<template>
    <styled-dialog
        :value="value"
        @input="$emit('input', $event)">
        <template
            v-if="!complete"
            #heading>
            <h3>Are you sure you want to cancel?</h3>
        </template>

        <v-alert
            type="error"
            class="mb-4"
            :value="error">
            {{ error }}
        </v-alert>

        <!-- Default Start -->
        <template v-if="!complete">
            <p class="">
                Are you sure you want to cancel this onboarding
                for {{ onboarding.label }}? You can deploy it again
                at any time so don't worry, starting it again is just
                as easy as the first time!
            </p>

            <div class="text-xs-center mb-4 mt-5">
                <styled-button
                    :loading="loading"
                    @click="onCancelOnboarding()">
                    CANCEL ONBOARDING
                </styled-button>
            </div>
            <a
                href="#"
                class="red--text"
                @click.prevent="$emit('input', false)">
                Close
            </a>
        </template>
        <!-- Default End -->

        <!-- Complated Start -->
        <template v-else>
            <div class="text-xs-center ma-4">
                <h1>Success!</h1>
                <p class="mb-5">
                    This onboarding has been successfully cancelled.
                </p>
            </div>
        </template>
        <!-- Completed End -->
    </styled-dialog>
</template>

<script>
import StyledDialog from './StyledDialog';
import StyledButton from './StyledButton';
import { mapGetters } from 'vuex';

export default {
    components: {
        StyledDialog,
        StyledButton
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        onboarding: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            error: null,
            loading: false,
            complete: false
        };
    },
    computed: {
        ...mapGetters([
            'dealerProducts'
        ]),
        productName() {
            return this.onboarding?.product?.display_name || '';
        }
    },
    methods: {
        async onCancelOnboarding() {
            try {
                this.error = null;
                this.loading = true;
                await this.$store.dispatch('cancelOnboarding', this.onboarding);

                this.complete = true;

                // Keep the complete dialog active briefly
                setTimeout(() => {
                    this.$emit('complete');
                    this.reset();
                }, 1000);

            } catch(error) {
                this.error = 'Error removing onboarding.  Please try again or contact support';
                console.error('Error removing onboarding', error);
            } finally {
                this.loading = false;
            }
        },
        reset() {
            // Ensure the user doesn't see the message switch
            // while the animation is happening
            setTimeout(() => {
                this.complete = false;
            }, 1000);
        }
    }
};
</script>

<style lang="scss" scoped>
.remove-product {
    border-top: 1px solid $gray-light;
    padding-top: 20px;
    margin-top: 30px;
}
</style>
<template>
    <div>
        <styled-slideout
            :value="value"
            :width="700"
            @input="handleSlideoutToggle">
            <new-tag-manager-provider-request-slideout
                v-model="showNewTagManagerDialog" />
            <verify-site-provider-installation-slideout
                v-model="showVerifySiteProviderInstallationDialog"
                :dealer="dealer" />
            <div class="pa-5">
                <div
                    v-if="currentStage == STAGE_DEFAULT"
                    class="text-xs-center">
                    <h2>Site Provider Change Disclaimer</h2>
                    <p>
                        Changing a site provider involves a few steps
                        to ensure BuyerBridge can continue to function
                        after the change occurs.  During this process
                        you will need to make sure the EventFlow tag
                        is moved to the new site and verify that it’s
                        working properly once installed.
                    </p>
                    <p class="mb-4">
                        Are you ready to continue with these steps?
                    </p>
                    <styled-button
                        @click="currentStage = STAGE_SITE_PROVIDER">
                        I'm Ready!  Continue!
                    </styled-button>
                </div>

                <div v-if="currentStage == STAGE_SITE_PROVIDER">
                    <h2>Change Site Provider</h2>
                    <p>
                        To begin the change process, please select
                        the new site provider for DU Auto Group below.
                    </p>

                    <div class="layout">
                        <v-autocomplete
                            v-model="siteProvider"
                            :items="siteProviders"
                            item-text="name"
                            return-object
                            :loading="loadingSiteProviders"
                            class="styled-field flex xs12 md6 mb-2"
                            label="Select Site Provider" />
                    </div>
                    <a
                        href="#"
                        class="mb-4 d-block"
                        @click.prevent="showNewTagManagerDialog = true">
                        Don’t see your provider?  Click here to open a request.
                    </a>


                    <p class="mb-0">
                        Has the EventFlow script been moved to the new site already?
                    </p>
                    <a
                        href="#"
                        class="mb-3 d-block"
                        @click.prevent="showVerifySiteProviderInstallationDialog = true">
                        If you’re not sure click here for instructions on how to verify if it’s been moved.
                    </a>

                    <v-radio-group
                        v-model="tagHasBeenMoved"
                        :rules="requiredRules"
                        class="styled-radio mb-3"
                        row>
                        <v-radio
                            label="Yes"
                            value="yes"
                            color="primary"
                            class="mb-0" />
                        <v-radio
                            label="No"
                            value="no"
                            color="primary" />
                    </v-radio-group>

                    <!-- @todo Ben, place the site provider install content, etc here
                    based on the selection above -->
                    <div v-if="siteProvider && tagHasBeenMoved == 'no'">
                        <h2>Install Request Emails</h2>
                        <p class="mb-4">
                            Use the example messaging below to obtain approval from {{ dealer.name }}
                            and have {{ siteProvider.name }} install the container using the approval.
                        </p>
                        <site-provider-install-content
                            :dealer="dealer"
                            :site-provider="siteProvider" />
                    </div>


                    <styled-callout class="layout align-center mb-4">
                        <div class="flex shrink mr-3">
                            <status-icon
                                value="warning"
                                size="25" />
                        </div>
                        <div class="flex">
                            <p><strong>Warning</strong></p>
                            <p>
                                Once you’ve changed your provider there will be
                                additional steps to verify that the EventFlow script
                                is working correctly.  Make sure you are certain that
                                the script is already installed and that you are ready
                                to verify that it’s working!
                            </p>
                        </div>
                    </styled-callout>

                    <styled-button
                        :disabled="tagHasBeenMoved != 'yes'"
                        :loading="loadingChangeSiteProvider"
                        @click="onSetSiteProvider">
                        Change Provider
                    </styled-button>
                </div>

                <!-- Processing Stage Start -->
                <div v-if="currentStage == STAGE_PROCESSING">
                    <h2>Reprocessing Inventory</h2>
                    <p>
                        Please wait a moment while we reprocess inventory. This primarily ensures that product URLs continue to function after the change.
                    </p>
                    <v-progress-linear
                        color="primary"
                        height="28"
                        :value="overallProgress"
                        class="mb-2" />

                    <div class="layout">
                        <div class="flex">
                            <strong>Process:</strong>{{ processUpdate }} <span v-if="overallProgress != 0">{{ overallProgress }}%</span>
                        </div>
                    </div>
                </div>
                <!-- Processing Stage End -->

                <!-- Verification Stage Start -->
                <div v-if="currentStage == STAGE_VALIDATION">
                    <h3>Verify Installation</h3>
                    <p>
                        Please follow the steps below to ensure the EventFlow
                        script was migrated correctly and the inventory was
                        updated correctly!
                    </p>

                    <verify-site-provider-installation
                        :dealer="dealer"
                        :change-site-provider-dialog-open="value" />
                </div>
                <!-- Verification Stage End -->
            </div>
        </styled-slideout>
    </div>
</template>

<script>
import NewTagManagerProviderRequestSlideout from '@/components/globals/NewTagManagerProviderRequestSlideout';
import StatusIcon from '@/components/globals/StatusIcon';
import StyledCallout from '@/components/globals/StyledCallout';
import StyledButton from '@/components/globals/StyledButton';
import StyledSlideout from '@/components/globals/StyledSlideout';
import VerifySiteProviderInstallation from '@/components/globals/VerifySiteProviderInstallation';
import { mapGetters } from 'vuex';
import { requiredRules } from '@/helpers/validationRules';
import SiteProviderInstallContent from './SiteProviderInstallContent';
import VerifySiteProviderInstallationSlideout from './VerifySiteProviderInstallationSlideout';

const STAGE_DEFAULT = 'default';
const STAGE_SITE_PROVIDER = 'site_provider';
const STAGE_PROCESSING = 'processing';
const STAGE_VALIDATION = 'validation';

export default {
    components: {
        VerifySiteProviderInstallationSlideout,
        SiteProviderInstallContent,
        VerifySiteProviderInstallation,
        NewTagManagerProviderRequestSlideout,
        StatusIcon,
        StyledCallout,
        StyledButton,
        StyledSlideout,
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        dealer: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            requiredRules,
            siteProvider: {},
            currentStage: STAGE_DEFAULT,
            siteProviders: [],
            loadingSiteProviders: false,
            tagHasBeenMoved: '',
            loadingChangeSiteProvider: false,
            showNewTagManagerDialog: false,
            showVerifySiteProviderInstallationDialog: false,
            verifyInstallationDialogOpen: false,
            inventoryProgress: 0,
            scrapingProgress: 0,
            inventoryMessage: '',
            processUpdate: '',
            updates: [],

            STAGE_DEFAULT,
            STAGE_SITE_PROVIDER,
            STAGE_PROCESSING,
            STAGE_VALIDATION
        };
    },
    computed: {
        ...mapGetters(['dealerInventorySources']),
        overallProgress() {
            return Math.round(((this.inventoryProgress / 2) + (this.scrapingProgress / 2)) * 100);
        },
    },
    watch: {
        value(newValue, oldValue) {
            // If we're transitioning to open and the form was previously
            // submitted reset it
            if (newValue === true
                && oldValue === false
                && this.complete === true) {
                this.complete = false;
            }
        }
    },
    created() {
        this.getSiteProviders();

        this.siteProvider = this.dealer.site_provider;
    },
    methods: {
        async getSiteProviders() {
            try {
                this.loadingSiteProviders = true;
                const response = await this.$apiRepository.getSiteProviders();
                this.siteProviders = response.data.data;
            } finally {
                this.loadingSiteProviders = false;
            }
        },
        async onSetSiteProvider() {
            try {

                this.loadingChangeSiteProvider = true;

                if (this.siteProvider
                    && this.siteProvider.id !== this.dealer.site_provider_id) {
                    await this.$http.patch(`/dealers/${this.dealer.id}`, {
                        site_provider_id: this.siteProvider.id
                    });
                    // Refresh the current dealer to register the updates
                    await this.$store.dispatch('updateCurrentDealer');
                }

                // Set to processing
                this.currentStage = STAGE_PROCESSING;

                // Process the first RDI
                // @todo update this so it processes the whole dealer
                const rdi = this.dealerInventorySources[0];

                // If there's no inventory to process report back to the user
                // and close the slideout
                if (!rdi) {
                    this.$flash('The site provider has been changed', 'green');
                    this.handleSlideoutToggle(false);
                    return;
                }

                const response = await this.processRdi(rdi.id);

                this.$flash('Processing started', 'green');
                console.log(response.data);
                this.$echo.channel(response.data.channel_name)
                    .listen('InventoryProcessed', (e) => {
                        if (e.process_name == 'feed-processor') {
                            this.processUpdate = 'Processing inventory...';
                            if (e.status == 'finished') {
                                this.inventoryProgress = 1;
                            } else if (e.status == 'failed') {
                                this.$flash('Feed processing failed', 'red');
                                this.inventoryProgress = 0;
                                this.processUpdate = 'Feed processing failed!';
                            } else {
                                this.inventoryProgress = e.progress;
                            }
                        }
                        if (e.process_name == 'vdp-url-processor') {
                            this.processUpdate = 'Processing VDP urls...';
                            if (e.status == 'finished') {
                                this.scrapingProgress = 1;
                            } else if (e.status == 'failed') {
                                this.$flash('VDP url processing failed', 'red');
                                this.scrapingProgress = 0;
                                this.processUpdate = 'VDP url processing failed!';
                            } else {
                                this.scrapingProgress = e.progress;
                            }
                        }
                        if (this.overallProgress == 100) {
                            setTimeout(() => {
                                this.currentStage = STAGE_VALIDATION;
                            }, 5000);
                        }
                        console.log(e);
                    });


            } catch(error) {
                console.log(error);
            } finally {
                this.loadingChangeSiteProvider = false;
            }
        },
        async processRdi(id) {
            try {
                const data = {
                    upload_exports: true,
                    notification_email: [],
                    upload_to_facebook: true,
                    activate_pusher: true,
                };
                return this.$http.post(`dealers/${this.dealer.id}/remote_dealer_identifiers/${id}/process-inventory`, data);
            } catch(error) {
                // eslint-disable-next-line no-console
                console.error('Error processing new feed:', error);
                this.error = 'There was an error trying to process the new feed.  Please try again or contact support if the problem continues.';
            }
        },
        handleSlideoutToggle($event) {
            this.$emit('input', $event);
            this.siteProvider = this.dealer.site_provider;
            this.currentStage = STAGE_DEFAULT;
            this.tagHasBeenMoved = '';
            this.inventoryProgress = 0;
            this.scrapingProgress = 0;
            this.inventoryMessage = '';
            this.processUpdate = '';
            this.updates  = [];
        },
    }
};
</script>
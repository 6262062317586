<template>
    <v-combobox
        :value="value"
        :items="dealers"
        item-text="name"
        item-value="id"
        :loading="loadingDealers"
        label="Search/Select Dealer(s)"
        :error-messages="errorMessages"
        :rules="activeRules"
        class="styled-field"
        chips
        multiple
        @keyup="search"
        @input="$emit('input', $event)">
        <template #selection="{ attrs, item, parent, selected }">
            <v-chip
                v-if="item === Object(item)"
                v-bind="attrs"
                :selected="selected"
                small>
                <span class="pr-2">
                    {{ item.name }}
                </span>
                <v-icon
                    small
                    @click="parent.selectItem(item)">
                    close
                </v-icon>
            </v-chip>
        </template>
    </v-combobox>
</template>

<script>
import { debounce } from 'lodash';
import { requiredComboboxRules }  from '../../../helpers/validationRules';

export default {
    props: {
        value: {
            type: Array,
            required: true
        },
        agencyId: {
            type: Number,
            default: null
        },
        rules: {
            type: Array,
            default() {
                return [];
            }
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dealers: [],
            loadingDealers: false,
            errorMessages: []
        };
    },
    computed: {
        activeRules() {
            // If the field is required default to the combobox rules
            const rules = this.required ? requiredComboboxRules : [];
            // Combine the rules provided by props
            return [...rules, ...this.rules];
        }
    },
    watch: {
        agencyId() {
            this.getDealers();
        }
    },
    created() {
        this.getDealers();
    },
    methods: {
        search: debounce(async function(e) {
            const { value } = e.target;
            this.getDealers(value);
        }, 300),
        async getDealers(terms = null) {
            try {
                this.loadingDealers = true;
                this.errors = [];

                const response = await this.$apiRepository.getDealersMinimal(terms, this.agencyId);

                this.dealers = response.data.data;
            } catch (error) {
                console.error('Error retrieving dealers', error);
                this.errors = ['Error retrieving dealers'];
            } finally {
                this.loadingDealers = false;
            }
        }
    }
};
</script>

<template>
    <div>
        <styled-list>
            <div>
                <div class="layout">
                    <div class="flex">
                        <h3>
                            Install the Facebook Pixel Helper &amp; verify installation
                        </h3>
                        <p>
                            Visit any page on <a
                                :href="dealer.site_base_url"
                                target="_blank">{{ website }}</a> and verify that pixel
                            {{ dealer.facebook_pixel_annotations.data[0].facebook_pixel_id }} has a PageView event with a green check
                            using the <a
                                href="https://chrome.google.com/webstore/detail/facebook-pixel-helper/fdgfkebogiimcoedlicjlajpkdmockpc?hl=en"
                                target="_blank">Facebook Pixel Helper</a>.  You may need to refresh
                            once after loading the page.
                        </p>
                        <p>
                            <a
                                href="https://chrome.google.com/webstore/detail/facebook-pixel-helper/fdgfkebogiimcoedlicjlajpkdmockpc?hl=en"
                                target="_blank">Download Pixel Helper</a>
                        </p>
                        <div>
                            <div class="property">
                                <p class="label">
                                    Pixel ID
                                </p>
                                <p class="value">
                                    <copy-text :value="dealer.facebook_pixel_annotations.data[0].facebook_pixel_id" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="shrink">
                        <img
                            src="/img/site-provider-validate-facebook-pageview.png"
                            style="width: 150px;">
                    </div>
                </div>
            </div>
            <div>
                <div class="layout">
                    <div class="flex">
                        <h3>
                            Verify content view events
                        </h3>
                        <p>
                            Click the URLs below and ensure that they go to the right page and that you see
                            ViewContent events showing with green check marks in the Pixel Helper.
                        </p>
                        <template v-if="vehicles.length">
                            <ul>
                                <li
                                    v-for="(vehicle, index) in vehicles"
                                    :key="index">
                                    <a :href="vehicle.vdp_url">{{ vehicle.vdp_url }}</a>
                                </li>
                            </ul>
                        </template>
                    </div>
                    <div class="shrink">
                        <img
                            src="/img/site-provider-validate-facebook-view-content.png"
                            style="width: 150px;">
                    </div>
                </div>
            </div>
            <div>
                <div class="layout">
                    <div class="flex">
                        <h3>
                            Verify lead events
                        </h3>
                        <p>
                            On any page of the site, try to trigger a lead event by clicking phone numbers,
                            using the chat or submitting a contact form. You should see a new Lead event under
                            the pixel. If you're having trouble, read the article below.
                        </p>
                    </div>
                    <div class="shrink">
                        <img
                            src="/img/site-provider-validate-facebook-lead.png"
                            style="width: 150px;">
                    </div>
                </div>
            </div>
        </styled-list>
        <div class="layout align-center mt-5">
            <div class="mr-3">
                <strong>Does something look off?</strong> If you’re not able to verify
                all of the steps above please contact support!
            </div>
            <styled-button
                class="grow"
                @click="handleContactSupportClick">
                Contact Support
            </styled-button>
        </div>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import StyledList from '@/components/globals/StyledList';
import CopyText from '@/components/globals/CopyText';

export default {
    components: {
        CopyText,
        StyledButton,
        StyledList
    },
    props: {
        dealer: {
            type: Object,
            required: true
        },
        changeSiteProviderDialogOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            vehicles: [],
            loadingVehicles: true
        };
    },
    computed: {
        website() {
            if (!this.dealer.site_base_url) {
                return null;
            }
            const url = new URL(this.dealer.site_base_url);
            return url.hostname;
        }
    },
    created() {
        this.getVehicles();
    },
    methods: {
        async getVehicles() {
            try {
                const response = await this.$http.post('dealers/'+this.dealer.id+'/vehicles/search', {
                    query: {
                        bool: {
                            must: [
                                {
                                    function_score: {
                                        random_score: {
                                            field: '_seq_no'
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    size: 3
                });

                this.vehicles = response.data.hits.hits.map(hit => hit._source);

                console.log(this.vehicles);
            } finally {
                this.loadingVehicles = false;
            }
        },
        handleContactSupportClick() {
            this.$store.dispatch('createTicket');
        }
    }

};
</script>

<style lang="scss" scoped>
    .dealer-info,
    .dealer-map {
        flex: 1;
        padding: 30px;
    }
    .dealer-info {
        display: flex;
        flex-wrap: wrap;
    }
    .property {
        width: 50%;
        .label {
            font-size: 12px;
            color: $gray-text;
            margin: 0;
        }
        .value {
            margin-left: 10px;
        }
    }
</style>
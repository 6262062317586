<template>
    <div :class="{'condensed': condensed}">
        <website-inventory-vehicle-slideout
            v-if="currentVehicle"
            v-model="vehicleSlideoutOpen"
            :vehicle="currentVehicle" />
        <v-alert
            type="error"
            :value="error">
            {{ error }}
        </v-alert>

        <div
            v-if="loading"
            class="text-xs-center py-5">
            <loader />
        </div>

        <template v-else>
            <div class="layout wrap align-center">
                <div
                    class="flex mb-5"
                    :class="{'xs12 md5 text-xs-center': !condensed}">
                    <p>
                        We’ve successfully detected inventory for
                        <strong>{{ website }}</strong>
                    </p>

                    <p>
                        Please review what we've retrieved below to ensure
                        you're comfortable with its use over feed inventory.
                        It's important to closely inspect pricing, build
                        attributes &amp; images, specifically.
                    </p>
                </div>
                <div
                    class="flex text-xs-center mb-5"
                    :class="{'xs12 md3': !condensed}">
                    <market-stats
                        title="In Feed"
                        class="pt-0"
                        :metric="totalVehicles | numFormat(numberFormats.number)" />
                    <p class="grey--text">
                        <em>Updated <time-ago :value="updatedAt" /></em>
                    </p>
                </div>
                <div
                    class="flex mb-5"
                    :class="{'xs12 md4': !condensed}">
                    <div class="chart-container">
                        <apex-chart
                            ref="chart"
                            type="donut"
                            :options="chartOptions"
                            :series="chartSeries"
                            :height="280" />
                        <div class="chart-legend">
                            <div class="chart-legend-inner">
                                <div>
                                    <span>NEW</span>
                                    <span
                                        :style="{
                                            color: colors.purpleLight
                                        }">
                                        {{ newCount }}
                                    </span>
                                </div>
                                <div>
                                    <span>USED</span>
                                    <span
                                        :style="{
                                            color: colors.greenLight
                                        }">
                                        {{ usedCount }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="condensed">
                <div class="sample-header">
                    <div class="sample-title">
                        Inventory Sample
                    </div>
                    <div class="sample-buttons">
                        <action-button
                            icon="refresh"
                            class="ml-3"
                            @click="getSampleVehicles">
                            Sample More
                        </action-button>
                        <action-button
                            icon="list"
                            class="ml-3"
                            @click="tableSlideoutOpen = true">
                            View All
                        </action-button>
                    </div>
                </div>
                <loader v-if="samplesLoading" />
                <div
                    v-else
                    class="sample-vehicles">
                    <vehicle-review
                        v-if="sampleVehicleNew"
                        :vehicle="sampleVehicleNew"
                        class="mx-0 mb-2"
                        @open-details="openVehicleDetails" />
                    <vehicle-review
                        v-if="sampleVehicleUsed"
                        :vehicle="sampleVehicleUsed"
                        class="mx-0"
                        @open-details="openVehicleDetails" />
                </div>
            </template>
            <template v-if="!condensed">
                <div ref="scrollTarget" />
                <website-inventory-table
                    :root-domain="rootDomain"
                    :inventory="inventory"
                    :loading="loadingInventory"
                    :previous-page="previousPage"
                    :next-page="nextPage"
                    @go-to-previous="goToPreviousPage()"
                    @go-to-next="goToNextPage()" />
            </template>
            <website-inventory-table-slideout
                v-if="condensed"
                v-model="tableSlideoutOpen"
                :root-domain="rootDomain"
                :inventory="inventory"
                :loading="loadingInventory"
                :previous-page="previousPage"
                :next-page="nextPage"
                @go-to-previous="goToPreviousPage()"
                @go-to-next="goToNextPage()" />
        </template>
    </div>
</template>

<script>
import ApexChart from 'vue-apexcharts';
import WebsiteInventoryVehicleSlideout from '@/components/globals/WebsiteInventoryVehicleSlideout';
import WebsiteInventoryTableSlideout from '@/components/globals/WebsiteInventoryTableSlideout';
import WebsiteInventoryTable from '@/components/globals/WebsiteInventoryTable';
import TimeAgo from '@/components/globals/TimeAgo';
import MarketStats from '@/components/globals/MarketStats';
import Loader from '@/components/globals/Loader';
import getNumberFormats from '@/helpers/numberFormats';
import colors from '@/helpers/colors';
import getRootDomain from '@/helpers/getRootDomain';
import ActionButton from '@/components/globals/ActionButton';
import VehicleReview from '@/components/globals/VehicleReview';

export default {
    components: {
        ApexChart,
        MarketStats,
        WebsiteInventoryVehicleSlideout,
        WebsiteInventoryTableSlideout,
        WebsiteInventoryTable,
        ActionButton,
        VehicleReview,
        Loader,
        TimeAgo,
    },
    props: {
        website: {
            type: String,
            required: true
        },
        condensed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            colors,
            numberFormats: getNumberFormats(),
            loading: true,
            error: null,
            inventory: [],
            totalVehicles: 0,
            updatedAt: new Date(),
            loadingInventory: false,
            currentPage: 1,
            rowsPerPage: 25,
            vehicleSlideoutOpen: false,
            tableSlideoutOpen: false,
            sampleVehicleUsed: null,
            sampleVehicleNew: null,
            samplesLoading: false,
            currentVehicle: null,
            chartOptions: {
                chart: {
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '70%'
                        }
                    }
                },
                legend: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                colors: [colors.purpleLight, colors.greenLight],
                labels: ['New', 'Used']
            },
            newCount: 0,
            usedCount: 0
        };
    },
    computed: {
        rootDomain() {
            return getRootDomain(this.website);
        },
        chartSeries() {
            const series = [
                this.newCount,
                this.usedCount
            ];
            return series;
        },
        pages() {
            return Math.round(this.totalVehicles / this.rowsPerPage);
        },
        nextPage() {
            if (this.currentPage < this.pages) {
                return this.currentPage + 1;
            }
            return 0;
        },
        previousPage() {
            if (this.currentPage > 1) {
                return this.currentPage - 1;
            }
            return 0;
        },
        startAt() {
            return this.rowsPerPage * (this.currentPage - 1);
        }
    },
    watch: {
        website(newWebsite, oldwebsite) {
            if (newWebsite != oldwebsite) {
                this.init();
            }
        }
    },
    created() {
        this.init();
    },
    methods: {
        onChangeSort() {
            this.getWebsiteInventory();
        },
        goToNextPage() {
            this.currentPage = this.nextPage;
            this.getWebsiteInventory();
            this.scrollToTableTop();
        },
        goToPreviousPage() {
            this.currentPage = this.previousPage;
            this.getWebsiteInventory();
            this.scrollToTableTop();
        },
        scrollToTableTop() {
            this.$scrollTo(
                this.$refs.scrollTarget,
                500,
                {
                    offset: -150
                }
            );
        },
        async init() {
            try {
                this.currentPage = 1;
                this.loading = true;
                this.error = null;

                await Promise.all([
                    this.getWebsiteInventory(),
                    this.getWebsiteInventoryCounts(),
                ]);
                if (this.condensed) {
                    await this.getSampleVehicles();
                }
                this.$emit('load', this.inventory);
                this.updatedAt = new Date();
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
        openVehicleDetails(vehicle) {
            this.currentVehicle = vehicle;
            this.vehicleSlideoutOpen = true;
        },
        async getRandomVehicleSample(type) {
            this.samplesLoading = true;
            try {
                const response = await this.$apiRepository.getWebsiteInventory({
                    website: this.rootDomain,
                    query: {
                        bool: {
                            filter: [
                                {
                                    match: {
                                        'inventory_type.keyword': type
                                    }
                                }
                            ]
                        }
                    },
                    size: 1,
                    from: 0,
                    sort: [{
                        _script: {
                            script: 'Math.random()',
                            type: 'number',
                            order: 'asc'
                        }
                    }]
                });
                const randomVehicle = response.data?.hits?.hits?.map(hit => hit._source) ?? [];
                if (randomVehicle.length && type === 'used') { this.sampleVehicleUsed = randomVehicle[0] }
                if (randomVehicle.length && type === 'new') { this.sampleVehicleNew = randomVehicle[0] }
            } catch (error) {
                console.log(error);
            } finally {
                this.samplesLoading = false;
            }
        },
        async getSampleVehicles() {
            await Promise.all([
                this.getRandomVehicleSample('new'),
                this.getRandomVehicleSample('used')
            ]);
        },
        async getWebsiteInventory() {
            try {
                this.loadingInventory = true;
                const response = await this.$apiRepository.getWebsiteInventory({
                    website: this.rootDomain,
                    size: this.rowsPerPage,
                    from: this.startAt,
                    // @todo bring this back and change the headers to user sorting
                    // for VIN, Year, Mile & Price once the backend supports it
                    // sort: [{
                    //     [this.sortBy]: this.sortDescending ? 'desc' : 'asc'
                    // }],
                });
                this.inventory = response.data?.hits?.hits?.map(hit => hit._source) ?? [];
                this.totalVehicles = response.data?.hits?.total?.value ?? 0;
            } catch (error) {
                console.error('Error retrieving website inventory', error);
                throw 'Error retrieving website inventory';
            } finally {
                this.loadingInventory = false;
            }
        },
        async getWebsiteInventoryCounts() {
            try {
                this.newCount = 0;
                this.usedCount = 0;

                const response = await this.$apiRepository.getWebsiteInventoryCounts(this.rootDomain);

                this.newCount = response.data?.new ?? 0;
                this.usedCount = response.data?.used ?? 0;
            } catch (error) {
                console.error('Error retrieving website inventory counts', error);
                throw 'Error retrieving website inventory counts';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.chart-container {
    position: relative;
    margin-bottom: -12px;
    .chart-legend {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -7px;

    }
    .chart-legend-inner {
        width: 100%;
        div {
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                width: 48%;

                &:first-child {
                    text-align: right;
                }
                &:last-child {
                    font-weight: bold;
                    font-size: 1.6rem;
                }
            }
        }
    }
}
.vin-img {
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.50);
}
.condensed {
    .chart-container {
        width: 240px;
    }
}
.sample-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
}
.sample-title {
    color: $blue-bg;
    font-weight: 500;
    font-size: 18px;
}
</style>


<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h3>Verify Installation</h3>
            <p>
                Please follow the steps below to ensure the EventFlow
                script was migrated correctly and the inventory was
                updated correctly!
            </p>

            <verify-site-provider-installation
                :dealer="dealer" />
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from './StyledSlideout';
import VerifySiteProviderInstallation from './VerifySiteProviderInstallation';

export default {
    name: 'VerifySiteProviderInstallationSlideout',
    components: {
        VerifySiteProviderInstallation,
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        dealer: {
            type: Object,
            required: true
        },
    }
};
</script>

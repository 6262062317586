<template>
    <styled-dialog
        :value="value"
        :width="700">
        <div class="pa-4">
            <div class="header">
                <div
                    class="logos"
                    :class="{
                        'multiple-logos': products.length > 1
                    }">
                    <template v-for="(product, index) in products">
                        <span
                            v-if="index > 0"
                            :key="product.id">
                            &amp;
                        </span>
                        <product-logo
                            :key="`product-${product.id}`"
                            :height="products.length > 1 ? '60px' : '70px'"
                            :name="product.display_name"
                            :value="product.id" />
                    </template>
                </div>
                <div>
                    <h1>Let's do this!</h1>
                    <p>Currently Onboarding {{ dealer.name }}!</p>
                </div>
            </div>
            <v-alert
                :value="shouldUseHubSpotOnboardingForm"
                type="info"
                class="mb-4">
                In order to begin the onboarding of this product, there are a
                few things we need to build on the back end. One of our
                Onboarding specialists will be reaching out to you in 1 business
                day to ensure that we have all of the information we need to
                begin this process.
            </v-alert>
            <p class="mb-5">
                In order to deploy the {{ productsDescription }}
                {{ productsPluralized }} you will need to
                update your BuyerBridge plan. Check the box below if
                you agree to be billed for the "{{ productsDescription }}"
                {{ productsPluralized }} under this account in accordance
                with your BuyerBridge contract.
            </p>
            <div class="layout justify-end align-center">
                <div>
                    <v-checkbox
                        v-model="disclaimerVerified"
                        label="I agree to the increased fees"
                        color="primary"
                        class="styled-checkbox mr-4" />
                </div>
                <styled-button
                    :disabled="!disclaimerVerified"
                    :loading="continueIsLoading"
                    @click="onContinue()">
                    CONTINUE
                </styled-button>
            </div>
            <div v-if="platform">
                <new-platform-onboarding-form
                    :products="products"
                    :platform="platformName"
                    @complete="hubSpotFormSubmitted = true" />
            </div>
            <!-- <div
                v-if="activeDealerOnboardings.length"
                class="cancel-onboardings">
                <icon
                    name="indicator-warning"
                    size="25"
                    color="orange" />
                <p>
                    Your active onboardings will be cancelled during this process.
                    Don't worry, you can restart them at any point afterwards!
                </p>
            </div> -->
        </div>
        <a
            href="#"
            class="red--text d-block mt-3"
            @click.prevent="$emit('input', false)">
            cancel
        </a>
    </styled-dialog>
</template>

<script>
import ProductLogo from '@/components/globals/ProductLogo.vue';
import StyledDialog from '@/components/globals/StyledDialog.vue';
import StyledButton from '@/components/globals/StyledButton.vue';
import NewPlatformOnboardingForm from '@/components/globals/NewPlatformOnboardingForm.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { PLAYBOOK_PRODUCTS, PRODUCTS, PLATFORM_FACEBOOK, AGENCY_DU } from '@/helpers/globals.js';
import { getChannelByBBProductId, getProductCategoryByBBProductId } from '@/components/onboarding/channel-setup/channels.js';
import EventBus from '@/event-bus.js';

export default {
    components: {
        ProductLogo,
        StyledDialog,
        StyledButton,
        NewPlatformOnboardingForm
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        productIds: {
            type: Array,
            required: true
        },
        dealer: {
            type: Object,
            required: true
        },
        playId: {
            type: Number,
            required: false,
            default: null
        },
        redirectUrl: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            disclaimerVerified: false,
            continueIsLoading: false,
            hubSpotFormSubmitted: false
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency,
        }),
        ...mapGetters([
            'dealerProducts',
            'activeDealerOnboardings'
        ]),
        productsDescription() {
            if (this.products.length === 1) {
                return this.products[0].display_name;
            }

            return this.products.reduce((description, product, index) => {
                if (index == 0) {
                    return product.display_name;
                }

                return description + (index < this.products.length - 1 ? ', ' : ' & ') + product.display_name;
            }, '');
        },
        products() {
            return this.productIds.map(productId => {
                return PRODUCTS.find(product => product.id == productId);
            });
        },
        productsPluralized() {
            return (this.products.length > 1) ? 'products' : 'product';
        },
        hasAdvertisingProduct() {
            return this.products.some(product => {
                return PLAYBOOK_PRODUCTS.includes(product.id);
            });
        },
        dealerProductsToRemove() {
            // Find the product settings for each provided product
            // and collect all the products which we should drop
            let dropProducts = [];
            PRODUCTS
                .filter(product => {
                    return this.productIds.includes(product.id);
                }).forEach(product => {
                    dropProducts = [ ...new Set([
                        ...dropProducts,
                        ...product.upgradeFrom
                    ]) ];
                });

            // If the dealer already has this product drop it
            return this.dealerProducts
                        .filter(product => dropProducts.includes(product.id))
                        .map(product => product.dealer_product.id);
        },
        platform() {
            return this.products.map(product => {
                return getChannelByBBProductId(product.id);
            })?.[0];
        },
        platformName() {
            return this.platform?.name;
        },
        shouldUseHubSpotOnboardingForm() {
            // Check if the agency is not DU and its not a Facebook platform
            return (this.currentAgency.id !== AGENCY_DU) && (this.platform?.key !== PLATFORM_FACEBOOK) && (!this.isAdminUser);
        },
        isAdminUser() {
            return this.$userHasRole(['super_admin', 'admin']);
        }
    },
    methods: {
        ...mapActions([
            'startNewOnboarding',
            'cancelAllOnboardings',
            'updateCurrentDealer'
        ]),
        getProductCategoryByBBProductId,
        getChannelByBBProductId,
        async onContinue() {
            this.continueIsLoading = true;

            await this.updateProducts();

            await this.startNewOnboarding({
                dealerId: this.dealer.id,
                productIds: this.productIds,
                playId: this.playId ?? null,
                redirectUrl: this.redirectUrl
            });

            // Reset on completion
            this.$emit('input', false);
            this.cancelOnboardings = true;
            this.disclaimerVerified = false;
            this.continueIsLoading = false;

            // Tell the UI we're complete to reset
            this.$emit('complete');
        },

        async updateProducts() {
            // If we are using the hubspot form we dont create the dealer products here
            // The support team will create the dealer products when the onboarding is complete
            if (this.shouldUseHubSpotOnboardingForm) {
                EventBus.$emit('submit-new-platform-onboarding-form');

                // Wait for the hubSpotFormSubmitted to be true
                while (!this.hubSpotFormSubmitted) {
                    await new Promise(resolve => setTimeout(resolve, 500));
                }

                // Reset the flag for the next onboarding
                this.hubSpotFormSubmitted = false;

                return;
            }

            let createPromises = [];

            // If we are not using the hubspot form we just create the
            // dealer products like normal
            createPromises = this.products.map(product => {
                return this.$apiRepository.createDealerProduct(
                    this.dealer.id,
                    product.id
                );
            });

            const removePromises = this.dealerProductsToRemove.map(dealerProductId => {
                return this.$apiRepository.deleteDealerProduct(dealerProductId);
            });

            Promise.all([
                ...createPromises,
                ...removePromises
            ]);
        }
    }
};
</script>

<style lang="scss" scoped>
.cancel-onboardings {
    border-top: 1px solid $gray-light;
    padding-top: 20px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    font-style: italic;
    svg {
        margin-right: 14px;
    }
}

.header {
    margin-bottom: 2rem;
    display: flex;
    .logos {
        margin-right: 2rem;
        display: flex;
        align-items: center;
        span {
            margin: 0 0.5rem;
        }
    }
    h1 {
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 0.3rem;
    }
    p {
        font-size: 1.3rem;
        line-height: 1.5rem;
    }
}

</style>
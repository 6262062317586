<template>
    <div>
        <div v-if="!hasInventory && !loading">
            <div class="no-inventory-notice">
                No inventory is currently available. Please configure your inventory data source during onboarding to use this tool.
                If you believe you are seeing this message in error <span @click.prevent="$store.dispatch('createOnboardingSupportTicket', 'Inventory Product Added With No Inventory')">please contact support for help</span>.
            </div>
        </div>
        <div
            v-if="hasInventory && !loading"
            class="analysis"
            :class="{'small-style': smallStyle}">
            <div class="mb-3">
                <span class="analysis-title">Inventory Analysis</span>
                <span
                    v-if="updatedAt && !smallStyle"
                    class="updated">Updated: {{ updatedAt }}</span>
            </div>
            <div class="visual">
                <div class="visual-right">
                    <div
                        class="holder"
                        :style="'width:'+usedWidth+'%;'">
                        <div class="visual-title">
                            Used
                        </div>
                        <div class="visual-block">
                            <div class="number">
                                {{ usedCount }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="holder"
                        :style="'width:'+newWidth+'%;'">
                        <div class="visual-title">
                            New
                        </div>
                        <div class="visual-block new">
                            <div class="number">
                                {{ newCount }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="visual-left">
                    <div class="wrapper">
                        <div class="visual-title">
                            Total Vehicles
                        </div>
                        <div class="visual-block">
                            <div class="number">
                                {{ total }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';

export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        hasInventory: {
            type: Boolean,
            required: true
        },
        usedCount: {
            type: [String, Number],
            required: true
        },
        newCount: {
            type: [String, Number],
            required: true
        },
        smallStyle: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer,
        }),
        updatedAt() {
            const hasDate = this.dealer.vehicle_data_last_processed?.date;
            if (hasDate) {
                const date = format(window.convertFromUtcToLocalTime(this.dealer.vehicle_data_last_processed.date), 'MM/DD/YYYY');
                const time = format(window.convertFromUtcToLocalTime(this.dealer.vehicle_data_last_processed.date), 'h:mm A');
                return date + ' @ ' + time;
            }
            return '';
        },
        total() {
            return +this.usedCount + +this.newCount;
        },
        usedWidth() {
            const usedWidth = (+this.usedCount / this.total * 100).toFixed(2);
            if (usedWidth > 95) return 95;
            if (usedWidth < 7) return 7;
            return usedWidth;
        },
        newWidth() {
            const newWidth = (+this.newCount / this.total * 100).toFixed(2);
            if (newWidth > 95) return 95;
            if (newWidth < 7) return 7;
            return newWidth;
        }
    },
};
</script>

<style lang="scss" scoped>
.analysis {
    padding: 15px 35px 35px 35px;
    border-bottom: 1px solid $alabaster-dark;
}
.analysis.small-style {
    padding: 0;
    border-bottom: none;
    .analysis-title {
        font-weight: 400;
        font-size: 16px;
    }
    .visual-right {
        width: 83%;
    }
    .visual-left {
        width: 16%;
    }
}
.analysis-title {
    font-size: 20px;
    font-weight: 600;
    margin-right: 15px;
}
.updated {
    color: $gray;
    font-size: 13px;
}
.visual {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
}
.visual-right {
    width: 87.3%;
    display: flex;
    .holder:first-of-type {
        .visual-block {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }
    .holder:last-of-type {
        .visual-block {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}
.visual-left {
    width: 12%;
}
.visual-block {
    padding: 6px 10px;
    background: #03A2EA;
    color: $white;
    font-size: 22px;
    font-weight: 700;
    &.new {
        background: #84D2F5;
    }
}
.holder {
    width: 50%;
}
.wrapper .visual-block{
    text-align: center;
    border-radius: 5px;
}
.visual-title {
    text-transform: uppercase;
    margin-bottom: 5px;
    font-size: 12px;
    white-space: nowrap;
}
.no-inventory-notice {
    background-color: #F8CACA;
    border: 1px solid #E12020;
    padding: 12px 20px;
    margin: 30px;
    font-size: 13px;
    span {
        text-decoration: underline;
        cursor: pointer;
        color: $gray-dark;
    }
}
</style>
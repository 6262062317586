import areas from './areas';

// Retrieve segement from this file and replace shortCode with abbreviation
// https://github.com/country-regions/country-region-data/blob/master/data.json

const countrySettings = [
    {
        name: 'United States',
        abbreviation: 'US',
        code: 'USA',
        key: 'us',
        googleAdminArea: 'administrative_area_level_1',
        translations: {
            address: 'Address',
            locality: 'City',
            administrativeArea: 'State',
            postal: 'Zip',
            phoneFormats: '555-555-5555, (555) 555-5555'
        }
    },
    {
        name: 'Canada',
        abbreviation: 'CA',
        code: 'CAN',
        key: 'ca',
        googleAdminArea: 'administrative_area_level_1',
        translations: {
            address: 'Address',
            locality: 'City',
            administrativeArea: 'Region',
            postal: 'Postal Code',
            phoneFormats: '555-555-5555, (555) 555-5555'
        }
    },
    {
        name: 'United Kingdom',
        abbreviation: 'GB',
        code: 'GBR',
        key: 'gb',
        googleAdminArea: 'administrative_area_level_2',
        translations: {
            address: 'Street',
            locality: 'Locality',
            administrativeArea: 'Region',
            postal: 'Postal Code',
            phoneFormats: '020 1234 1234, 020-1234-1234, 02012341234, 01234 123456, 012345 1234'
        }
    },
    {
        name: 'Ireland',
        abbreviation: 'IE',
        code: 'IRL',
        key: 'ie',
        googleAdminArea: 'administrative_area_level_2',
        translations: {
            address: 'Street',
            locality: 'Locality',
            administrativeArea: 'Region',
            postal: 'Eircode',
            phoneFormats: '+353(0) 1 444 3333, , +353(0) 15 444 3333'
        }
    }
];

const getCountries = () => {

    // Map the areas onto the country object if set
    return countrySettings.map(country => {
        country.areas = areas[country.key] || [];
        return country;
    });
};

export default getCountries();

<template>
    <styled-slideout
        :value="value"
        :width="700"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2>Chatbot Configuration</h2>
            <p class="mb-4">
                Use the form below to determine whether the chatbot 
                is enabled for this dealer and configure it. Be sure
                to save the updates when you're done!
            </p>
            <dealer-chatbot-configuration v-if="value" />
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import DealerChatbotConfiguration from '@/components/globals/DealerChatbotConfiguration';

export default {
    components: {
        DealerChatbotConfiguration,
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    }
};
</script>

export const userMessages = {
    initial_inquiry: 'https://www.facebook.com/marketplace/item/123456789/?ref=messaging_thread&amp;link_ref=BuyerBridge\n\nVIN: ABCDEFGHIJK123456\n\nIs this still available?',
    email_response: 'test@example.com',
    phone_response: '555-555-5555',
    unknown_response: 'Random stuff',
    unknown_follow_up: 'I have another question'
};

export const tokens = [{
    field: '$first_name',
    label: 'First Name',
    description: 'The first name of the messenger user'
},{
    field: '$full_name',
    label: 'Full Name',
    description: 'The full name of the messenger user'
},{
    field: '$dealer_name',
    label: 'Dealer Name',
    description: 'The dealer name associated with the vehicle the user is inquiring about'
},{
    field: '$dealer.address',
    label: 'Dealer Address',
    description: 'The street address of the dealer'
},{
    field: '$dealer.city',
    label: 'Dealer City',
    description: 'The city of the dealer'
},{
    field: '$dealer.state',
    label: 'Dealer State',
    description: 'The state of the dealer'
},{
    field: '$dealer.zip',
    label: 'Dealer Zip',
    description: 'The zip code of the dealer'
},{
    field: '$dealer.phone',
    label: 'Dealer Phone',
    description: 'The phone number of the dealer'
},{
    field: '$vehicle.make_name',
    label: 'Vehicle Make',
    description: 'The make of the vehicle'
},{
    field: '$vehicle.model_name',
    label: 'Vehicle Model',
    description: 'The model of the vehicle'
},{
    field: '$vehicle.trim_name',
    label: 'Vehicle Trim',
    description: 'The trim of the vehicle'
},{
    field: '$vehicle.mileage',
    label: 'Vehicle Mileage',
    description: 'The mileage/odometer on the vehicle'
},{
    field: '$vehicle.price',
    label: 'Vehicle Price',
    description: 'The price of the vehicle'
},{
    field: '$vdp_url',
    label: 'VDP URL',
    description: 'The the website VDP of the vehicle'
},{
    field: '$vehicle_location.city',
    label: 'Vehicle City',
    description: 'The city of the vehicle'
},{
    field: '$vehicle.state',
    label: 'Vehicle State',
    description: 'The state of the vehicle'
},{
    field: '$vehicle_location.zip',
    label: 'Vehicle Zip',
    description: 'The zip code of the vehicle'
}];

export const chatbotMessages =  {
    new_inquiry: 'Thank you for inquiry, {$first_name}. I\'m an automated bot designed to help you get in touch with the Seller {$dealer_name} faster',
    ask_for_email: 'What email address is best for the Seller to send you more information about this vehicle?',
    received_email: 'Great, thank you!',
    cant_find_email: 'Hm, that doesn’t look right. An email address is needed for the Seller to promptly send you the information you’re looking for. Can you try writing it again?',
    ask_for_phone_number: 'If you want a response even faster, you can also provide your phone number. \n\nIf you want to leave your phone number, you can write it below, otherwise, just write "skip".',
    cant_find_phone_number: 'I\'m guessing you don\'t want to provide your number. Moving on!',
    phone_opt_out: 'No problem! Moving on!',
    received_phone_number: 'Got it, thanks!',
    thank_you: 'Awesome. I have all the information I need to pass your vehicle inquiry to the Seller. Please allow 24-48 hours for their response.',
    sorry: 'Sorry, I do not understand that input.'
};


export const getAvailableChannels = (platforms) => {
    const availableChannels = platforms.data.data
        .filter(platform => platform.products.data.some( product => product.public == true))
        .map(channel => {
            return {
                ...channel,
                products: channel.products.data
                    .filter( product => product.public == true)
                    // Omit unused products
                    .filter(product => product.id > 18)
                    .filter(product => product.id !== 23 && product.id !== 24)
            };
        });
    return availableChannels;
}
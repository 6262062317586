<template>
    <div>
        <v-alert
            :value="error"
            type="error"
            transition="fade-transition"
            class="mt-2 mx-2">
            An error occurred.  Please try again or <a @click="$intercom.show()">contact support</a>.
        </v-alert>

        <v-form
            id="deactivate-account-form"
            ref="form"
            class="px-3 py-1">
            <h2 class="primary--text font-weight-regular my-3">
                Deactivate {{ dealer.name }}
            </h2>
            <p>
                Deactivating an account will stop all associated services from functioning.
                Please be sure you are certain you want to cancel before moving forward.
            </p>
            <p v-if="dealerHasAdProduct">
                Please note that it is your responsibility to ensure that any
                associated campaigns in Facebook Ads Manager have been successfully
                turned off.
            </p>
            <p>
                During deactivation we will take the following actions:
            </p>
            <ul class="list-style-type-none mb-4">
                <li>1. This account and its associated products will be deactivated</li>
                <li>2. All inventory will stop processing &amp; exporting</li>
                <li>3. Call tracking numbers will be removed</li>
                <li>4. Website tracking systems will be disabled</li>
                <li v-if="hasActiveCampaigns">
                    5. Any campaigns selected below will be disabled
                </li>
            </ul>

            <template v-if="hasActiveCampaigns">
                <h3>Disable Campaigns</h3>
                <p>
                    Select the campaigns you want to be disabled:
                </p>
                <div class="mb-4">
                    <div
                        v-for="(campaign, index) in disableCampaigns"
                        :key="`${campaign.id}-${index}`"
                        class="disable-campaigns-item mb-2">
                        <span class="ml-3">{{ campaign.name }}</span>
                        <v-btn
                            icon
                            @click="removeCampaignToDisable(campaign)">
                            <v-icon color="white">
                                highlight_off
                            </v-icon>
                        </v-btn>
                    </div>
                </div>
                <div class="mb-4 layout wrap">
                    <template>
                        <v-autocomplete
                            v-model="selectedCampaigns"
                            :items="campaigns"
                            item-text="name"
                            item-value="id"
                            return-object
                            light
                            color="grey lighten-2"
                            label="Add Campaign to Disable"
                            placeholder="Search..." />
                    </template>
                    <div class="flex md3 text-xs-right">
                        <v-btn
                            type="button"
                            :loading="loading"
                            depressed
                            outline
                            large
                            color="primary"
                            class="ma-0 text-none font-weight-bold"
                            @click="addCampaignToDisable">
                            Add
                        </v-btn>
                    </div>
                </div>
            </template>
            <v-checkbox
                v-model="deactivateAgreementVerified"
                color="primary"
                class="ma-0 py-1 styled-checkbox"
                :rules="requiredRules">
                <template #label>
                    <p>
                        Check here to confirm that you agree to deactivate
                        this account
                        <strong v-if="hasActiveCampaigns">
                            and acknowledge that you are responsible for verifying that the
                            campaigns were properly disabled within Facebook's Ads Manager
                        </strong>
                        <strong v-else-if="dealerHasAdProduct">
                            and acknowledge that you are responsible for any campaigns
                            that may still be active with Facebook's Ads Manager
                        </strong>
                        in line with our
                        <a
                            href="https://buyerbridge.io/our-terms/"
                            target="_blank">
                            <span>terms of use</span>
                        </a>.
                    </p>
                </template>
            </v-checkbox>
            <div class="text-xs-center mt-5">
                <styled-button
                    :disabled="!deactivateAgreementVerified"
                    :loading="loading"
                    class="play-button"
                    @click.prevent="confirmDeactivation = true">
                    Deactivate {{ dealer.name }}
                </styled-button>
            </div>
        </v-form>
        <confirm-deactivation-modal
            v-model="confirmDeactivation"
            @deactivate="deactivateDealersRequest" />
    </div>
</template>

<script>
import ConfirmDeactivationModal from '@/components/dealers/settings/panels/ConfirmDeactivationModal';
import StyledButton from '@/components/globals/StyledButton';
import { requiredRules } from '@/helpers/validationRules';
import { mapGetters, mapState } from 'vuex';


export default {
    components: {
        ConfirmDeactivationModal,
        StyledButton
    },
    data() {
        return {
            loading: false,
            error: false,
            deactivateAgreementVerified: false,
            requiredRules,
            campaigns: [],
            disableCampaigns: [],
            confirmDeactivation: false,
            selectedCampaigns: null
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer,
            dealers: (state) => state.dealer.dealers,
            currentDealer: (state) => state.dealer.currentDealer
        }),
        ...mapGetters([
            'dealerHasAdProduct',
            'dealerFacebookAdAccount'
        ]),
        hasActiveCampaigns() {
            return (
                this.dealerHasAdProduct &&
                (this.campaigns.length || this.disableCampaigns.length)
            );
        }
    },
    mounted() {
        this.getCampaigns();
    },
    methods: {
        async getCampaigns() {
            this.disableCampaigns = [];
            this.campaigns = [];

            try {
                const fields = [
                    'id',
                    'name'
                ];
                const url = `/dealers/${this.currentDealer.id}/graph-api/ad-account/campaigns`;
                const response = await this.$http.get(url, {
                    fields: fields.join(','),
                    filtering: [{
                        field: 'effective_status',
                        operator: 'IN',
                        value: ['ACTIVE']
                    }]
                });
                if (response.data?.length) {
                    response.data.forEach(campaign => {
                        this.disableCampaigns.push(campaign);
                    });
                }
            } catch (error) {
                console.log('Error loading dealer campaigns', error);
            }
        },
        async deactivateDealersRequest() {
            this.loading = true;
            this.error = false;
            try {
                let deactivatedData = {};

                if (this.disableCampaigns.length) {
                    deactivatedData = {
                        'disable_campaigns': this.disableCampaigns.map(campaign => campaign.id)
                    };
                }

                const requestDeactivate = await this.$http.post(`/dealers/${this.currentDealer.id}/deactivate`, deactivatedData);

                if (requestDeactivate.status == 200) {

                    // Navigate to the dealers page
                    this.$router.push({
                        path:'/dealers',
                        query: {
                            account_deactivated: requestDeactivate.data.message
                        }
                    }, () => {
                        // Once we've navigated away reset the current dealer to the first available
                        const newCurrentDealer = this.dealers.find(dealer => dealer.id !== this.currentDealer.id);
                        this.$store.dispatch('updateDealerById', newCurrentDealer.id);
                    });
                }
            } catch(error) {
                console.error('Error:', error);
                this.error = true;
                this.$emit('error', error);
            } finally {
                this.loading = false;
            }
        },
        addCampaignToDisable() {
            this.disableCampaigns.push(this.selectedCampaigns);
            this.campaigns.splice(this.campaigns.indexOf(this.selectedCampaigns), 1);
            this.selectedCampaigns = null;
        },
        removeCampaignToDisable(campaign) {
            this.campaigns.push(campaign);
            this.disableCampaigns.splice(this.disableCampaigns.indexOf(campaign), 1);
        }
    }
};
</script>
<style lang="scss">
    .list-style-type-none {
        list-style-type: none;
    }
    .disable-campaigns-item {
        border-radius: 5px;
        background-color: #8F9EA6;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 7px 0;
        .v-btn--icon {
            margin-left: auto;
            height: 10px;
        }
    }
</style>
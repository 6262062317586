<template>
    <div>
        <div
            v-if="statusLoading"
            class="text-xs-center my-5">
            <p>Checking feature status...</p>
            <styled-loader :size="40" />
        </div>
        <div
            v-else
            class="pb-4">
            <div v-if="latestFeatureStatus.length">
                <h3 class="feature-status-heading">
                    Items That Need Your Attention
                </h3>
                <div
                    v-for="(feature, index) in latestFeatureStatus"
                    :key="feature.feature"
                    :class="[
                        'feature-status mb-1',
                        index % 2 ? 'even' : 'odd'
                    ]">
                    <div class="layout">
                        <div class="flex xs12 md4 mt-1">
                            <button
                                class="feature-button layout align-center text-xs-left"
                                @click="$store.dispatch('routeToStepByFeature', feature.feature)">
                                <div class="ml-3">
                                    <status-icon
                                        value="warning"
                                        size="17"
                                        :color="colors.red"
                                        class="mr-2" />
                                    <span>{{ feature.display_name }}</span>
                                </div>
                            </button>
                        </div>
                        <div class="feature-status-error flex xs12 md8 mt-1">
                            <div class="mb-2">
                                <p
                                    v-for="error in feature.errors"
                                    :key="error">
                                    {{ error.replace(/\.$/, '') }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="text-xs-right">
                        <a
                            v-if="feature.editable"
                            href="#"
                            @click.prevent="$store.dispatch('routeToStepByFeature', feature.feature)">
                            Fix it!
                        </a>
                        <span v-else-if="feature.message">
                            {{ feature.message }}
                        </span>
                        <span v-else>
                            Please
                            <a
                                href="#"
                                @click.prevent="$store.dispatch('createOnboardingSupportTicket')">
                                contact support
                            </a>
                            for assistance with this error
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import StyledLoader from '../../globals/StyledLoader';
import StatusIcon from '../../globals/StatusIcon';
import { mapState, mapGetters } from 'vuex';
import colors from '../../../helpers/colors';

const alwaysShow = ['facebook_marketplace'];

const uneditableFeatures = [
    {
        feature: 'google_analytics',
        status: null,
        message: null
    },{
        feature: 'site_provider',
        status: 'incomplete',
        message: 'Please contact support to configure this provider'
    },{
        feature: 'facebook_custom_conversion',
        status: null,
        message: null
    },{
        feature: 'facebook_lead_form',
        status: null,
        message: null
    },{
        feature: 'facebook_product_catalog',
        status: 'incomplete', // When a catalog doesn't have inventory
        message: null
    },{
        feature: 'facebook_automotive_catalog',
        status: 'incomplete', // When a catalog doesn't have inventory
        message: null
    },{
        feature: 'tiktok_catalog',
        status: 'incomplete', // When a catalog doesn't have inventory
        message: null
    },{
        feature: 'snapchat_catalog',
        status: 'incomplete', // When a catalog doesn't have inventory
        message: null
    },{
        feature: 'pinterest_catalog',
        status: 'incomplete', // When a catalog doesn't have inventory
        message: null
    },{
        feature: 'facebook_marketplace',
        status: 'missing_initial_setup',
        message: 'Please use the dealer settings page to opt back into Marketplace'
    }
];

export default {
    components: {
        StyledLoader,
        StatusIcon
    },
    data() {
        return {
            colors
        };
    },
    computed: {
        ...mapState({
            statusLoading: (state) => state.onboarding.statusLoading,
            latestStep: (state) => state.onboarding.latestStep
        }),
        ...mapGetters([
            'requiredFeatures',
            'incompleteFeatureStatus',
            'currentSteps'
        ]),
        latestFeatureStatus() {
            const latestSteps = this.currentSteps.slice(0, this.latestStep + 1);

            const latestFeatures = latestSteps.reduce((features, step) => {
                return features.concat(step.dependencies);
            }, []);

            return this.incompleteFeatureStatus
                .filter(feature => {
                    const featureCanBeShown = (
                        latestFeatures.includes(feature.feature) ||
                        alwaysShow.includes(feature.feature)
                    );

                    const featureIsRequired = this.requiredFeatures.includes(feature.feature);
                    return featureCanBeShown && featureIsRequired;
                })
                .map(feature => {

                    // Defaults
                    feature.editable = true;
                    feature.message = null;

                    // Determine if this feature isn't editable - meaning that there's no step
                    // that can be used to resolve whatever the issue is
                    const uneditableFeature = uneditableFeatures.find(uneditableFeature => {
                        return (uneditableFeature.feature === feature.feature);
                    });

                    // If the feature is editable just return it since there's no customizations needed
                    if (!uneditableFeature) {
                        return feature;
                    }

                    // If the uneditable feature considers all status uneditable set it
                    if (uneditableFeature.status === null) {
                        feature.editable = false;
                    }

                    // This is necessary because some feature's can be edited at certain
                    // stages but aren't at others (e.g. if a catalog doesn't have inventory
                    // we don't have a way [yet] for them to solve that)
                    if (uneditableFeature.status === feature.status) {
                        feature.editable = false;
                    }

                    // Allow a custom message to be set
                    if (uneditableFeature.message) {
                        feature.message = uneditableFeature.message;
                    }

                    return feature;
                });
        }
    }
};
</script>


<style lang="scss" scoped>

.feature-status-heading {
    text-transform: uppercase;
    color: $carnation;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 4px;
}

.feature-status {
    position: relative;
    padding: 10px 10px 10px 6px;
    font-size: 12px;
    &::before {
        content: '';
        position: absolute;
        width: 2px;
        background-color: rgba($gray, 0.3);
        top: 5px;
        bottom: 5px;
        left: 5px;
    }
    &.odd {
        background-color: rgba($gray, 0.05);
    }
    .feature-status-error {
        p {
            font-weight: 600;
        }
    }
}

.feature-button {
    &:active,
    &:focus {
        outline: none;
    }
}
</style>


<template>
    <styled-card
        show-more
        :value="false">
        <template #heading>
            Lead Destinations
        </template>
        <template #action-buttons>
            <action-button
                icon="lead-destination"
                @click="$emit('input',true)">
                Add Destination
            </action-button>
            <action-button
                icon="cog"
                @click="displayLeadSettingsPanel = true">
                Manage
            </action-button>
        </template>

        <lead-settings
            v-model="displayLeadSettingsPanel" />

        <styled-dialog v-model="displayDeleteLeadDestinationModal">
            <template #heading>
                <h2>Delete Lead Destination</h2>
            </template>
            <v-alert
                :value="error"
                type="error"
                transition="fade-transition"
                class="mb-4">
                An error occurred.  Please try again or contact support.
            </v-alert>

            <p>
                Are you sure you would like to delete {{ toBeDeletedDestination }}
                as a lead destination for {{ dealer.name }}?
            </p>
            <template #actions>
                <styled-button
                    outline
                    class="ml-auto"
                    @click="displayDeleteLeadDestinationModal = false">
                    Close
                </styled-button>
                <styled-button
                    class="ml-3"
                    @click="deleteLeadDestination(selectedLeadDestination)">
                    Delete
                </styled-button>
            </template>
        </styled-dialog>



        <slot name="heading" />

        <div
            v-if="dealer.lead_destinations && dealer.lead_destinations.data.length"
            class="py-4 pl-5 pr-4">
            <div
                v-for="leadDestination in dealer.lead_destinations.data"
                :key="leadDestination.id"
                class="layout align-center pb-4">
                <div class="flex xs6">
                    <data-field v-if="leadDestination.lead_sender_class_name == WEBHOOK_LEAD_SENDER">
                        <template #label>
                            Webhook
                        </template>
                        {{ leadDestination.lead_sender_configuration.webhook_url }}
                    </data-field>
                    <data-field v-else>
                        <template #label>
                            Email
                        </template>
                        {{ leadDestination.lead_sender_configuration.email }}
                    </data-field>
                </div>
                <div class="flex xs3">
                    <data-field>
                        <template #label>
                            Type
                        </template>
                        <span class="text-uppercase">
                            {{ leadDestinationType(leadDestination) }}
                        </span>
                    </data-field>
                </div>
                <div class="flex xs2">
                    <data-field>
                        <template #label>
                            Overridden?
                        </template>
                        <div class="overriden ml-2">
                            <span class="overriden-text">{{ leadDestinationOverriden(leadDestination) }}</span>
                            <styled-tooltip
                                v-if="leadDestinationOverriden(leadDestination) === 'YES'"
                                position="top"
                                :open-delay="0"
                                :close-delay="0">
                                <template #content>
                                    <div>
                                        <div>Overriden Email: {{ leadDestination.lead_sender_configuration.override_from_sender_email }}</div>
                                        <div>Overriden Name: {{ leadDestination.lead_sender_configuration.override_from_sender_name }}</div>
                                    </div>
                                </template>
                                <span>
                                    <icon
                                        name="info"
                                        color="#909FA8"
                                        size="19" />
                                </span>
                            </styled-tooltip>
                        </div>
                    </data-field>
                </div>
                <div class="flex xs1 text-xs-right">
                    <action-button
                        class="mx-2"
                        icon="trash"
                        @click="openDeleteLeadModal(leadDestination)" />
                </div>
            </div>
        </div>
        <div v-else>
            <v-flex class="py-4 px-5">
                <p class="text-xs-center mt-4 mb-5">
                    No lead destinations configured
                </p>
            </v-flex>
        </div>
    </styled-card>
</template>

<script>
import { mapState } from 'vuex';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import DataField from '@/components/globals/DataField';
import StyledDialog from '@/components/globals/StyledDialog';
import StyledTooltip from '@/components/globals/StyledTooltip';
import Icon from '@/components/globals/Icon';
import StyledButton from '@/components/globals/StyledButton';
import LeadSettings from './panels/LeadSettings';
import { XML_EMAIL_LEAD_SENDER, TEXT_EMAIL_LEAD_SENDER, WEBHOOK_LEAD_SENDER } from '@/helpers/globals';

export default {
    name: 'LeadDestinations',
    components: {
        LeadSettings,
        StyledCard,
        ActionButton,
        DataField,
        StyledDialog,
        StyledTooltip,
        Icon,
        StyledButton
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            displayAddDestinationDialog: false,
            displayLeadSettingsPanel: false,
            displayDeleteLeadDestinationModal: false,
            selectedLeadDestination: null,
            error: null,
            XML_EMAIL_LEAD_SENDER,
            TEXT_EMAIL_LEAD_SENDER,
            WEBHOOK_LEAD_SENDER
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer
        }),
        toBeDeletedDestination() {
            if (this.selectedLeadDestination?.lead_sender_class_name == this.WEBHOOK_LEAD_SENDER) {
                return this.selectedLeadDestination.lead_sender_configuration.webhook_url;
            }

            if (this.selectedLeadDestination) {
                return this.selectedLeadDestination.lead_sender_configuration.email;
            }

            return null;
        }
    },
    methods: {
        openAddDestinationDialog() {
            this.displayAddDestinationDialog = true;
        },
        onAddLeadDestination(data) {
            // Pass updated event to the parent
            this.$emit('updated', data);
        },
        leadDestinationType(leadDestination) {
            switch (leadDestination.lead_sender_class_name) {
                case this.TEXT_EMAIL_LEAD_SENDER :
                    return 'Plain Text';
                case this.XML_EMAIL_LEAD_SENDER :
                    return 'ADF XML';
                case this.WEBHOOK_LEAD_SENDER :
                    return 'WEBHOOK';
            }
        },
        leadDestinationOverriden(leadDestination) {
            const overridenEmail = leadDestination.lead_sender_configuration.override_from_sender_email;
            const overridenName = leadDestination.lead_sender_configuration.override_from_sender_name;
            if (leadDestination.lead_sender_class_name === this.TEXT_EMAIL_LEAD_SENDER && (!overridenEmail && !overridenName)) {
                return 'NO';
            }
            if (leadDestination.lead_sender_class_name === this.TEXT_EMAIL_LEAD_SENDER && (overridenEmail || overridenName)) {
                return 'YES';
            }
            return 'N/A';
        },
        openDeleteLeadModal(leadDestination) {
            this.selectedLeadDestination = leadDestination;
            this.displayDeleteLeadDestinationModal = true;
        },
        async deleteLeadDestination(leadDestination) {
            this.error = null;
            try {
                const data = {
                    related: [{
                        related_object_key: leadDestination.id
                    }]
                };
                await this.$http.put(`dealers/${this.dealer.id}/lead_destinations/detach`, data);
                this.$emit('updated');
                this.selectedLeadDestination = null;
                this.displayDeleteLeadDestinationModal = false;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error deleting lead destination:', error);
                this.error = 'Error deleting lead destination';
            }
        }
    }
};
</script>

<style scoped>
.overriden {
    display: flex;
    text-transform: uppercase;
}
.overriden-text {
    display: inline-block;
    margin-right: 5px;
}
</style>

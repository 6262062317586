<template>
    <div class="process-single">
        <div class="process-name">
            {{ processName }}
        </div>
        <div class="process-name">
            {{ processStatus }}
        </div>
        <div class="process-name">
            <template v-if="totalItems || totalItems === 0">
                <span>{{ currentItems }}/{{ totalItems }}</span>
            </template>
            <template v-else>
                --
            </template>
        </div>
        <div class="process-name-progress">
            <div class="progress-back" />
            <div
                class="progress-front"
                :style="'width:'+progress+'%;'" />
        </div>
    </div>
</template>

<script>
import { capitalizeWord } from '@/helpers/capitalizeWord';

export default {
    props: {
        process: {
            type: Object,
            required: true
        },
        processName: {
            type: String,
            required: true
        }
    },
    computed: {
        processStatus() {
            const status = this.process?.status ?? 'In queue';
            return capitalizeWord(status);
        },
        totalItems() {
            return this.process?.data?.total ?? '';
        },
        currentItems() {
            return this.process?.data?.processed ?? '';
        },
        progress() {
            return (+this.currentItems / this.totalItems * 100).toFixed(2);
        }
    }
};
</script>

<style lang="scss">
.process-single {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}
.process-name {
    color: #3F4649;
    width: 20%;
}
.process-name-progress {
    width: 40%;
    position: relative;
    height: 13px;
}
.progress-back {
    height: 100%;
    width: 100%;
    background: #7FD0F4;
}
.progress-front {
    position: absolute;
    background: $blue-bg;
    height: 100%;
    top: 0;
    left: 0;
    bottom:0;
}
</style>
<template>
    <styled-card id="channels-overview">
        <template #heading>
            Channels Overview
        </template>
        <template #action-buttons>
            <action-button
                icon="subscribe"
                @click="subscriptionsPanelActive = true">
                Subscriptions
            </action-button>
            <action-button
                icon="cog"
                @click="showChannelSettings">
                Manage
            </action-button>
        </template>
        <loader v-if="loading" />
        <div
            v-else
            class="channels">
            <div
                v-for="channel in enrolledChannels"
                :key="channel.id"
                class="channel">
                <div class="badge">
                    <img
                        class="logo"
                        :src="'/img/platforms/' + fbToMeta(channel.name, true, true) + '.svg'">
                    <p class="name">
                        {{ fbToMeta(channel.display_name) }}
                    </p>
                </div>
                <div class="products">
                    <p class="label">
                        Current subscription(s)
                    </p>
                    <div class="products">
                        <p
                            v-for="product in channel.products"
                            :key="product.id"
                            class="product">
                            <product-logo
                                class="image"
                                :height="'22px'"
                                type="horizontal"
                                :value="product.id"
                                :name="product.display_name" />
                        </p>
                    </div>
                </div>
                <div class="settings">
                    <p>
                        <a
                            href="#"
                            @click.prevent="showChannelSettingsTab(channel.name)">Settings</a>
                    </p>
                    <p>
                        <a
                            href="#"
                            @click.prevent="subscriptionsPanelActive = true">Subscription</a>
                    </p>
                    <template v-if="channelHasCallTracking(channel)">
                        <p>
                            <a
                                href="#"
                                @click.prevent="callTrackingPanelActive = true">Call Tracking</a><span class="state">({{ callTrackingNumbers.length }} active numbers)</span>
                        </p>
                    </template>
                    <template v-if="channelHasChatbot(channel)">
                        <p>
                            <a
                                href="#"
                                @click.prevent="chatbotPanelActive = true">Chatbot</a><span class="state">({{ chatbotEnabled ? 'Enabled' : 'Disabled' }})</span>
                        </p>
                    </template>
                </div>
                <div class="upgrade">
                    <styled-button
                        v-if="channel.unfinished_onboarding_id"
                        :to="{ name: 'dealer-onboarding',
                               params: {
                                   dealer_id: dealer.id,
                                   onboarding_id: channel.unfinished_onboarding_id
                               }}">
                        CONTINUE ONBOARDING
                    </styled-button>
                    <styled-button
                        v-else-if="checkUpgradeEligibility(channel)"
                        :to="{ name: 'channel-setup-product-selection', params: { dealer_id: dealer.id, channel: channel.name }}">
                        UPGRADE
                    </styled-button>
                </div>
            </div>
        </div>
        <div
            v-if="!loading && notEnrolledChannels.length > 0"
            class="additional-channels">
            <div class="messaging">
                <h2>Onboard to Additional Channels</h2>
                <p>
                    To active additional channels for your business it is as easy as clicking the setup button underneath each channel
                    to start the onboarding process. Once a channel is onboarded, new reports will be available to review each channel metrics.
                </p>
            </div>
            <div
                v-if="notEnrolledChannels.length > 0"
                class="channel-setup">
                <div
                    v-for="channel in notEnrolledChannels"
                    :key="channel.id"
                    class="channel">
                    <div class="badge">
                        <img
                            class="logo"
                            :src="'/img/platforms/' + channel.name + '.svg'">
                        <p class="name">
                            {{ channel.display_name }}
                        </p>
                        <router-link
                            v-if="channel.authStatus"
                            class="setup-button"
                            :to="{ name: 'channel-setup-product-selection', params: { dealer_id: dealer.id, channel: channel.name }}">
                            SETUP
                        </router-link>
                        <styled-button
                            v-else
                            class="setup-button"
                            @click="openAuthenticateModal(channel)">
                            AUTHENTICATE AND SETUP
                        </styled-button>
                        <p
                            v-if="channel.name === 'facebook'"
                            target="_blank"
                            class="learn-more">
                            <a
                                href="https://buyerbridge.io/facebook-automotive-inventory-ads/"
                                target="_blank">Learn More</a>
                        </p>
                        <p
                            v-else-if="channel.name === 'snapchat'"
                            target="_blank"
                            class="learn-more">
                            <a
                                href="https://buyerbridge.io/social-platforms/snapchat/"
                                target="_blank">Learn More</a>
                        </p>
                        <p
                            v-else-if="channel.name === 'tiktok'"
                            target="_blank"
                            class="learn-more">
                            <a
                                href="https://buyerbridge.io/social-platforms/tiktok/"
                                target="_blank">Learn More</a>
                        </p>
                        <p
                            v-else-if="channel.name === 'pinterest'"
                            class="learn-more">
                            <a
                                href="https://buyerbridge.io/social-platforms/pinterest/"
                                target="_blank">Learn More</a>
                        </p>
                        <p
                            v-else-if="channel.name === 'google'"
                            class="learn-more">
                            <a
                                href="https://support.buyerbridge.com/knowledge/google-vehicle-listings"
                                target="_blank">Learn More</a>
                        </p>
                        <p
                            v-else
                            class="coming-soon">
                            COMING SOON
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <platform-authentication-slide-out
            v-model="showAuthenticateSlideOut"
            :channel="authenticateModalData"
            slide-out-title="Set up Token user"
            :redirect-path="'/dealers/' + dealer.id + '/channel-setup/' + authenticateModalData.name" />
        <call-tracking v-model="callTrackingPanelActive" />
        <subscriptions
            v-model="subscriptionsPanelActive"
            :channels="enrolledChannels" />
        <chatbot-dialog
            v-model="chatbotPanelActive" />
    </styled-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import StyledButton from '@/components/globals/StyledButton';
import Loader from '@/components/globals/Loader';
import CallTracking from './panels/call-tracking/CallTracking';
import Subscriptions from './panels/Subscriptions';
import ChatbotDialog from '@/components/globals/ChatbotDialog';
import ProductLogo from '@/components/globals/ProductLogo';
import PlatformAuthenticationSlideOut from '@/components/resellers/settings/PlatformAuthenticationSlideOut';
import { canUpgradeChannel } from '@/components/onboarding/channel-setup/channels';
import { PLATFORM_FACEBOOK } from '@/helpers/globals';
import {
    TOGGLE_CHANNEL_SETTINGS_SLIDEOUT,
    SET_CHANNEL_SETTINGS_TAB
} from '@/store/mutation-types';
import { getNotEnrolledChannels } from '@/helpers/getNotEnrolledChannels';
import { getAvailableChannels } from '@/helpers/getAvailableChannels';
import { fbToMeta } from '@/helpers/fbToMeta';

export default {
    name: 'ChannelsOverview',
    components: {
        StyledCard,
        ActionButton,
        StyledButton,
        Loader,
        CallTracking,
        Subscriptions,
        ChatbotDialog,
        ProductLogo,
        PlatformAuthenticationSlideOut,
    },
    data() {
        return {
            loading: false,
            availableChannels: [],
            notEnrolledChannels: {},
            showAuthenticateSlideOut: false,
            authenticateModalData: {},
            callTrackingPanelActive: false,
            subscriptionsPanelActive: false,
            chatbotPanelActive: false,
            chatbotEnabled: false,
            fbToMeta
        };
    },
    computed: {
        ...mapGetters([
            'dealerPlatforms',
            'dealerProducts',
            'activeDealerOnboardings'
        ]),
        ...mapState({
            dealer: state => state.dealer.currentDealer,
            agency: state => state.agency.currentAgency
        }),
        callTrackingNumbers() {
            return this.dealer?.twilio_lead_sources.data || [];
        },
        dealerChannels() {
            const channels = this.dealerPlatforms.map(platform => {
                return {
                    ...platform,
                    products: this.dealerProducts.filter( product => {
                        const visibility = product.product_settings?.visibility;
                        return product.platform_id == platform.id
                            && visibility !== 'coming_soon';
                    })
                };
            });

            const activeOnboardings = this.activeDealerOnboardings;
            const channelsWithCompletingInfo = channels.map(function(channel) {
                activeOnboardings.forEach(onboarding =>{
                    if (!channel.unfinished_onboarding_id) {
                        if (onboarding.products.data[0]?.platform_id == channel.products[0]?.platform_id) {
                            channel.unfinished_onboarding_id = onboarding.id;
                        }
                    }
                });
                return channel;
            });
            return channelsWithCompletingInfo;
        },
        enrolledChannels() {
            return this.dealerChannels.filter(channel => channel.products.some( product => {
                const visibility = product.product_settings?.visibility;
                return product.public == true
                    && visibility !== 'coming_soon';
            }));
        },
    },
    watch: {
        dealer() {
            this.updateChannels();
        }
    },
    created() {
        this.$title = 'Account Settings';
        this.updateChannels();
    },
    methods: {
        async updateChannels() {
            this.loading = true;
            await this.loadDealerPlatforms();
            this.notEnrolledChannels = await getNotEnrolledChannels(this.availableChannels, this.enrolledChannels, this.agency.id, true);
            this.setFacebookAuthStatus();
            this.loading = false;
        },
        async loadDealerPlatforms() {
            const platforms = await this.$apiRepository.getPlatforms();
            this.availableChannels = getAvailableChannels(platforms);
            await this.checkChatbotStatus();
            this.loading = false;
        },
        checkUpgradeEligibility(channel) {
            const enrolledProducts = channel.products;
            const enrolledProductIds = enrolledProducts.map(product => product.id);
            return canUpgradeChannel(channel.id, enrolledProductIds);
        },
        channelHasChatbot(channel) {
            const productIds = channel.products.map(p => p.id);
            return channel.id == 1 && (productIds.includes(20) || productIds.includes(22));
        },
        channelHasCallTracking(channel) {
            const productIds = channel.products.map(p => p.id);
            return channel.id == 1 && (productIds.includes(21) || productIds.includes(22));
        },
        async checkChatbotStatus() {
            const response = await this.$http.post(`dealers/${this.dealer.id}/validate/chatbot/status`);

            if (response.data?.status == 'complete') {
                this.chatbotEnabled = true;
            }
        },
        showChannelSettings() {
            this.$store.commit(TOGGLE_CHANNEL_SETTINGS_SLIDEOUT, true);
        },
        showChannelSettingsTab(value) {
            this.$store.commit(SET_CHANNEL_SETTINGS_TAB, value);
            this.showChannelSettings();
        },
        openAuthenticateModal(data) {
            this.showAuthenticateSlideOut = true;
            this.authenticateModalData = data;
        },
        setFacebookAuthStatus() {
            // Check if the agency does not have the facebook_business_id set
            const hasFacebookBusinessManagerId = this.agency?.facebook_business_id ? true : false;
            // Set the authStatus for facebook to false if the agency does not have the facebook_business_id set
            this.notEnrolledChannels = this.notEnrolledChannels.map(channel => {
                if (channel.name === PLATFORM_FACEBOOK) {
                    channel.authStatus = hasFacebookBusinessManagerId ? true : false;
                }
                return channel;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.logo {
    width: 50px;
    height: auto;
}
p {
    margin: 0;
}
.channels {
    display: flex;
    flex-direction: column;

    .channel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 30px 60px;
        height: 175px;
        border-bottom: 1px solid $alabaster-dark;

        .badge {
            width: 90px;
            text-align: center;

            .name {
                font-size: 20px;
                color: $blue-primary;
            }
        }

        .products {
            display: flex;
            flex-direction: column;
            align-items: center;

            .label {
                font-size: 15px;
                color: $blue-primary;
            }
            .product {
                width: 150px;
                height: auto;
                margin-top: 15px;
                img {
                    width: 20px;
                    height: auto;
                }
            }
        }

        .settings {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 30px;
            height: 90px;
            min-width: 350px;
            border-left: 1px solid $alabaster-dark;
            border-right: 1px solid $alabaster-dark;
            p {
                margin: 5px 0;
            }
        }
        .state {
            margin-left: 5px;
        }
        .upgrade {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 300px;
            justify-content: center;
        }
    }
}


.additional-channels {
    display: flex;
    .messaging {
        display: flex;
        flex-direction: column;
        max-width: 440px;
        padding: 30px;
        h2 {
            margin-bottom: 15px;
        }
        .learn-more {
            margin-top: 5px;
            align-self: flex-end;
        }
    }
    .channel-setup {
        display: flex;
        flex: 1;
        justify-content: space-around;
        align-items: center;
        padding: 30px;
        .badge {
            width: 120px;
            text-align: center;

            img {
                width: 30px;
                height: auto;
            }
            .coming-soon {
                margin-top: 10px;
                font-size: 10px;
                font-weight: 700;
                color: $carnation;
            }
            .setup-button {
                text-decoration: none;
                margin-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $blue-bg;
                color: $white;
                font-weight: 600;
                border-radius: 5px;
                padding: 5px 10px;
                height: auto;
            }
            .learn-more {
                margin-top: 5px;
            }
        }
    }
}
</style>

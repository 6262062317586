<template>
    <div>
        <hub-spot-form
            :form-id="formId"
            :form-submit-callback="handlePreSubmit"
            :field-values="fieldValues"
            :check-box-field-values="checkBoxFieldValues"
            hidden
            @complete="formSubmitted" />
    </div>
</template>

<script>
import HubSpotForm from '@/components/globals/HubSpotForm';
import { mapState } from 'vuex';

export default {
    components: {
        HubSpotForm,
    },
    props: {
        products: {
            type: Array,
            required: true
        },
        platform: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            submitRequestDialogOpen: false,
            value: true,
            formId: '76732246-410a-48bd-a093-6b9b59916eb6',
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            user: (state) => state.user.user
        }),
        fieldValues() {
            return {
                'TICKET.agency_id': this.currentDealer.agency.id,
                'TICKET.agency_name': this.currentDealer.agency.name,
                'TICKET.form___dealer_name': this.currentDealer.name,
                'TICKET.ticket___buyerbridge_id': this.currentDealer.id,
                'TICKET.ticket_contact_email': this.user.email,
                'email': this.user.email,
                'TICKET.initial_ticket_owner_contact_name': this.user.name,
                'TICKET.subject': `TOB [BB] - ${this.currentDealer.agency.name} - ${this.currentDealer.name} - ${this.platform}`,
            };
        },
        checkBoxFieldValues() {
            let platformName = this.platform;
            if (this.platform === 'Microsoft') {
                platformName = 'MSN'; // This is the product name in HubSpot
            }

            return {
                'TICKET.products__bb_': this.products.map(product => {
                    return product.display_name;
                }),
                'TICKET.channel': [platformName]
            };
        }
    },
    methods: {
        handlePreSubmit($form) {
            const $contentField = $form.find('[name="TICKET.content"]');
            $contentField.val(`
            • Organization: ${this.currentDealer.agency.name}
            • Account(s): ${this.currentDealer.name}
            • Account ID(s): ${this.currentDealer.id}
            • Type: Activate Account Channel Product
            • Channel: ${this.platform}
            • Products: ${this.products.map(product => product.display_name).join(', ')}
            • Contact: ${this.user.name} ${this.user.email}
            • Notes:
            ${$contentField.val()}`);
        },
        formSubmitted() {
            this.$emit('complete');
        }
    },
};
</script>

<template>
    <styled-dialog
        :value="value"
        @input="$emit('input', $event)">
        <template>
            <h2 class="mb-4">
                Are you sure you want to deactivate<br> {{ dealer.name }}?
            </h2>
            <v-layout align-center>
                <div class="flex xs12 pa-4 lightshade">
                    <v-checkbox
                        v-model="deactivateAccount"
                        color="primary"
                        class="ma-0 py-1 styled-checkbox">
                        <template #label>
                            <p>
                                Yes, I confirm that I want to deactivate {{ dealer.name }}
                            </p>
                        </template>
                    </v-checkbox>
                </div>
            </v-layout>
            <div class="layout justify-center">
                <styled-button
                    class="mt-5"
                    :disabled="!deactivateAccount"
                    @click.prevent="$emit('input', false); $emit('deactivate')">
                    Deactivate {{ dealer.name }}
                </styled-button>
            </div>
        </template>
        <template #actions>
            <a
                href="#"
                class="red--text"
                @click.prevent="$emit('input', false)">
                Cancel
            </a>
        </template>
    </styled-dialog>
</template>

<script>
import { mapState } from 'vuex';
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';

export default {
    components: {
        StyledDialog,
        StyledButton,
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer
        })
    },
    data() {
        return {
            deactivateAccount: false
        };
    },
};
</script>

<style lang="scss" scoped>
.lightshade {
    background-color: $gray-lightshade;
}
</style>
<template>
    <div>
        <v-radio-group
            v-model="currentFeedRdiKnown"
            :rules="requiredRules"
            class="styled-radio mb-3"
            row>
            <v-radio
                label="Yes, I have the ID"
                value="yes"
                color="primary"
                class="mb-0" />
            <v-radio
                :label="`No, I need to get it from ${feedProviderName}`"
                value="no"
                color="primary" />
        </v-radio-group>
    </div>
</template>

<script>
import { requiredRules } from '@/helpers/validationRules';

export default {
    props: {
        feedProviderName: {
            type: String,
            default: ''
        },
        feedRdiKnown: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            requiredRules,
            currentFeedRdiKnown: null
        };
    },
    watch: {
        feedRdiKnown(value) {
            if (this.currentFeedRdiKnown !== value) {
                this.currentFeedRdiKnown = value;
            }
        },
        currentFeedRdiKnown(value) {
            this.$emit('input', value);
        }
    },
    mounted() {
        this.currentFeedRdiKnown = this.feedRdiKnown;
    }
};
</script>

export const getCurrentProcessInfo = (allProcesses, id) => {

    let processes = allProcesses;

    if (id) {
        processes = allProcesses.filter(process => process.remote_dealer_identifier_id == id);
    }

    const runningProcess = processes.find(process => {
        return ['feed-processor', 'vdp-url-processor', 'upload-exports'].includes(process.process_name) && (process.status === 'processing');
    }) || null;

    const feedProcess = processes.find(process => {
        return process.process_name === 'feed-processor' && !process.status.includes('processing', 'failed');
    }) || null;

    const urlProcess = processes.find(process => {
        return process.process_name === 'vdp-url-processor' && !process.status.includes('processing', 'failed');
    }) || null;

    const uploadProcess = processes.find(process => {
        return process.process_name === 'upload-exports' && !process.status.includes('processing', 'failed');
    }) || null;

    const totalVehicles = feedProcess?.details?.rdi_vehicles ?? 0;
    const lastFeed = processes.find(process => process.process_name === 'feed-processor');
    const lastVdp = processes.find(process => process.process_name === 'vdp-url-processor');
    const lastUpload = processes.find(process => process.process_name === 'upload-exports');
    const isProcessing = lastFeed?.status === 'processing' || lastVdp?.status === 'processing' || lastUpload?.status === 'processing';

    return {
        running_process: runningProcess,
        feed_process: feedProcess,
        url_process: urlProcess,
        upload_process: uploadProcess,
        total_vehicles: totalVehicles,
        is_processing: isProcessing
    }
}
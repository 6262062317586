import stripIndent from 'strip-indent';
import JSZip from 'jszip';
import downloadFile from './downloadFile';


export const getReadmeText = () => {
    return stripIndent(`
        Please paste the primary script (gtm-main-script.txt)
        on every page as high in the <head> of the page as possible.

        Additionally, paste the secondary script (gtm-secondary-script.txt)
        immediately after the opening <body> tag on every page.
    `).trim();
};

export const getMainScript = (containerId, agencyName = 'BuyerBridge') => {
    return stripIndent(`
        <!-- ${agencyName} Tag Manager -->
        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${containerId}');<\/script>
        <!-- End ${agencyName} Tag Manager -->
    `).trim();
};

export const downloadMainScript = (containerId) => {
    const mainScript = getMainScript(containerId);
    downloadFile(mainScript, `gtm-${containerId}-main-script.txt`);
};

export const getSecondaryScript = (containerId, agencyName = 'BuyerBridge') => {
    return stripIndent(`
        <!-- ${agencyName} Tag Manager (noscript) -->
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${containerId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        <!-- End ${agencyName} Tag Manager (noscript) -->
    `).trim();
};

export const downloadSecondaryScript = (containerId) => {
    const secondaryScript = getSecondaryScript(containerId);
    downloadFile(secondaryScript, `gtm-${containerId}-secondary-script.txt`);
};

export const downloadGtmCode = async(containerId) => {
    var zip = new JSZip();

    zip.file('readme.txt', getReadmeText());

    zip.file('gtm-main-script.txt', getMainScript(containerId));
    zip.file('gtm-secondary-script.txt', getSecondaryScript(containerId));

    // Generate the zip file asynchronously
    const content = await zip.generateAsync({ type: 'blob' });

    downloadFile(content, `${containerId}.zip`);
};

export default downloadGtmCode;

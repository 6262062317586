<template>
    <div>
        <div
            v-if="loading"
            class="text-xs-center py-5">
            <loader label="Checking for active onboardings..." />
        </div>

        <div
            v-if="!loading && activeDealerOnboardings.length"
            class="layout wrap">
            <cancel-onboarding-dialog
                v-model="cancelOnboardingDialogOpen"
                :onboarding="onboardingToCancel"
                @complete="onCancelOnboardingComplete" />


            <v-alert
                v-if="$route.query.onboarding_exit"
                type="success"
                :value="true"
                class="flex xs12 my-3"
                transition="fade-transition"
                dismissible>
                You have successfully exited onboarding and can pick up at any time below.
                Just click "Continue This Onboarding" to continue.
            </v-alert>

            <styled-card
                :value="activeOnboardingCardOpen"
                :class="{
                    'flex xs12 ma-3': true,
                    'flash-on-load--success': !!$route.query.onboarding_exit
                }">
                <template #heading>
                    <button @click="activeOnboardingCardOpen = !activeOnboardingCardOpen">
                        Active Onboarding: {{ getOnboardingName(primaryOnboarding) }}
                    </button>
                </template>

                <template #action-buttons>
                    <action-button
                        icon="trash"
                        class="mr-4"
                        @click="onCancelOnboarding(primaryOnboarding)">
                        Cancel
                    </action-button>
                    <action-button
                        icon="outbound-link"
                        @click="onContinueOnboarding(primaryOnboarding)">
                        Continue
                    </action-button>
                </template>

                <div class="pa-5">
                    <div
                        v-if="loadingPrimaryOnboarding"
                        class="py-5">
                        <loader />
                    </div>

                    <div v-else>
                        <div class="layout wrap align-center">
                            <div class="flex xs12 md9">
                                <p v-if="onboardingValid">
                                    You're all clear and ready to deploy this {{ primaryOnboarding.type }}!
                                    All you need to do is deploy it from the review step.  Please click
                                    "complete this onboarding" here to finalize the process.
                                </p>
                                <p v-else>
                                    You're not quite ready to deploy this {{ primaryOnboarding.type }}.
                                    Please review the missing dependencies below and revisit the outstanding
                                    steps as there's likely additional actions you still need to take then
                                    we can get rolling!
                                </p>
                                <div class="layout wrap">
                                    <data-field class="flex mb-3">
                                        <template #label>
                                            Type
                                        </template>
                                        {{ primaryOnboarding.type | capitalize }}
                                    </data-field>
                                    <data-field class="flex mb-3">
                                        <template #label>
                                            Started by
                                        </template>
                                        <template v-if="primaryOnboarding.user">
                                            {{ primaryOnboarding.user.name }}
                                        </template>
                                        <template v-else>
                                            -
                                        </template>
                                    </data-field>
                                    <data-field class="flex mb-3">
                                        <template #label>
                                            Left off at
                                        </template>
                                        Step {{ primaryOnboarding.configuration.currentStep + 1 }}
                                    </data-field>
                                    <data-field class="flex mb-3">
                                        <template #label>
                                            Started at
                                        </template>
                                        {{ $moment.utc(primaryOnboarding.created_at.date).local().format('MM/DD/YY @ h:mma') }}
                                    </data-field>
                                    <data-field class="flex mb-3">
                                        <template #label>
                                            Last touch
                                        </template>
                                        {{ $moment.utc(primaryOnboarding.updated_at.date).local().format('MM/DD/YY @ h:mma') }}
                                    </data-field>
                                </div>
                            </div>
                            <div class="flex xs12 hidden-sm-and-down md3 text-xs-center text-md-right">
                                <styled-button
                                    class="ml-4"
                                    @click="onContinueOnboarding(primaryOnboarding)">
                                    CONTINUE THIS ONBOARDING
                                </styled-button>
                            </div>
                        </div>

                        <incomplete-features
                            v-if="!onboardingValid"
                            class="mt-5" />

                        <template v-if="primaryOnboarding.type === 'play'">
                            <h3 class="mb-4 mt-5">
                                Budgets &amp; Targeting
                            </h3>
                            <campaign-tree />
                        </template>

                        <div
                            v-if="!onboardingValid"
                            class="layout wrap align-center mt-5">
                            <div class="flex xs12 md2 text-xs-center text-md-left order-xs2 order-md1">
                                <a
                                    href="#"
                                    class="red--text"
                                    @click.prevent="onCancelOnboarding(primaryOnboarding)">
                                    Cancel Onboarding
                                </a>
                            </div>
                            <div class="flex xs12 md8 text-xs-center order-xs1 order-md2">
                                <styled-button
                                    @click="onContinueOnboarding(primaryOnboarding)">
                                    CONTINUE THIS ONBOARDING
                                </styled-button>
                            </div>
                            <div class="flex xs12 md2 order-xs3" />
                        </div>
                    </div>
                </div>
            </styled-card>

            <styled-card
                v-if="otherOnboardings.length"
                :value="otherOnboardingCardOpen"
                class="flex xs12 ma-3">
                <template #heading>
                    <button @click="otherOnboardingCardOpen = !otherOnboardingCardOpen">
                        Other Onboardings ({{ otherOnboardings.length }})
                    </button>
                </template>
                <div class="pa-5">
                    <p class="mb-4">
                        You have {{ otherOnboardings.length }} other active onboardings that
                        need to be completed.  You don't need to complete these in any order
                        but you should definitely finish them off as soon as possible!
                    </p>
                    <v-data-table
                        :headers="headers"
                        :items="otherOnboardings"
                        hide-actions
                        class="styled-table">
                        <template #items="{ item, index }">
                            <tr
                                :class="[
                                    index % 2 ? 'even' : 'odd'
                                ]">
                                <td class="py-2">
                                    {{ item.type | capitalize }}
                                </td>
                                <td class="py-2">
                                    {{ getOnboardingName(item) }}
                                </td>
                                <td class="py-2">
                                    {{ $moment.utc(item.created_at.date).local().format('MM/DD/YY @ h:mma') }}
                                </td>
                                <td class="py-2">
                                    {{ $moment.utc(item.updated_at.date).local().format('MM/DD/YY @ h:mma') }}
                                </td>
                                <td class="py-2">
                                    Step {{ item.configuration.currentStep + 1 }}
                                </td>
                                <td class="py-2 px-4 text-xs-right">
                                    <div class="layout">
                                        <action-button
                                            icon="trash"
                                            class="mr-4"
                                            @click="onCancelOnboarding(item)">
                                            Cancel
                                        </action-button>
                                        <action-button
                                            icon="outbound-link"
                                            @click="onContinueOnboarding(item)">
                                            Continue
                                        </action-button>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </styled-card>
        </div>
    </div>
</template>

<script>

import CampaignTree from '@/components/onboarding/components/CampaignTree';
import IncompleteFeatures from '@/components/onboarding/components/IncompleteFeatures';
import Loader from '@/components/globals/Loader';
import DataField from '@/components/globals/DataField';
import ActionButton from '@/components/globals/ActionButton';
import StyledButton from '@/components/globals/StyledButton';
import StyledCard from '@/components/globals/StyledCard';
import CancelOnboardingDialog from '@/components/globals/CancelOnboardingDialog';
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
        StyledCard,
        DataField,
        CampaignTree,
        IncompleteFeatures,
        Loader,
        ActionButton,
        StyledButton,
        CancelOnboardingDialog
    },
    data() {
        return {
            loading: false,
            loadingPrimaryOnboarding: false,
            error: null,
            plays: [],
            products: [],
            onboardings: [],
            headers: [
                {
                    text: 'Type',
                    align: 'left',
                    width: '10%',
                    sortable: false
                },
                {
                    text: 'Name',
                    align: 'left',
                    width: '35%',
                    sortable: false
                },
                {
                    text: 'Started at',
                    align: 'left',
                    width: '25%',
                    sortable: false
                },
                {
                    text: 'Last touch',
                    align: 'left',
                    width: '25%',
                    sortable: false
                },
                {
                    text: 'Left off at',
                    align: 'left',
                    width: '15%',
                    sortable: false
                },
                {
                    text: '',
                    align: 'right',
                    width: '150px',
                    sortable: false
                },
            ],
            cancelOnboardingDialogOpen: false,
            onboardingToCancel: {},
            activeOnboardingCardOpen: false,
            otherOnboardingCardOpen: false,
            lastOnboardingLoaded: null
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            onboardingProductIds: (state) => state.onboarding.productIds,
            onboardingPlayId: (state) => state.onboarding.playId,
            onboardingType: (state) => state.onboarding.type
        }),
        ...mapGetters([
            'onboardingValid',
            'activeDealerOnboardings'
        ]),
        initializedOnboarding() {
            if (!this.activeDealerOnboardings.length) {
                return null;
            }

            return this.activeDealerOnboardings.find(onboarding => {
                if (onboarding.type !== this.onboardingType) {
                    return false;
                }

                if (onboarding.type === 'play') {
                    return (onboarding.play_id === this.onboardingPlayId);
                } else {
                    return onboarding.products.data.every(product => {
                        return this.onboardingProductIds.includes(product.id);
                    });
                }
            }) || null;
        },
        primaryOnboarding() {
            return this.initializedOnboarding || this.activeDealerOnboardings[0] || null;
        },
        otherOnboardings() {
            return this.activeDealerOnboardings.filter(onboarding => {
                return (onboarding !== this.primaryOnboarding);
            });
        }
    },
    watch: {
        activeOnboardingCardOpen(newValue, oldValue) {
            // If the card is opened and the onboarding
            // hasn't already been loaded initialize it
            if (newValue == true
                && oldValue == false
                && this.lastOnboardingLoaded !== this.primaryOnboarding) {
                this.init();
            }
        }
    },
    methods: {
        async init() {

            // Ensure the dealer is loaded so we don't act on incorrect data
            await this.$store.dispatch('waitForDealerLoaded');

            if (!this.primaryOnboarding) {
                return;
            }

            // If the primary onboarding is already initialized just update its status
            if (this.primaryOnboarding === this.initializedOnboarding) {
                this.lastOnboardingLoaded = this.primaryOnboarding.id;
                await this.$store.dispatch('updateOnboardingStatus');
                return;
            }

            this.loadingPrimaryOnboarding = true;

            await this.$store.dispatch('loadOnboarding', {
                onboardingId: this.primaryOnboarding.id
            });

            this.lastOnboardingLoaded = this.primaryOnboarding.id;

            this.loadingPrimaryOnboarding = false;
        },
        onCancelOnboarding(onboarding) {
            this.onboardingToCancel = onboarding;
            this.cancelOnboardingDialogOpen = true;
        },
        async onCancelOnboardingComplete() {
            this.cancelOnboardingDialogOpen = false;
            await this.$store.dispatch('updateCurrentDealer');
            this.init();
        },
        onContinueOnboarding(onboarding) {
            this.$router.push({
                name: 'dealer-onboarding',
                params: {
                    dealer_id: onboarding.dealer_id,
                    onboarding_id: onboarding.id
                }
            });
        },
        getOnboardingName(onboarding) {
            if (onboarding.products.data.length) {
                return `${onboarding.products.data[0].name.replace(' ', ' - ').replace('Facebook', 'Meta')}`;
            } else {
                return '';
            }
        }
    }
};
</script>

<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div
            v-if="!dealerUsingEventFlow && dealerIsUsingGtm"
            class="pa-5">
            <h2>Copy Google Tag Manager Code</h2>
            <p>
                Please paste the primary script below on every page as high in
                the <strong>&#x3C;head&#x3E;</strong> of the page as possible.
            </p>
            <copy-text-area
                hide-email
                :download-filename="`${dealerTagManagerId}-primary.txt`"
                class="mb-4">
                <pre>{{ gtmMainScript }}</pre>
            </copy-text-area>
            <p>
                Additionally, paste the secondary script below immediately after the opening
                <strong>&#x3C;body&#x3E;</strong> tag on every page.
            </p>
            <copy-text-area
                hide-email
                :download-filename="`${dealerTagManagerId}-secondary.txt`"
                class="mb-4">
                <pre>{{ gtmSecondaryScript }}</pre>
            </copy-text-area>
            <p>
                If you're sending this to another party, feel free to use the link
                below which includes these scripts and instructions for the
                website's developer.
            </p>
            <copy-text-area
                hide-email
                hide-download>
                {{ publicGtmSetupUrl }}
            </copy-text-area>
        </div>
        <div
            v-if="dealerUsingEventFlow"
            class="pa-5">
            <h2>Copy Event Flow Code</h2>
            <p>
                Please paste the script below on every page as high in
                the <strong>&#x3C;head&#x3E;</strong> of the page as possible.
            </p>
            <copy-text-area
                :download-filename="`${dealerUuid}-primary.txt`"
                class="mb-4"
                hide-email>
                <pre>{{ eventFlowScript }}</pre>
            </copy-text-area>
            <p>
                If you're sending this to another party, feel free to use the link
                below which includes these scripts and instructions for the
                website's developer.
            </p>
            <copy-text-area
                hide-email
                hide-download>
                {{ publicEventFlowSetupUrl }}
            </copy-text-area>
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import CopyTextArea from '@/components/globals/CopyTextArea';
import { mapGetters } from 'vuex';
import { getMainScript, getSecondaryScript } from '@/helpers/gtmCode';
import { getEventFlowScript } from '@/helpers/eventFlowCode';

export default {
    components: {
        StyledSlideout,
        CopyTextArea
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        dealer: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters([
            'dealerUsingEventFlow',
            'dealerIsUsingGtm',
            'agencyUsingGA'
        ]),
        dealerTagManagerId() {
            return this.dealer.tag_manager_container_id;
        },
        dealerUuid() {
            return this.dealer.uuid;
        },
        publicGtmSetupUrl() {
            const gtmSetupRoute = this.$router.resolve({
                name: 'gtm-setup',
                params: {
                    container_id: this.dealerTagManagerId
                }
            });

            return window.location.origin + gtmSetupRoute.href;
        },
        gtmMainScript() {
            return getMainScript(this.dealerTagManagerId, this.dealer.agency.name);
        },
        gtmSecondaryScript() {
            return getSecondaryScript(this.dealerTagManagerId, this.dealer.agency.name);
        },
        eventFlowScript() {
            return getEventFlowScript(this.dealer.uuid, this.dealer.agency.name);
        },
        publicEventFlowSetupUrl() {
            const eventFlowSetupRoute = this.$router.resolve({
                name: 'event-flow-setup',
                params: {
                    uuid: this.dealer.uuid
                }
            });

            return window.location.origin + eventFlowSetupRoute.href;
        },
    }
};
</script>

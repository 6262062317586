<template>
    <v-layout row>
        <v-flex xs12>
            <v-text-field
                ref="areaCodeField"
                v-model="areaCode"
                :rules="areaCodeRules"
                label="Desired Call Tracking Area Code"
                class="py-3 my-0 styled-field"
                required />
            <v-layout row>
                <div
                    v-if="loadingCallTracking"
                    class="flex align-center">
                    <loader />
                </div>
                <div
                    v-else
                    class="call-tracking-data-wrapper">
                    <p
                        v-if="callTrackingNumbers.length"
                        class="my-0">
                        Select a tracking number:
                    </p>
                    <v-radio-group
                        v-model="selectedNumber"
                        class="justify-space-around align-center">
                        <v-radio
                            v-for="(number, index) in callTrackingNumbers"
                            :key="index"
                            :label="number | formatPhoneNumber(country)"
                            :value="number"
                            class="radio-bg-white" />
                    </v-radio-group>
                    <p
                        v-if="error"
                        class="text-xs-center red--text">
                        No number could be found for the provided area code. Please search again.
                    </p>
                </div>
            </v-layout>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import { areaCodeRules } from '@/helpers/validationRules';
import ApiRepository from '@/api-repository';
import disableAutofill from '@/mixins/disable-autofill';
import Loader from '@/components/globals/Loader';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default {
    components: {
        Loader
    },
    mixins: [
        disableAutofill,
    ],
    props: {
        type: {
            type: String,
            default: ''
        },

    },
    data() {
        return {
            areaCode: '',
            areaCodeRules: [],
            callTrackingNumbers: [],
            loadingCallTracking: false,
            selectedNumber: '',
            error: false
        };
    },
    computed: {
        ...mapState({
            dealer: (state) => state.dealer.currentDealer
        }),
        country() {
            return this.dealer.country_code;
        }
    },
    watch: {
        areaCode() {
            this.$refs.areaCodeField.validate();
            if(!this.$refs.areaCodeField.valid) {
                this.callTrackingNumbers = [];
                return;
            }

            this.getCallTrackingNumbers();
        },
        selectedNumber(value) {
            if(this.currentNumber !== value) {
                this.$emit('change', value);
            } else {
                this.$emit('change', '');
            }
        },
        country() {
            this.areaCodeRules = areaCodeRules(this.country);
        }
    },
    created() {
        this.$apiRepository = new ApiRepository();
        this.areaCodeRules = areaCodeRules(this.country);

        if (this.dealer.phone) {
            const parsedNumber = parsePhoneNumberFromString(this.dealer.phone, this.country);
            this.areaCode = parsedNumber.nationalNumber.substring(0,3);
        }
        this.selectedNumber = this.currentNumber;

        // Attempt to get call tracking numbers
        // this will only take action if we have a value (area code)
        this.getCallTrackingNumbers();
    },
    methods: {
        onNumberSelected(number) {
            console.log('Number selected:',number);
        },
        async getCallTrackingNumbers() {
            if (!this.areaCode || !this.country) {
                return;
            }

            this.error = false;
            this.loadingCallTracking = true;
            this.callTrackingNumbers = [];

            try {
                const response = await this.$apiRepository.getCallTrackingNumbers(this.areaCode, this.country);
                const numbers = response.data?.data || [];
                this.callTrackingNumbers = numbers.slice(0,5);
            } catch (error) {
                console.error('Error retrieving call tracking numbers',  error);
                this.error = true;
            } finally {
                this.loadingCallTracking = false;
            }
        }
    },
};
</script>

<style lang="scss">
.area-code-field {
    .v-text-field__details {
        display: none;
    }
}
.call-tracking-data-wrapper {
    width:100%;
    max-height: 250px;
    overflow-y: auto;

    .accent--text {
        caret-color: #03a9f4 !important;
        color: #03a9f4 !important;
    }

    .radio-bg-white {
        background: #eef0f1;
        padding:5px 5px 5px 10px;
    }
    .v-radio {
        margin-right: 0;
    }
    .v-input--selection-controls {
        margin-top: 8px;
    }
}
</style>

<template>
    <div>
        <div class="pa-5">
            <h2 class="mb-4">
                <span v-if="activeFeedInventories.length">Switch to</span>
                <span v-else>Add</span>
                Website Inventory
            </h2>
            <website-inventory
                :website="dealerHostname"
                :condensed="true" />
        </div>
        <terms-confirmation
            :loading="loading"
            @approved="onApprove"
            @declined="onDecline" />
    </div>
</template>

<script>
import WebsiteInventory from '@/components/globals/WebsiteInventory';
import TermsConfirmation from '@/components/globals/TermsConfirmation';
import { mapState, mapGetters } from 'vuex';
import { WEBSITE_INVENTORY_API_SOURCE, WEBSITE_INVENTORY_SOURCE_TYPE } from '@/helpers/globals';

export default {
    components: {
        WebsiteInventory,
        TermsConfirmation
    },
    props: {
        activeFeedInventories: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters([
            'dealerHostname',
            'dealerRootDomain'
        ]),
    },
    methods: {
        async onApprove() {
            this.loading = true;
            // delete all feed inventories if there are any
            if (this.activeFeedInventories.length) {
                await Promise.all(this.activeFeedInventories.map( async(feedInventory) => {
                    await this.deleteFeedInventory(feedInventory.rdiId);
                }));
            }
            await this.createRdi();
            this.loading = false;
            this.$emit('approved', this.createdRdi);
        },
        async deleteFeedInventory(rdiId) {
            try {
                await this.$apiRepository.deleteRdi(this.currentDealer.id, rdiId);
            } catch(error) {
                console.log(error);
            }
        },
        onDecline() {
            this.$emit('declined');
        },
        async createRdi() {
            try {
                const data = {
                    feed_provider_id: WEBSITE_INVENTORY_API_SOURCE,
                    rdi_type: WEBSITE_INVENTORY_SOURCE_TYPE,
                    id_string: this.dealerRootDomain,
                    process_inventory: true,
                    upload_exports: true,
                    notification_email: [],
                };
                const response = await this.$http.post(`dealers/${this.currentDealer.id}/remote_dealer_identifiers/create`, data);
                this.createdRdi = response.data.data;
            } catch(error) {
                console.log(error);
            }
        },
    }
};
</script>

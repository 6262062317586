<template>
    <div class="inventory-approval">
        <div class="layout justify-center">
            <v-checkbox
                v-model="websiteInventoryTermsConfirmed"
                :color="colors.green"
                class="styled-checkbox flex mb-5"
                :class="{'onboarding-size': onboardingFlow}"
                dark>
                <template #label>
                    <p class="checkbox-terms">
                        I confirm that the attributes and pricing above are accurate and agree to the
                        <a
                            target="_blank"
                            href="https://buyerbridge.io/our-terms/">
                            <span>BuyerBridge terms &amp; conditions</span>
                        </a>
                        around pricing.
                    </p>
                </template>
            </v-checkbox>
        </div>
        <div class="layout align-center justify-center mb-5">
            <styled-button
                class="decline-button mr-5"
                @click="onDecline">
                Decline
            </styled-button>
            <styled-button
                class="approve-button"
                :loading="loading"
                :loader-size="20"
                :disabled="!websiteInventoryTermsConfirmed || loading"
                @click="onApprove">
                Approve
            </styled-button>
        </div>
        <p>
            You can choose not to use the website inventory and wait for the dealer's
            feed provider to provide inventory. Note that we cannot adjust the data
            above so if you are unhappy with using it, even temporarily, please decline
            its usage
        </p>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import { UPDATE_ONBOARDING_DATA } from '@/store/mutation-types';
import { mapState } from 'vuex';
import colors from '@/helpers/colors';

export default {
    components: {
        StyledButton,
    },
    props: {
        onboardingFlow: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            colors,
            websiteInventoryTermsConfirmedLocal: false,
        };
    },
    computed: {
        ...mapState({
            onboardingData: (state) => state.onboarding.data
        }),
        websiteInventoryTermsConfirmed: {
            get() {
                if (this.onboardingFlow) {
                    return this.onboardingData.websiteInventoryTermsConfirmed || false;
                } else {
                    return this.websiteInventoryTermsConfirmedLocal;
                }
            },
            set(isConfirmed) {
                if (this.onboardingFlow) {
                    this.$store.commit(UPDATE_ONBOARDING_DATA, { websiteInventoryTermsConfirmed: isConfirmed });
                } else {
                    this.websiteInventoryTermsConfirmedLocal = isConfirmed;
                }
            }
        },
        websiteInventoryTermsStatus: {
            get() {
                return this.onboardingData.websiteInventoryTermsStatus || null;
            },
            set(status) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, { websiteInventoryTermsStatus: status });
            }
        },
    },
    methods: {
        async onApprove() {
            if (this.onboardingFlow) { this.websiteInventoryTermsStatus = 'approved' }
            this.$emit('approved');
        },
        onDecline() {
            if (this.onboardingFlow) { this.websiteInventoryTermsStatus = 'declined' }
            this.$emit('declined');
        },
    }
};
</script>

<style lang="scss" scoped>
.inventory-approval {
    text-align: center;
    background-color: $blue-bg;
    color: $white;
    padding: 40px;
}
.decline-button {
    background-color: $carnation;
    &:hover {
        background-color: darken($carnation, 10%);
    }
}
.approve-button {
    background-color: $whild-willow;
    &:hover {
        background-color: darken($whild-willow, 10%);
    }
    &:disabled {
        &:hover {
            background-color: $whild-willow;
        }
        background-color: $whild-willow;
        opacity: 0.6;
    }
}
.checkbox-terms {
    color: $white;
    a {
        color: $white !important;
    }
}
.decline-button, .approve-button {
    padding: 0 15px;
    height: 40px;
    border: 2px solid $white;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    &:disabled {
        border: 2px solid $white;
    }
}
.onboarding-size {
    max-width: 450px;
}
</style>

<template>
    <div class="add-user-form">
        <div ref="scrollTarget" />
        <div
            v-if="complete"
            class="mb-3 flex layout column custom-alert-container">
            <p class="alert-title">
                You have successfully added a new user!
            </p>
            <template v-if="previousPasswordSetupType == 'send'">
                <p class="alert-content">
                    We have sent account setup instructions to:
                </p>
                <p class="destination">
                    {{ previousUserEmail }}
                </p>
            </template>
            <template v-else>
                <p class="alert-content">
                    Please send them the password you created with login
                    instructions.
                </p>
            </template>
        </div>

        <v-alert
            :value="errors.length"
            type="error"
            class="mb-5">
            <template v-if="errors.length == 1">
                {{ errors[0] }}
            </template>
            <template v-else>
                <p>The following errors occured:</p>
                <ul>
                    <li
                        v-for="error in errors"
                        :key="error">
                        {{ error }}
                    </li>
                </ul>
            </template>
        </v-alert>

        <v-form
            v-if="showForm"
            ref="form"
            v-model="valid"
            @submit.prevent="submit()">
            <v-text-field
                v-model="userName"
                name="name"
                label="User Name"
                :rules="requiredRules"
                class="mb-3 styled-field" />

            <v-text-field
                v-model="email"
                name="email"
                label="Email"
                :rules="emailRules"
                class="mb-3 styled-field" />

            <v-autocomplete
                v-model="selectedRoleId"
                :items="roles"
                item-text="name"
                item-value="id"
                label="User Role"
                :rules="requiredRules"
                class="mb-3 styled-field" />

            <multiple-dealer-field
                v-if="dealerId ? false : selectedRoleId === 4"
                v-model="selectedDealers"
                :agency-id="currentAgency.id"
                class="mb-3"
                required />

            <v-radio-group
                v-model="passwordSetupType"
                :rules="requiredRules"
                class="styled-radio mb-4"
                row>
                <v-radio
                    label="Send login &amp; password instructions"
                    value="send"
                    color="primary"
                    class="mb-0" />
                <v-radio
                    label="Manually set a password"
                    value="manual"
                    color="primary" />
            </v-radio-group>

            <template v-if="passwordSetupType == 'manual'">
                <v-text-field
                    ref="passwordField"
                    v-model="password"
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="passwordRules"
                    :append-icon="
                        showPassword ? 'visibility' : 'visibility_off'
                    "
                    class="styled-field mb-3"
                    @click:append="showPassword = !showPassword" />
                <v-text-field
                    ref="confirmPasswordField"
                    v-model="confirmPassword"
                    label="Confirm Password"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="passwordRules"
                    :append-icon="
                        showPassword ? 'visibility' : 'visibility_off'
                    "
                    :error-messages="passwordErrors"
                    class="styled-field"
                    @click:append="showPassword = !showPassword" />
            </template>

            <div class="text-xs-center mt-5">
                <styled-button
                    :disabled="!valid"
                    :loading="loadingSubmission">
                    Add user
                </styled-button>
            </div>
        </v-form>
    </div>
</template>

<script>
import MultipleDealerField from './fields/MultipleDealerField';
import StyledButton from './StyledButton';
import {
    requiredRules,
    passwordRules,
    emailRules,
} from '../../helpers/validationRules';
import { debounce } from 'lodash';
import { mapState } from 'vuex';

export default {
    components: {
        StyledButton,
        MultipleDealerField,
    },
    props: {
        dealerId: {
            type: Number,
            default: null,
        },
        //To handle external hide/show behavior
        showForm: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data() {
        return {
            requiredRules,
            emailRules: [...emailRules, ...requiredRules],
            passwordRules: [...passwordRules, ...requiredRules],
            passwordSetupType: null,
            password: '',
            showPassword: false,
            confirmPassword: '',
            previousUserEmail: '',
            previousPasswordSetupType: null,
            complete: false,
            errors: [],
            dealers: [],
            valid: false,
            selectedDealers: [],
            loadingDealers: false,
            loadingAgencies: true,
            userName: '',
            email: '',
            selectedRoleId: null,
            loadingSubmission: false,
            roles: [
                {
                    id: 4,
                    name: 'Dealer',
                },
                {
                    id: 11,
                    name: 'Agency',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency,
        }),
        passwordErrors() {
            if (
                this.confirmPassword.length &&
                this.$refs.passwordField.valid &&
                this.$refs.confirmPasswordField.valid &&
                this.password !== this.confirmPassword
            ) {
                return ['The provided passwords don\'t match'];
            }

            return [];
        },
        selectedDealerValues() {
            return this.selectedDealers
                .filter((dealer) => typeof dealer === 'object')
                .map((dealer) => {
                    return {
                        related_object_key: dealer.id,
                    };
                });
        },
    },
    methods: {
        search: debounce(async function(e) {
            const { value } = e.target;

            this.loadingDealers = true;

            const response = await this.$apiRepository.getAgencyDealers(
                this.currentAgency.id,
                value
            );

            this.dealers = response.data.data;
            this.loadingDealers = false;
        }, 300),
        async submit() {
            try {
                this.complete = false;
                this.errors = [];
                this.loadingSubmission = true;

                await this.createUser();
                await this.attachRole();

                if (this.selectedDealers.length || this.dealerId) {
                    await this.attachDealer();
                }

                if (this.passwordSetupType === 'send') {
                    await this.sendPasswordReset();
                }

                this.previousPasswordSetupType = this.passwordSetupType;
                this.previousUserEmail = this.email;
                this.complete = true;
                this.reset();
                this.$emit('onAddUser', true);
            } catch (error) {
                console.error(error);
            } finally {
                this.scrollToMessages();
                this.loadingSubmission = false;
            }
        },
        reset() {
            this.userName = '';
            this.email = '';
            this.password = '';
            this.confirmPassword = '';
            this.selectedRoleId = null;
            this.selectedDealers = [];
            this.passwordSetupType = null;
            this.$refs.form.resetValidation();
        },
        async createUser() {
            try {
                const config = {
                    agency_id: this.currentAgency.id,
                    name: this.userName,
                    email: this.email,
                    password_setup_type: this.passwordSetupType,
                };

                if (this.password.length) {
                    config.password = this.password;
                }

              if (this.confirmPassword.length) {
                config.password_confirmation = this.confirmPassword;
              }

                const response = await this.$http.post('/users', config);

                this.userId = response.data.data.id;
            } catch (error) {
                const errorMessage = error.response.data.error.messages[0];
                if (errorMessage) {
                    this.errors.push(errorMessage);
                    return;
                }

                this.errors.push('Error creating user!');
            }
        },
        async attachRole() {
            try {
                const config = {
                    related: [
                        {
                            related_object_key: this.selectedRoleId,
                        },
                    ],
                };
                await this.$http.post(
                    `users/${this.userId}/roles/attach`,
                    config
                );
            } catch (error) {
                this.errors.push('Error attaching user role');
                throw error;
            }
        },
        async attachDealer() {
            try {
                let config = {};
                if (this.dealerId) {
                    config.related = [
                        {
                            related_object_key: this.dealerId,
                        },
                    ];
                } else {
                    config.related = this.selectedDealerValues;
                }

                await this.$http.post(
                    `users/${this.userId}/dealers/attach`,
                    config
                );
            } catch (error) {
                this.errors.push('Error attaching dealer');
                throw error;
            }
        },
        async sendPasswordReset() {
            try {
                await this.$apiRepository.sendPasswordReset(this.email);
            } catch (error) {
                this.errors.push('Error sending password reset email');
                throw error;
            }
        },
        scrollToMessages() {
            this.$scrollTo(this.$refs.scrollTarget, 500, {
                offset: -150,
            });
        },
    },
    watch: {
        async selectedRoleId(roleId) {
            if (roleId === 4 && !this.dealers.length) {
                this.loadingDealers = true;

                const response = await this.$apiRepository.getAgencyDealers(
                    this.currentAgency.id
                );

                this.dealers = response.data.data;
                this.loadingDealers = false;
            }
        },
        showForm(value) {
            if (value) {
                this.complete = false;
            }
        },
    },
};
</script>

<style lang="scss">
.add-user-form {
    .styled-field.v-input.v-text-field.v-input.v-text-field:not(.v-select--chips) {
        input {
            padding-top: 12px;
            padding-bottom: 12px;
            max-height: 42px;
        }
        label {
            top: 10px;
            &.v-label--active {
                transform: translateY(-18px) scale(0.7);
            }
        }
    }
}
.custom-alert-container {
    justify-content: center;
    align-items: center;
    height: 167px;
    background-color: #ebebeb;
    .alert-title {
        font-size: 20px;
        color: #3f4649;
    }
    .alert-content {
        color: #3f4649;
        font-size: 15px;
    }
    .destination {
        color: $blue-bg;
        font-size: 12px;
    }
}
</style>

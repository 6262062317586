<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2>Install Request Emails</h2>
            <p class="mb-4">
                Use the example messaging below to obtain approval from {{ dealer.name }}
                and have {{ siteProvider.name }} install the container using the approval.
            </p>
            <site-provider-install-content
                :dealer="dealer"
                :site-provider="siteProvider" />
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import SiteProviderInstallContent from '@/components/globals/SiteProviderInstallContent';

export default {
    name: 'ResendSiteProvider',
    components: {
        StyledSlideout,
        SiteProviderInstallContent
    },
    props: {
        dealer: {
            type: Object,
            required: true
        },
        siteProvider: {
            type: Object,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value(newValue, oldValue) {
            // If we're transitioning to open and the form was previously
            // submitted reset it
            if (newValue === true
                && oldValue === false
                && this.complete === true) {
                this.complete = false;
            }
        }
    }
};
</script>
<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2>Banner Provider Request</h2>
            <p>
                Please fill out the form below to request support for a new banner provider and allow up to 72 hours for us to respond to the request.
            </p>
            <p><i>Note that it can take up to 4 weeks to accommodate a new provider request</i></p>
        </div>
        <div class="pl-5 pr-5 pb-5">
            <hub-spot-form
                form-id="e7839732-5933-469b-ad8a-4db19a639ec1"
                :field-values="{
                    'TICKET.agency_name': currentDealer.agency.name,
                    'TICKET.form___dealer_name': currentDealer.name,
                    'TICKET.initial_ticket_owner_contact_name': user.name,
                    'email': user.email,
                    'TICKET.subject': `Privacy Banner Request - ${currentDealer.agency.name} - ${currentDealer.name} - Support`,
                }" />
        </div>
    </styled-slideout>
</template>

<script>
import { mapState } from 'vuex';
import HubSpotForm from '@/components/globals/HubSpotForm';
import StyledSlideout from '@/components/globals/StyledSlideout';

export default {
    components: {
        HubSpotForm,
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            currentDealer: (state) => state.dealer.currentDealer
        })
    }
};
</script>

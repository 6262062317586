<template>
    <div>
        <v-alert
            :value="error"
            class="mb-4">
            {{ error }}
        </v-alert>

        <div
            v-if="initalizationLoading"
            class="layout justify-center py-5">
            <loader
                :label="(setupOnCreate) ? 'Initializing Chatbot...' : 'Loading chatbot configuration...'" />
        </div>

        <styled-callout
            v-else-if="!hasPageAccess"
            class="layout align-center mb-3">
            <div class="flex shrink mr-3">
                <status-icon
                    value="warning"
                    size="25" />
            </div>
            <div class="flex">
                <p>
                    {{ currentDealer.name }} needs to accept page access to
                    {{ currentDealer.agency.name }} before the chatbot can be configured
                </p>
            </div>
        </styled-callout>

        <template v-else>
            <slot name="header" />

            <div v-if="!hideToggle">
                <v-switch
                    v-model="chatbotEnabled"
                    :loading="loadingToggle"
                    :label="(chatbotEnabled) ? 'Chatbot enabled' : 'Chatbot disabled'"
                    color="primary"
                    @change="handleToggle" />
            </div>

            <template v-if="hideToggle ? true : chatbotEnabled">
                <div class="chatbot-section">
                    <div class="chatbot-section-heading">
                        <h4>Introduction</h4>
                    </div>
                    <div class="chatbot-section-body">
                        <div class="dialog dialog-right">
                            <chat-bubble align="right">
                                <div v-html="userMessages.initial_inquiry.replace(/\n/g, '<br />')" />
                            </chat-bubble>
                        </div>

                        <div
                            v-if="chatbotMessages.new_inquiry != null"
                            class="dialog dialog-editable">
                            <chat-bubble>
                                <token-editor
                                    v-model="chatbotMessages.new_inquiry"
                                    :tokens="tokens" />
                            </chat-bubble>
                        </div>
                    </div>
                </div>
                <div class="chatbot-section">
                    <div class="chatbot-section-heading">
                        <v-checkbox
                            v-model="activateEmailSection"
                            color="primary"
                            class="styled-checkbox">
                            <template #label>
                                <h4>Email Collection</h4>
                            </template>
                        </v-checkbox>
                    </div>
                    <div
                        v-if="activateEmailSection"
                        class="chatbot-section-body">
                        <div
                            v-if="chatbotMessages.ask_for_email != null"
                            class="dialog dialog-editable">
                            <chat-bubble>
                                <token-editor
                                    v-model="chatbotMessages.ask_for_email"
                                    :tokens="tokens" />
                            </chat-bubble>
                        </div>

                        <div class="dialog dialog-right">
                            <chat-bubble align="right">
                                {{ userMessages.unknown_response }}
                            </chat-bubble>
                        </div>

                        <div
                            v-if="chatbotMessages.cant_find_email != null"
                            class="dialog dialog-editable">
                            <chat-bubble>
                                <token-editor
                                    v-model="chatbotMessages.cant_find_email"
                                    :tokens="tokens" />
                            </chat-bubble>
                        </div>

                        <div class="dialog dialog-right">
                            <chat-bubble align="right">
                                {{ userMessages.email_response }}
                            </chat-bubble>
                        </div>

                        <div
                            v-if="chatbotMessages.received_email != null"
                            class="dialog dialog-editable">
                            <chat-bubble>
                                <token-editor
                                    v-model="chatbotMessages.received_email"
                                    :tokens="tokens" />
                            </chat-bubble>
                        </div>
                    </div>
                </div>
                <div class="chatbot-section">
                    <div class="chatbot-section-heading">
                        <v-checkbox
                            v-model="activatePhoneSection"
                            color="primary"
                            class="styled-checkbox">
                            <template #label>
                                <h4>Phone Collection</h4>
                            </template>
                        </v-checkbox>
                    </div>
                    <div
                        v-if="activatePhoneSection"
                        class="chatbot-section-body">
                        <div
                            v-if="chatbotMessages.ask_for_phone_number != null"
                            class="dialog dialog-editable">
                            <chat-bubble>
                                <token-editor
                                    v-model="chatbotMessages.ask_for_phone_number"
                                    :tokens="tokens" />
                            </chat-bubble>
                        </div>

                        <div class="dialog dialog-right">
                            <chat-bubble align="right">
                                {{ userMessages.unknown_response }}
                            </chat-bubble>
                        </div>

                        <div
                            v-if="chatbotMessages.cant_find_phone_number != null"
                            class="dialog dialog-editable">
                            <chat-bubble>
                                <token-editor
                                    v-model="chatbotMessages.cant_find_phone_number"
                                    :tokens="tokens" />
                            </chat-bubble>
                        </div>

                        <div class="dialog dialog-right">
                            <chat-bubble align="right">
                                {{ userMessages.phone_response }}
                            </chat-bubble>
                        </div>

                        <div
                            v-if="chatbotMessages.received_phone_number != null"
                            class="dialog dialog-editable">
                            <chat-bubble>
                                <token-editor
                                    v-model="chatbotMessages.received_phone_number"
                                    :tokens="tokens" />
                            </chat-bubble>
                        </div>
                    </div>
                </div>
                <div class="chatbot-section">
                    <div class="chatbot-section-heading">
                        <h4>Closing</h4>
                    </div>
                    <div class="chatbot-section-body">
                        <div
                            v-if="chatbotMessages.thank_you != null"
                            class="dialog dialog-editable">
                            <chat-bubble>
                                <token-editor
                                    v-model="chatbotMessages.thank_you"
                                    :tokens="tokens" />
                            </chat-bubble>
                        </div>

                        <div class="dialog dialog-right">
                            <chat-bubble align="right">
                                {{ userMessages.unknown_follow_up }}
                            </chat-bubble>
                        </div>

                        <div
                            v-if="chatbotMessages.sorry != null"
                            class="dialog dialog-editable">
                            <chat-bubble>
                                <token-editor
                                    v-model="chatbotMessages.sorry"
                                    :tokens="tokens" />
                            </chat-bubble>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!hideSave"
                    class="mt-5 text-xs-center">
                    <styled-button
                        :loading="loadingSave"
                        outline
                        @click="save">
                        SAVE SETTINGS
                    </styled-button>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import StyledCallout from '@/components/globals/StyledCallout';
import StatusIcon from '@/components/globals/StatusIcon';
import Loader from '@/components/globals/Loader';
import StyledButton from '@/components/globals/StyledButton';
import TokenEditor from '@/components/globals/fields/TokenEditorField';
import ChatBubble from '@/components/globals/ChatBubble';
import sleep from '@/helpers/sleep';
import { tokens, userMessages, chatbotMessages } from './config';
import { mapState } from 'vuex';

export default {
    components: {
        StyledCallout,
        StatusIcon,
        Loader,
        StyledButton,
        TokenEditor,
        ChatBubble
    },
    props: {
        hideToggle: {
            type: Boolean,
            default: false
        },
        hideSave: {
            type: Boolean,
            default: false
        },
        setupOnCreate: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            error: '',
            hasPageAccess: false,
            initalizationLoading: false,
            loadingToggle: false,
            chatbotEnabled: false,
            loadingSave: false,
            tokens,
            chatbotMessages: {},
            userMessages,
            activateEmailSection: true,
            activatePhoneSection: true
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        })
    },
    watch: {
        activateEmailSection(isActive) {
            if (isActive)  {
                this.chatbotMessages.ask_for_email = chatbotMessages.ask_for_email;
                this.chatbotMessages.received_email = chatbotMessages.received_email;
                this.chatbotMessages.cant_find_email = chatbotMessages.cant_find_email;
            } else {
                this.chatbotMessages.ask_for_email = null;
                this.chatbotMessages.received_email = null;
                this.chatbotMessages.cant_find_email = null;
            }
        },
        activatePhoneSection(isActive) {
            if (isActive)  {
                this.chatbotMessages.ask_for_phone_number = chatbotMessages.ask_for_phone_number;
                this.chatbotMessages.cant_find_phone_number = chatbotMessages.cant_find_phone_number;
                this.chatbotMessages.phone_opt_out = chatbotMessages.phone_opt_out;
                this.chatbotMessages.received_phone_number = chatbotMessages.received_phone_number;
            } else {
                this.chatbotMessages.ask_for_phone_number = null;
                this.chatbotMessages.cant_find_phone_number = null;
                this.chatbotMessages.phone_opt_out = null;
                this.chatbotMessages.received_phone_number = null;
            }
        }
    },
    async created() {
        try {

            this.initalizationLoading = true;

            await this.verifyPageAccess();

            // If we don't have page access stop the sequence
            if (!this.hasPageAccess) {
                this.initalizationLoading = false;
                return;
            }

            // Setup the chatbot if requested
            if (this.setupOnCreate) {
                await this.runInitialSetup();
            }
            // Otherwise if the toggle is displayed check the status to show
            // the correct toggle position
            else if (!this.hideToggle) {
                await this.checkStatus();
            }

            // If the chatbot has already been configured with copy combine it with the defaults
            if (this.currentDealer.configuration?.chatbot?.copy) {

                const { copy } = this.currentDealer.configuration.chatbot;

                // If the ask for email value is null close the whole section
                if (copy.ask_for_email === null) {
                    this.activateEmailSection = false;
                }

                // If the ask for phone value is null close the whole section
                if (copy.ask_for_phone_number === null) {
                    this.activatePhoneSection = false;
                }

                this.chatbotMessages = {
                    ...chatbotMessages,
                    ...copy
                };
            } else {
                this.chatbotMessages = chatbotMessages;
            }

        } catch (error) {
            // Do nothing this is just to ensure the loader gets removed in case there's an issue
        } finally {
            this.initalizationLoading = false;
        }
    },
    methods: {
        async verifyPageAccess() {
            try {
                const response = await this.$http.post(`dealers/${this.currentDealer.id}/validate/facebook_page/status`);

                if (response.data?.status == 'complete') {
                    this.hasPageAccess = true;
                }
            } catch (error) {
                console.log('Error checking chatbot status', error.response || error);
            }
        },
        async runInitialSetup() {
            try {
                // Since this happens quickly delay a bit to avoid a flashing message
                // the sleep will ensure that we're never loading for less than 2 seconds
                // but no longer if the sequence has already completed
                await Promise.all([
                    this.$http.post(`dealers/${this.currentDealer.id}/enable-chatbot`),
                    sleep(2000)
                ]);

                // Report to the parent
                this.$emit('setup-complete');

            } catch (error) {
                console.error('Error enabling chatbot', error.response || error);
                this.error = 'Error enabling chatbot.  Please contact support for assitance.';
            }
        },
        async checkStatus() {
            try {
                const response = await this.$http.post(`dealers/${this.currentDealer.id}/validate/chatbot/status`);

                if (response.data?.status == 'complete') {
                    this.chatbotEnabled = true;
                }

            } catch (error) {
                console.log('Error checking chatbot status', error.response || error);
                this.error = 'Error checking chatbot status.  Please contact support for assitance.';
            } finally {
                this.loadingToggle = false;
            }
        },
        async toggleChatbot(state) {
            try {
                this.loadingToggle = true;
                if (state == true) {
                    await this.$http.post(`dealers/${this.currentDealer.id}/enable-chatbot`);
                } else {
                    await this.$http.post(`dealers/${this.currentDealer.id}/disable-chatbot`);
                }
                this.chatbotEnabled = state;
            } catch (error) {
                console.log('Error toggling chatbot state', error.response || error);
                this.error = 'Error changing chatbot state.  Please contact support for assitance.';
            } finally {
                this.loadingToggle = false;
            }
        },
        async save() {
            try {
                this.loadingSave = true;

                const config = {
                    chatbot: {
                        copy: this.chatbotMessages
                    }
                };

                await this.$apiRepository.updateDealerConfiguration(this.currentDealer.id, config);

                await this.$store.dispatch('updateCurrentDealer');
            } catch (error) {
                console.error('Error updating chatbot copy', error.response || error);
                this.error = 'Error updating chatbot copy.  Please try again or contact support for assitance.';
            } finally {
                this.loadingSave = false;
            }
        },
        handleToggle(value) {
            this.toggleChatbot(value);
        }
    }
};
</script>

<style lang="scss">
.dialog-editable {
    .chat-bubble {
        transition: background-color 0.2s ease-in-out;
        &::after {
            transition: background-color 0.2s ease-in-out;
        }
        &:hover {
            background-color: darken($alabaster-dark, 2%);
            &::after {
                border-color: darken($alabaster-dark, 2%) transparent;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.chatbot-section {
    margin-bottom: 40px;
}

.chatbot-section-heading {
    margin-bottom: 10px;
    .styled-checkbox {
        margin-left: -3px;
    }
    h4 {
        color: $gray;
        font-weight: 500;
        font-size: 1.3rem;
    }
}

.chat-bubble {
    p {
        margin-bottom: 0.5rem;
        line-height: 1.3rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.chatbot-section-body {
    padding: 20px 20px 10px;
    border-left: 4px solid $alabaster-dark;
}

.dialog {
    display: flex;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    & > div {
        width: calc(100% - 100px);
    }
}

.dialog-right {
    justify-content: flex-end;
    user-select: none;
}

</style>
<template>
    <div class="contact-panel">
        <p>{{ feedProvider.name }} Contact Information</p>
        <div class="layout wrap">
            <data-field
                v-if="feedProvider.support_phone"
                class="mr-3"
                no-indent>
                <template #label>
                    Phone
                </template>
                <a
                    :href="`tel:${feedProvider.support_phone}`"
                    target="_blank">
                    {{ feedProvider.support_phone }}
                </a>
            </data-field>
            <data-field
                v-if="feedProvider.support_email"
                no-indent>
                <template #label>
                    Email
                </template>
                <a
                    :href="`mailto:${feedProvider.support_email}`"
                    target="_blank">
                    {{ feedProvider.support_email }}
                </a>
            </data-field>
        </div>
    </div>
</template>

<script>
import DataField from '@/components/globals/DataField';

export default {
    components: {
        DataField
    },
    props: {
        feedProvider: {
            type: Object,
            required: true
        },
    }
};
</script>

<style lang="scss" scoped>
.contact-panel {
    padding: 30px;
    background-color: rgba($gray, 0.05);
}
</style>

<template>
    <div
        :class="{
            'styled-table-container': true,
            'hide-margins': hideMargins
        }">
        <div
            v-if="!hideHeader"
            class="table-header">
            <div class="table-field">
                <v-text-field
                    v-model="search"
                    prepend-inner-icon="search"
                    type="string"
                    :label="searchText"
                    class="styled-field styled-field-small has-icon"
                    flat
                    hide-details
                    small
                    multiple
                    clearable
                    dense />
            </div>
            <div class="table-field">
                <v-select
                    :items="headers"
                    :value="internalPagination.sortBy"
                    item-text="text"
                    item-value="value"
                    label="Sort by"
                    class="styled-field styled-field-small"
                    flat
                    hide-details
                    small
                    clearable
                    dense
                    @input="changeSort($event)" />
            </div>
            <slot name="filters" />
        </div>
        <v-data-table
            ref="table"
            :headers="headers"
            :loading="loading"
            :items="filteredItems"
            :item-key="itemKey"

            :pagination="internalPagination"
            :rows-per-page-items="rowsPerPageItems"
            :hide-actions="hideActions"
            v-bind="appendedTableProps"
            class="styled-table"
            @update:pagination="onUpdatePagination">
            <template slot="no-data">
                <div class="no-results">
                    <h4 v-if="!items.length">
                        No results found
                    </h4>
                </div>
            </template>

            <template slot="progress">
                <loader />
            </template>

            <template #headers="{ headers }">
                <tr>
                    <th
                        v-for="(header, index) in headers"
                        :key="`${header.text}-${index}`"
                        :class="[
                            header.sortable ? 'column sortable' : '',
                            internalPagination.descending ? 'desc' : 'asc',
                            header.value === internalPagination.sortBy ? 'active' : '',
                            !!header.value ? `table-header-${header.value}` : ''
                        ]"
                        :style="{
                            textAlign: header.align,
                            width: header.width
                        }"
                        @click="(!!header.value && header.sortable) ? changeSort(header.value) : null">
                        <span v-if="header.text">
                            <v-icon
                                v-if="header.sortable"
                                :key="`${header.text}-icon`"
                                small
                                :class="header.align === 'right' ? 'arrow-right': ''">
                                arrow_upward
                            </v-icon>
                            <styled-tooltip
                                v-if="header.tooltip"
                                :max-width="300"
                                position="top">
                                {{ header.text }}
                                <template #content>
                                    <span v-html="header.tooltip" />
                                </template>
                            </styled-tooltip>
                            <template v-else>
                                <div :class="{'bottom-border': header.text_bottom }">
                                    {{ header.text }}
                                </div>
                            </template>
                        </span>
                    </th>
                </tr>
                <tr v-if="hasFilters">
                    <th
                        v-for="header in headers"
                        :key="header.text"
                        :style="{
                            width: header.width
                        }"
                        align="right">
                        <div v-if="header.filter">
                            <v-select
                                v-if="header.filter === 'multiple'"
                                v-model="filters[header.value]"
                                :items="columnValueList(header)"
                                :class="`styled-field styled-field-small table-filter-${header.value}`"
                                flat
                                hide-details
                                small
                                multiple
                                clearable
                                outlined
                                dense>
                                <template #selection="{ index }">
                                    <span
                                        v-if="index === 0"
                                        class="filterd-field">
                                        <icon
                                            name="funnel"
                                            width="15"
                                            height="15" />
                                    </span>
                                </template>
                            </v-select>
                            <v-text-field
                                v-else-if="header.filter === 'search'"
                                v-model="filters[header.value]"
                                type="string"
                                label=""
                                class="styled-field styled-field-small" />
                        </div>
                        <div v-if="header.text_bottom">
                            {{ header.text_bottom }}
                        </div>
                    </th>
                </tr>
            </template>

            <template #items="props">
                <!-- <tr @click="props.expanded = !props.expanded">
                    <td>Test</td>
                </tr> -->
                <slot
                    name="items"
                    v-bind="props" />
            </template>

            <template
                v-if="hasTotals"
                #footer>
                <tr class="footer">
                    <td
                        v-if="footerTitlesColspan"
                        :colspan="footerTitlesColspan"
                        class="footer-title">
                        <div>Total:</div>
                        <div>Average:</div>
                        <div>Calculated:</div>
                    </td>
                    <td
                        v-for="(total, index) in totals.slice(footerTitlesColspan)"
                        :key="index + footerTitlesColspan"
                        :style="{ textAlign: total.header.cellAlign || 'left' }"
                        class="column-total">
                        <copy-text
                            :value="total.value"
                            show-text-only>
                            {{ total.formatted }}
                        </copy-text>
                        <div v-if="total.average_value">
                            <copy-text
                                :value="total.average_value"
                                show-text-only>
                                {{ total.average_formatted }}
                            </copy-text>
                        </div>
                        <div v-if="total.calculated">
                            <copy-text
                                :value="total.calculated"
                                show-text-only>
                                <channel-advertising-calculated-tooltip
                                    :value="total.calculated"
                                    :details="total" />
                            </copy-text>
                        </div>
                        <div v-else>
                            -
                        </div>
                    </td>
                </tr>
            </template>
            <template
                v-else-if="!!$slots['footer']"
                #footer>
                <slot name="footer" />
            </template>
            <template
                v-if="!!$slots['expand']"
                #expand>
                <slot name="expand" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import CopyText from './CopyText';
import StyledTooltip from './StyledTooltip';
import Loader from './Loader';
import Icon from './Icon';
import { get } from 'lodash';
import numeral from 'numeral';
import getNumberFormats from '../../helpers/numberFormats';
import ChannelAdvertisingCalculatedTooltip from '@/components/globals/ChannelAdvertisingCalculatedTooltip.vue';

const totalsNumberFormats = getNumberFormats(true);

export default {
    components: {
        ChannelAdvertisingCalculatedTooltip,
        CopyText,
        StyledTooltip,
        Icon,
        Loader
    },
    props: {
        headers: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        pagination: {
            type: Object,
            default: null
        },
        rowsPerPage: {
            type: Number,
            default: null
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        hideActions: {
            type: Boolean,
            default: false
        },
        itemKey: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        expand: {
            type: Boolean,
            default: false
        },
        hasFilters: {
            type: Boolean,
            default: false
        },
        minWidth: {
            type: String,
            default: '100%'
        },
        sortBy: {
            type: String,
            default: null
        },
        descending: {
            type: Boolean,
            default: true
        },
        externalControl: {
            type: Boolean,
            default: false
        },
        hideMargins: {
            type: Boolean,
            default: false
        },
        hideAllRowsOption: {
            type: Boolean,
            default: false
        },
        searchText: {
            type: String,
            default: 'Search'
        },
        fixedTotals: {
            type: Boolean,
            default: false
        },
        footerTitlesColspan: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            search: '',
            initialRows: null,
            filters: {},
            hasTotals: false,
            internalPagination: {
                rowsPerPage: 25
            }
        };
    },
    computed: {
        appendedTableProps() {
            // If we're externally controlling pagination the total-items
            // prop will stop the table from manipulating itself for
            // sorting, etc so we've made this a little more intuitive with a prop
            if (this.externalControl) {
                return {
                    totalItems: this.internalPagination.totalItems
                };
            }
            return {};
        },
        totals() {
            if (!this.hasTotals) {
                return [];
            }

            // Setup an array of totals with null values for each column
            const totals = new Array(this.headers.length).map(() => null);
            const counts = new Array(this.headers.length).fill(0);

            const itemsForCountingTotals = this.fixedTotals ? this.filteredItems : this.$refs.table.filteredItems;
            itemsForCountingTotals.forEach(item => {
                this.headers.forEach((header, index) => {

                    // Only compute if a total property was provided
                    if (!header.total) {
                        return;
                    }
                    const value = get(item, header.value) || 0;
                    if (!totals[index]) {
                        totals[index] = parseFloat(value);
                    } else {
                        totals[index] += parseFloat(value);
                    }
                    // Keep track of the number of records (for averages)
                    if (value !== null) {
                        counts[index]++;
                    }
                });
            });

            // Build up the totals object since we allow the user to use this
            // or order to created total fields that compute totals from the
            // results generated above!
            const totalsObj = {};
            this.headers.forEach((header, index) => {
                // Only work on this header if there's a corresponding total and ID
                if (!totals[index] || !header.id) {
                    return;
                }

                // Add the total to the totals object
                totalsObj[header.id] = totals[index];
                totalsObj[header.id + '_format'] = header.format;
            });

            // Now loop through all the headers one last time and call up
            // any functions assigned to the total property
            this.headers.forEach((header, index) => {
                // If the header is defined as a function call it!
                if (typeof header.total === 'function') {
                    try {
                        totals[index] = header.total.call(null, totalsObj);
                    } catch (error) {
                        console.error(error.message || error);
                    }
                }

                // Handle averages
                if (header.average) {
                    const itemsWithValidData = itemsForCountingTotals.filter(item => {
                        const validValue = get(item, header.value);
                        if (validValue) {
                            return item;
                        }
                    });
                    const totalValue = totals[index];
                    totals[index] = {
                        value: totalValue,
                        average_value: totalValue / itemsWithValidData.length
                    };
                }

                // Handle calculated
                if (header.calculated) {
                    totals[index].calculated = header.calculated && typeof header.calculated === 'function' ? header.calculated.call(null, totalsObj) : '';
                    totals[index].totalObject = totalsObj;
                }
            });

            // One last loop to format everything and we're done!
            this.headers.forEach((header, index) => {
                let value = header.average ? totals[index].value ?? '' : totals[index] ?? '';
                let average_value = header.average ? totals[index].average_value ?? '' : '';
                let calculated = header.calculated ? totals[index].calculated ?? '' : '';
                let totalObject = header.calculated ? totals[index].totalObject ?? '' : '';
                if (header.format == 'currency') {
                    value = value.toFixed(2);
                }
                if (average_value && header.format == 'currency') {
                    average_value = average_value.toFixed(2);
                } else if (average_value) {
                    average_value = average_value.toFixed();
                }
                totals[index] = {
                    value: value.toString(),
                    average_value: average_value.toString(),
                    formatted: header.format ? numeral(value).format(totalsNumberFormats[header.format]) : value ? value : '',
                    average_formatted: header.format ? numeral(average_value).format(totalsNumberFormats[header.format]) : average_value ? average_value : '',
                    header
                };

                if (header.calculated) {
                    if (header.format === 'percent') {
                        totals[index].formatted = '-';
                        totals[index].average_formatted = '-';
                    }
                    totals[index].calculated = header.format && typeof header.calculated !== 'undefined' ? numeral(calculated).format(totalsNumberFormats[header.format]) : calculated ? calculated : '';
                    totals[index].totalObject = totalObject;
                }
            });
            return totals;
        },
        rowsPerPageItems() {
            const items = [10, 25, 50, 100 ];

            // Get the unique result of the default items and whatever the user has provided
            const combinedItems = [ ...new Set([
                ...items,
                ...[this.initialRows]
            ]) ].sort((a, b) => a - b);

            // Add all to the end if allowed
            if (!this.hideAllRowsOption) {
                combinedItems.push({ text: 'All', value: -1 });
            }

            return combinedItems;
        },
        filteredItems() {
            return this.items.filter(item => {
                const isFieldFiltered = Object.keys(this.filters).every(field => {
                    if (this.filters[field] instanceof Array) {
                        const filteredField = this.headers.find(header => header.value == field && header.customValues);
                        if (filteredField) {
                            const res = this.filters[field].find(it => item[field].includes(it));
                            return this.filters[field].length < 1 || res ? true : false;
                        }
                        return this.filters[field].length < 1 || this.filters[field].includes(item[field]);
                    } else {
                        const value = item[field].toString().toLowerCase();
                        const filter = this.filters[field].toLowerCase();
                        return filter.length < 1 || value.includes(filter);
                    }
                });

                // Note that we had to move the search binding from vuetify
                // to here becauase of the totals capability and needing to
                // recompute against the keyword filter too

                let matchesSearchString = true;

                if (this.search) {
                    matchesSearchString = this.headers.some(header => {
                        // Retrieve the field value or an empty string
                        const value = get(item, header.value) || '';

                        // Determine if the search string is in the string-cast result
                        return `${value}`.toLowerCase().includes(this.search.toLowerCase());
                    });
                }

                return isFieldFiltered ? matchesSearchString : false;
            });
        }
    },
    watch: {
        pagination(value) {
            this.internalPagination = value;
        },
        sortBy(value) {
            this.internalPagination.sortBy = value;
        },
        descending(value) {
            this.internalPagination.descending = value;
        }
    },
    mounted() {
        // Store the initial rows so we can ensure the option is available in rows per page
        if (this.rowsPerPage) {
            this.initialRows = this.rowsPerPage;
            this.internalPagination.rowsPerPage = this.rowsPerPage;
        } else if (this.pagination) {
            this.initialRows = this.pagination.rowsPerPage || 25;
        }

        if (this.sortBy) {
            this.internalPagination.sortBy = this.sortBy;
        }

        if (this.descending) {
            this.internalPagination.descending = this.descending;
        }

        // Determine if the headers have totals
        this.hasTotals = this.headers.some(header => Object.prototype.hasOwnProperty.call(header, 'total'));

        // Allow the consumer to control the table's width since we've
        // overridden the behavior with table-layout :fixed;
        this.tableElem = this.$refs.table.$el.querySelector('.v-table');
        this.tableElem.style.minWidth = this.minWidth;
    },
    methods: {
        changeSort(column) {
            const pagination = { ...this.internalPagination };

            if (pagination.sortBy === column) {
                pagination.descending = !pagination.descending;
            } else {
                pagination.sortBy = column;
                pagination.descending = false;
            }

            // For external control
            if (this.pagination) {
                this.$emit('update:pagination', pagination);
            }
            // For internal control
            else {
                this.internalPagination = pagination;
            }

            // Allow consumers to listen only to sorting events this
            // makes it easier if pagination is being handled externally
            this.$emit('update:sort-by', pagination.sortBy);
            this.$emit('update:descending', pagination.descending);

            // Emit a single event when multiple updates won't work (e.g for
            // server requests)
            this.$emit('change-sort', {
                sortBy: pagination.sortBy,
                descending: pagination.descending
            });
        },
        onUpdatePagination(pagination) {
            // For external control
            if (this.pagination) {
                this.$emit('update:pagination', pagination);
            }
            // For internal control
            else {
                this.internalPagination = pagination;
            }
        },
        columnValueList(header) {
            const value = header.value;
            const customValues = header.customValues;
            if (customValues) {
                return customValues;
            }
            return this.items.map(item => item[value]).sort();
        }
    }
};
</script>

<style lang="scss">

.styled-table-container {
    &.hide-margins {
        .styled-table {
            .v-table__overflow {
                margin: 0;
                width: 100%;
            }
        }
    }
    .styled-table {
        .v-table__overflow {
            margin: 0 auto 0.6rem;
            padding: 0 5px;
            width: calc(100% - 70px);
        }
    }
    .table-header {
        margin: 1.5rem 5rem;
        display: flex;
        .table-field {
            //max-width: 200px;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .filterd-field {
        height: 32px;
        display: flex;
        align-items: center;
    }
    .dark-column {
        background-color: rgba($gray, 0.1);
    }
    .action-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        .action-button {
            display: block;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
            span {
                white-space: nowrap;
                display: block;
            }
        }
    }
    .column-total {
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
    }

    .average {
        color: $gray-primary;
        font-weight: 400;
        white-space: nowrap;
        font-size: 11px;
        display: flex;
        justify-content: center;
    }

    .arrow-right {
        left:0px!important;
    }

    .footer-title {
        text-align: right;
        font-weight: bold;
    }

    .footer {
        background-color: $gray-lightshade;
        td > div {
            margin: 0.6rem 0;
        }
    }
}

</style>

<template>
    <div
        class="market-stats"
        :class="{
            'stats-element': true,
            'small': small
        }">
        <styled-tooltip
            :value="primaryTooltipActive"
            :disabled="!(toolTipMessage || !!$slots['tooltip'])"
            position="top"
            :max-width="tooltipMaxWidth"
            :offset-position="tooltipOffsetPosition"
            open-on-click>
            <template
                v-if="!!$slots['tooltip']"
                #content>
                <slot name="tooltip" />
            </template>

            <template
                v-else
                #content>
                <div class="blue-grey--text text--darken-4">
                    {{ toolTipMessage }}
                </div>
            </template>

            <div>
                <div class="stats-element-title text-uppercase">
                    <span
                        @mouseenter.stop="togglePrimaryTooltip(true)"
                        @mouseleave.stop="togglePrimaryTooltip(false)">
                        {{ title }}
                    </span>
                    <styled-tooltip
                        v-if="!!$slots['tooltip-secondary']"
                        :value="secondaryTooltipActive"
                        position="top"
                        :max-width="tooltipSecondaryMaxWidth"
                        :offset-position="tooltipSecondaryOffsetPosition"
                        class="tooltip-secondary"
                        open-on-click>
                        <template #content>
                            <slot name="tooltip-secondary" />
                        </template>
                        <div
                            @mouseenter.stop="toggleSecondaryTooltip(true)"
                            @mouseleave.stop="toggleSecondaryTooltip(false)">
                            <icon
                                name="tooltip"
                                width="17" />
                        </div>
                    </styled-tooltip>
                </div>
                <div
                    class="stats-element-value"
                    @mouseenter.stop="togglePrimaryTooltip(true)"
                    @mouseleave.stop="togglePrimaryTooltip(false)">
                    <slot v-if="!!$slots['default']" />
                    <h4
                        v-else
                        class="light-blue--text font-weight-medium-bold">
                        {{ value }}
                    </h4>
                </div>
                <div
                    v-if="showChange && percentChange != Infinity"
                    class="change"
                    :class="metricDisposition">
                    <icon
                        v-if="percentChange > 0"
                        key="1"
                        name="chevron-up"
                        size="12" />
                    <icon
                        v-if="percentChange < 0"
                        name="chevron-down"
                        size="12" />
                    <span
                        class="percentage"
                        :class="metricDisposition">{{ Math.abs(percentChange) }}%</span>
                </div>
            </div>
        </styled-tooltip>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import StyledTooltip from './StyledTooltip';

export default {
    components: {
        StyledTooltip,
        Icon
    },
    props: {
        metric: {
            type: [Number, String],
            default: 0,
        },
        metricValue: {
            type: Number,
            default: 0,
        },
        previousMetricValue: {
            type: Number,
            default: 0,
        },
        showChange: {
            type: Boolean,
            default: false,
        },
        changeReversed: {
            type: Boolean,
            default: true,
        },
        metricString: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '--',
        },
        symbol: {
            type: String,
            default: '',
        },
        symbolFirstPosition: {
            type: Boolean,
            default: false
        },
        toolTipMessage: {
            type: String,
            default: '',
        },
        tooltipMaxWidth: {
            type: Number,
            default: 300
        },
        tooltipOffsetPosition: {
            default: undefined,
            validator(value) {
                return ['right', 'left'].includes(value);
            }
        },
        tooltipSecondaryMaxWidth: {
            type: Number,
            default: 300
        },
        tooltipSecondaryOffsetPosition: {
            default: undefined,
            validator(value) {
                return ['right', 'left'].includes(value);
            }
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            primaryTooltipActive: false,
            primaryTooltipTimeout: null,
            secondaryTooltipActive: false,
            secondaryTooltipTimeout: null,
        };
    },
    computed: {
        value() {
            if (this.metricString !== '') {
                return this.metricString;
            }

            return this.symbolFirstPosition
                ? `${this.symbol}${this.metric}`
                : `${this.metric}${this.symbol}`;
        },
        metricDisposition() {
            if (this.percentChange > 0 && !this.changeReversed) {
                return 'good';
            } else if (this.percentChange < 0 && !this.changeReversed) {
                return 'bad';
            } else if (this.percentChange < 0 && this.changeReversed) {
                return 'good';
            } else if (this.percentChange > 0 && this.changeReversed) {
                return 'bad';
            }
            return 'good';
        },
        percentChange() {
            if (!this.metricValue && !this.previousMetricValue) {
                return 0;
            }
            return (
                ((this.metricValue - this.previousMetricValue) /
                    this.previousMetricValue) *
                100
            ).toFixed(2);
        }
    },
    destroyed() {
        clearTimeout(this.primaryTooltipTimeout);
        clearTimeout(this.secondaryTooltipTimeout);
    },
    methods: {
        togglePrimaryTooltip(value) {

            if (!this.toolTipMessage && !this.$slots['tooltip']) {
                return;
            }

            clearTimeout(this.primaryTooltipTimeout);

            if (value == true) {
                this.primaryTooltipActive = true;
                this.secondaryTooltipActive = false;
            } else {
                this.primaryTooltipTimeout = setTimeout(() => {
                    this.primaryTooltipActive = false;
                }, 200);
            }
        },
        toggleSecondaryTooltip(value) {

            if (!this.$slots['tooltip-secondary']) {
                return;
            }

            clearTimeout(this.secondaryTooltipTimeout);

            if (value == true) {
                this.secondaryTooltipActive = true;
                this.primaryTooltipActive = false;
            } else {
                this.secondaryTooltipTimeout = setTimeout(() => {
                    this.secondaryTooltipActive = false;
                }, 200);
            }
        }
    }
};
</script>
<style lang="scss">
.market-stats {
    svg path {
        fill: inherit;
    }
}
</style>

<style lang="scss" scoped>
.stats-element {
    text-align: center;
    padding: 25px 0;
}
.change {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &.bad {
        color: #f6685f;
    }
    &.good {
        color: #a2cf75;
    }
    .svg-icon {
        margin: 2px 2px 0 0;
    }
    .percentage {
        color: inherit;
    }
}

span {
    color: $light-grey-title;
}

h4 {
    font-size: 36px;
    line-height: 34px;

    @media (min-width: 960px) and (max-width: 1200px) {
        font-size: 28px;
    }
}

.metrics-stacked {
    h4 {
        font-size: 12px;
        line-height: 16px;
        word-spacing: 100vw;
    }
}

h5 {
    font-size: 28px;
    line-height: 30px;
}

.stats-element-title {
    position: relative;
    display: inline-block;
    .tooltip-secondary {
        position: absolute;
        left: calc(100% + 5px);
        top: 0;
    }
}

.stats-element-value {
    //display: inline-block;
}

.small {
    .stats-element-title {
        white-space: nowrap;
        font-size: 12px;
        line-height: 14px;
    }
    .stats-element-value,
    .stats-element-value h4 {
        font-size: 22px;
        line-height: 24px;
    }
}
</style>

<template>
    <div>
        <template v-if="!allFinished">
            <div class="pa-5">
                <h2 class="mb-2">
                    Reprocessing Inventory
                </h2>
                <p class="mb-0">
                    Please wait a moment while we reprocess inventory, this may take a couple minutes to complete. You will be able to review it once complete.
                </p>
                <div>
                    <div
                        v-for="(provider, index) in providers"
                        :key="index"
                        class="single-process">
                        <single-inventory-process
                            :feed-provider="provider"
                            @finished="singleProcessIsFinished" />
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <inventory-review />
        </template>
    </div>
</template>

<script>
import SingleInventoryProcess from '@/components/globals/SingleInventoryProcess';
import InventoryReview from '@/components/globals/InventoryReview';

export default {
    components: {
        SingleInventoryProcess,
        InventoryReview
    },
    data() {
        return {
            providers: [],
            finishedProcesses: [],
            allFinished: false
        };
    },
    watch: {
        finishedProcesses() {
            if (this.finishedProcesses.length === this.providers.length) {
                this.finishedProcesses = [];
                this.finishProcessing();
            }
        }
    },
    methods: {
        startProcessing(providers) {
            this.providers = providers;
            this.$emit('start');
        },
        finishProcessing() {
            this.allFinished = true;
            this.$emit('end');
        },
        singleProcessIsFinished() {
            this.finishedProcesses.push('finished');
        },
    }
};
</script>

<style lang="scss">
.single-process {
    border-bottom: 1px solid #D8D8D8;
    &:last-of-type {
        border-bottom: none;
    }
}
</style>
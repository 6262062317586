<template>
    <styled-dialog v-model="value">
        <!-- Add Destination Start -->
        <template
            v-if="!complete"
            #heading>
            <h2>Add Lead Destination</h2>
        </template>
        <v-alert
            :value="error"
            type="error"
            transition="fade-transition"
            class="mb-4">
            An error occurred. Please try again or contact us for assistance.
        </v-alert>
        <v-form
            v-if="!complete"
            id="add-lead-destination-form"
            ref="form"
            v-model="valid"
            @submit.prevent="addLeadDestination">
            <h4 class="mb-2">
                Destination Type
            </h4>
            <v-radio-group
                v-model="leadType"
                :mandatory="true"
                row
                :rules="radioRules"
                class="styled-radio">
                <v-radio
                    v-if="dealerInAutoVertical"
                    label="ADF XML"
                    :value="XML_EMAIL_LEAD_SENDER"
                    color="primary" />
                <v-radio
                    label="Plain Text"
                    :value="TEXT_EMAIL_LEAD_SENDER"
                    color="primary" />
                <v-radio
                    label="Webhook/Zapier®"
                    :value="WEBHOOK_LEAD_SENDER"
                    color="primary" />
            </v-radio-group>
            <v-text-field
                v-if="leadType"
                v-model="destination"
                :label="
                    leadType == WEBHOOK_LEAD_SENDER
                        ? 'Add Webhook URL'
                        : 'Add Email Destination'
                "
                :rules="activeRules"
                class="styled-field mt-3" />

            <v-checkbox
                v-if="leadType == TEXT_EMAIL_LEAD_SENDER"
                v-model="overrideDefaultSender"
                color="primary"
                class="styled-checkbox my-3">
                <template #label>
                    Override from address
                </template>
            </v-checkbox>

            <div v-if="overrideDefaultSender && leadType == TEXT_EMAIL_LEAD_SENDER">
                <v-text-field
                    v-model="senderOverrideEmail"
                    label="From Email"
                    :rules="activeRules"
                    class="styled-field mt-3" />

                <v-text-field
                    v-model="senderOverrideName"
                    :rules="simpleInputRules"
                    label="From Name"
                    class="styled-field mt-3" />


                <p class="copy-spf-description">
                    To ensure deliverability please include the following SPF record or
                    modify the one you have:
                </p>
                <div class="spf-code-example-container">
                    <div class="spf-code-example">
                        {{ spfDefaultConfig }}
                    </div>
                    <div class="copy-spf tooltip__layout">
                        <img
                            :src="require('@/assets/icons/copy.svg')"
                            alt="copy"
                            width="20px"
                            @click="handleCopy">
                        <span
                            class="tooltip__content"
                            :class="{ show: showCopied }">Copied!</span>
                    </div>
                </div>
            </div>
            <v-checkbox
                v-if="leadType"
                v-model="disclaimer"
                color="primary"
                :rules="activeRules"
                class="styled-checkbox my-3">
                <template #label>
                    By clicking this box you agree that you are responsible for verifying
                    that leads are reaching this destination
                </template>
            </v-checkbox>
            <div class="layout justify-center">
                <styled-button
                    :loading="loading"
                    :disabled="!valid"
                    class="text-uppercase mt-3 mb-3">
                    Submit
                </styled-button>
            </div>
        </v-form>
        <template
            v-if="!complete"
            #actions>
            <a
                href="#"
                class="red--text"
                @click.prevent="$emit('input', false)">
                Cancel
            </a>
        </template>
        <p
            v-if="leadType && !complete"
            class="my-3 text-center contact-style">
            Please contact
            <a href="mailto:support@buyerbridge.io">support@buyerbridge.io</a>
            for additional information or assistance with lead delivery testing
            procedures.
        </p>
        <!-- Add Destination End -->

        <!-- Completed Start -->
        <div
            v-if="complete"
            class="text-xs-center">
            <h2>Lead Destination Added!</h2>
            <p>
                The lead destination has been successfully added. You can close this
                dialog to continue.
            </p>
            <styled-button
                class="mx-auto"
                @click="$emit('input', false)">
                Close
            </styled-button>
        </div>
    <!-- Completed End -->
    </styled-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import {
  emailRules,
  requiredRules,
  radioRules,
  urlRules,
} from '@/helpers/validationRules';
import {
  XML_EMAIL_LEAD_SENDER,
  TEXT_EMAIL_LEAD_SENDER,
  WEBHOOK_LEAD_SENDER,
} from '@/helpers/globals';

export default {
  name: 'AddLeadDestination',
  components: {
    StyledDialog,
    StyledButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      spfDefaultConfig: 'v=spf1 include:sending.buyerbridge.io ~all',
      showCopied: false,
      overrideDefaultSender: false,
      destination: '',
      leadType: null,
      valid: false,
      loading: false,
      error: false,
      radioRules,
      complete: false,
      XML_EMAIL_LEAD_SENDER,
      TEXT_EMAIL_LEAD_SENDER,
      WEBHOOK_LEAD_SENDER,
      disclaimer: false,
      senderOverrideEmail: '',
      senderOverrideName: ''
    };
  },
  computed: {
    ...mapState({
      dealer: (state) => state.dealer.currentDealer,
    }),
    ...mapGetters([
        'dealerInAutoVertical'
    ]),
    activeRules() {
      if (this.leadType == this.WEBHOOK_LEAD_SENDER) {
        return [...requiredRules, ...urlRules];
      }

      return [...requiredRules, ...emailRules];
    },
    simpleInputRules() {
        return  [...requiredRules];
    },
  },
  watch: {
    active(value) {
      this.$emit(value);
    },
  },
  methods: {
    async addLeadDestination() {
      this.$refs.form.validate();

      if (!this.valid) {
        return;
      }

      this.loading = true;
      this.error = false;

      try {
        const params = {
          lead_sender_class_name: this.leadType,
          dealer_id: this.dealer.id,
        };

        if (this.leadType == this.WEBHOOK_LEAD_SENDER) {
          params.lead_sender_configuration = {
            webhook_url: this.destination,
          };
        } else {
          params.lead_sender_configuration = {
            email: this.destination,
            override_from_sender_email: this.senderOverrideEmail,
            override_from_sender_name: this.senderOverrideName,
          };
        }

        const url = `/dealers/${this.dealer.id}/lead_destinations`;

        await this.$http.post(url, params);

        this.complete = true;

        // Combine the response and the params so consumers can
        // use the data (e.g. to update the UI)
        this.$emit('updated');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error adding lead destination:', error);
        this.error = true;
        this.$emit('error', error);
      } finally {
        this.loading = false;
      }
    },
    handleCopy() {
      navigator.clipboard.writeText(this.spfDefaultConfig);
      this.showCopied = !this.showCopied;
      setTimeout(() => {
        this.showCopied = false;
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.contact-style {
  font-size: 12px;
  color: $gray-primary;
  font-style: italic;
}

.spf-code-example-container {
  display: flex;
  margin-top: 25px;
  align-items: center;
  background-color: #ebebeb;
  justify-content: space-between;
  border-radius: 7px;
  border: solid 1px #8d8d8d;
}

.spf-code-example {
  padding: 10px;
}

.copy-spf img {
  cursor: pointer;
  margin-right: 25px;
}

.tooltip__layout {
  position: relative;
  display: inline-block;
}

.tooltip__content.show {
  opacity: 1;
  transition: 1s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.tooltip__content {
  padding-left: 50px;
  right: 0px;
  top: 25px;
  background: #616161;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  display: inline-block;
  font-size: 12px;
  padding: 5px 8px;
  position: absolute;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  width: auto;
  opacity: 0;
}

.copy-spf-description {
    margin-top: 20px;
}
</style>
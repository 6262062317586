<template>
    <span
        class="faded-image-wrapper"
        :style="{ width, height, backgroundImage }">
        <img
            :class="{ 'faded-image' : true, 'faded-image-loaded': loaded }"
            :src="src"
            :width="width"
            :height="height"
            @load="loaded = true">
    </span>
</template>

<script>

export default {
    props: {
        src: {
            type: [String,null],
            default: null
        },
        placeholder: {
            type: String,
            required: true
        },
        width: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            loaded: false
        };
    },
    computed: {
        backgroundImage() {
            if (this.placeholder) {
                return `url('${this.placeholder}')`;
            }
            return 'url(\'/img/image-placeholder.svg\')';
        }
    }
};
</script>

<style scoped>
.faded-image-wrapper {
    display: inline-block;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}

.faded-image {
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.faded-image-loaded {
    opacity: 1;
}
</style>

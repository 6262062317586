<template>
    <div>
        <div
            v-if="error"
            class="pt-5 error-text">
            Something went wrong... please contact support
        </div>
        <file-drop
            v-else
            v-model="file"
            :types="types"
            :preview-image="initialImage"
            :shortened="shortened"
            :centered-title="centeredTitle"
            :centered-text="centeredText"
            :darked="darked"
            :rounded="rounded"
            :aspect-ratio="aspectRatio"
            :file-limit="fileLimit"
            @file-handled="onFileHandled"
            @file-removed="onFileRemoved">
            <template #title>
                {{ title }}
            </template>
        </file-drop>
    </div>
</template>

<script>
    import FileDrop from '@/components/globals/FileDrop';
    import { mapState } from 'vuex';

    export default {
        name: 'AssetUpload',
        components: { FileDrop },
        props: {
            types: {
                type: Array,
                default() {
                    return [];
                }
            },
            centeredTitle: {
                type: Boolean,
                default: false
            },
            centeredText: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
            initialImage: {
                type: String,
                default: null
            },
            shortened: {
                type: Boolean,
                default: false
            },
            darked: {
                type: Boolean,
                default: false
            },
            rounded: {
                type: Boolean,
                default: false
            },
            aspectRatio: {
                type: String,
                default: ''
            },
            records: {
                type: Array,
                default() {
                    return [];
                }
            },
            fileLimit: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                file: null,
                fileUrl: null,
                error: null
            };
        },
        computed: {
        ...mapState({
                dealer: state => state.dealer.currentDealer,
                user: state => state.user.user,
                agency: state => state.agency.currentAgency
            })
        },
        methods: {
            async onFileHandled(file) {
                console.log('File handled');
                this.$emit('loading-started');
                const formData = new FormData();
                formData.append('file', file);
                formData.append('display_name', file.name);
                if (this.records.includes('agency_id')) {
                    formData.append('agency_id', this.agency.id);
                }
                if (this.records.includes('dealer_id')) {
                    formData.append('dealer_id', this.dealer.id);
                }
                if (this.records.includes('user_id')) {
                    formData.append('user_id', this.user.id);
                }
                try {
                    const response = await this.$apiRepository.uploadMediaAsset(formData);
                    this.fileUrl = response?.data?.url;
                    this.$emit('asset-uploaded', response?.data?.id);
                    this.$emit('asset-uploaded-url', response?.data?.url, response?.data?.id);
                    this.$emit('loading-ended');
                } catch(error) {
                    this.error = error;
                    console.log(error);
                }
            },
            async onFileRemoved() {
                console.log('File removed');
                this.fileUrl = null;
                this.file = null;
            },
        }
    };
</script>

<style scoped>
.error-text{
    color:red;
    text-align: center;
}
</style>
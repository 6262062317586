<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2>Inventory Export Request Emails</h2>
            <p class="mb-4">
                Use the example messaging below to obtain approval from {{ dealer.name }}
                and have {{ feedProvider.name }} export inventory to BuyerBridge.
            </p>
            <styled-list>
                <div>
                    <h3>
                        Request for Approval
                    </h3>
                    <p>
                        Send this message to an authorized contact at {{ dealer.name }} that
                        authorizes {{ feedProvider.name }} to export their vehicle inventory to BuyerBridge.
                    </p>
                    <p>
                        <em>
                            Note: if you are already an authorized contact at {{ feedProvider.name }}
                            for {{ dealer.name }} you can skip this step
                        </em>
                    </p>
                    <copy-text-area
                        hide-download
                        :email-subject="`[APPROVAL REQ'D] ${feedProvider.name} <> ${dealer.name} request RE: Inventory Export`"
                        class="mb-4">
                        <p>
                            Greetings!
                        </p>
                        <p>
                            In order to activate inventory advertising solutions with us
                            we need access to your vehicle inventory through {{ feedProvider.name }}.
                        </p>
                        <p>
                            To proceed with this process {{ feedProvider.name }} will need your
                            approval.
                        </p>
                        <p>
                            Please REPLY ALL with "Approved" so we can work with {{ feedProvider.name }}
                            to finalize the export of your inventory.
                        </p>
                        <p>
                            Thanks!<br>
                            The {{ dealer.agency.name }} Team
                        </p>
                    </copy-text-area>
                </div>
                <div>
                    <h3>Request for Inventory Export</h3>
                    <p>
                        Forward the approval received from the previous message
                        to {{ feedProvider.name }} with the message below
                    </p>
                    <p>
                        <styled-tooltip
                            high-index
                            position="top">
                            <template #content>
                                <p>
                                    Based on proven performance data, BuyerBridge
                                    highly recommends using high-quality vehicle
                                    photos <strong>without overlays</strong>.
                                </p>
                                <p class="pb-0">
                                    It is important to note that most feed providers
                                    have the ability to remove overlays but some
                                    may not.
                                </p>
                            </template>
                            <v-checkbox
                                v-model="addOverlayRemovalContent"
                                color="primary"
                                label="Add overlay removal request (recommended)"
                                class="styled-checkbox" />
                        </styled-tooltip>
                    </p>
                    <copy-text-area
                        hide-download
                        :email-to="!!feedProvider.support_email ? feedProvider.support_email : ''"
                        :email-subject="`BuyerBridge Inventory Export Request: ${dealer.name}`"
                        class="mb-4">
                        <p>
                            Hello {{ feedProvider.name }}!
                        </p>
                        <p>
                            We're in the process of setting up our advertising system with
                            {{ dealer.name }}. To finalize the process we'd like to
                            export their inventory to our technology partner BuyerBridge.
                        </p>
                        <p>
                            Please add the inventory for the dealership listed below to
                            the existing feed for BuyerBridge and let us know what the
                            dealer ID will be.
                        </p>
                        <p v-if="addOverlayRemovalContent">
                            If your system has the ability to remove overlays
                            (e.g. use "clean" photos) please remove them in
                            this export.
                        </p>
                        <p>
                            <em>
                                **If possible, please manually push an updated file with
                                this dealer's inventory added**
                            </em>
                        </p>
                        <p>
                            <strong>Dealer:</strong> {{ dealer.name }}<br>
                            <strong>Website:</strong> {{ dealerHostname }}<br>
                            <strong>Phone:</strong> {{ dealer.phone }}<br>
                            <strong>Address:</strong> {{ dealerAddressString }}
                        </p>
                        <p>
                            Our contact at {{ dealer.name }} has approved this change in the thread below.
                        </p>
                        <p>
                            Thanks!<br>
                            The {{ dealer.agency.name }} Team
                        </p>
                    </copy-text-area>
                </div>
            </styled-list>
        </div>
    </styled-slideout>
</template>

<script>
import { mapGetters } from 'vuex';
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledList from '@/components/globals/StyledList';
import CopyTextArea from '@/components/globals/CopyTextArea';

export default {
    name: 'ResendFeedProvider',
    components: {
        StyledTooltip,
        StyledSlideout,
        StyledList,
        CopyTextArea
    },
    props: {
        dealer: {
            type: Object,
            required: true
        },
        feedProvider: {
            type: Object,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            addOverlayRemovalContent: true,
        };
    },
    computed: {
        ...mapGetters([
            'dealerAddressString',
            'dealerHostname'
        ])
    }
};
</script>
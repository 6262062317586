var render = function render(){var _vm=this,_c=_vm._self._c;return _c('styled-slideout',{attrs:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('div',{staticClass:"pa-5"},[_c('h2',[_vm._v("Banner Provider Request")]),_c('p',[_vm._v(" Please fill out the form below to request support for a new banner provider and allow up to 72 hours for us to respond to the request. ")]),_c('p',[_c('i',[_vm._v("Note that it can take up to 4 weeks to accommodate a new provider request")])])]),_c('div',{staticClass:"pl-5 pr-5 pb-5"},[_c('hub-spot-form',{attrs:{"form-id":"e7839732-5933-469b-ad8a-4db19a639ec1","field-values":{
                'TICKET.agency_name': _vm.currentDealer.agency.name,
                'TICKET.form___dealer_name': _vm.currentDealer.name,
                'TICKET.initial_ticket_owner_contact_name': _vm.user.name,
                'email': _vm.user.email,
                'TICKET.subject': `Privacy Banner Request - ${_vm.currentDealer.agency.name} - ${_vm.currentDealer.name} - Support`,
            }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
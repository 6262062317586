<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <v-alert
                v-model="complete"
                type="success"
                class="mb-4">
                You have successfully updated the lead settings
                for {{ currentDealer.name }}
            </v-alert>
            <v-alert
                v-model="error"
                type="error"
                class="mb-4">
                {{ error }}
            </v-alert>
            <h2>Manage Lead Settings</h2>
            <p class="mb-4">
                Use the form below to manage how leads are
                handled for {{ currentDealer.name }}.
            </p>
            <v-form
                ref="form"
                v-model="valid"
                @submit.prevent="handleSubmit">
                <h3>ADF-XML</h3>
                <p class="mb-4">
                    Update the settings below to modify standard ADF-XML
                    fields for this account.
                </p>
                <v-text-field
                    v-model="configuration.override_xml_provider_name"
                    label="Override XML Provider Name"
                    class="styled-field mb-4" />
                <v-text-field
                    v-model="configuration.override_xml_id_sequence"
                    label="Override XML ID Sequence"
                    class="styled-field mb-4" />
                <v-text-field
                    v-model="configuration.override_xml_id_source"
                    label="Override XML ID Source"
                    class="styled-field mb-4" />
                <v-select
                    v-model="configuration.override_xml_lead_type"
                    :items="leadTypes"
                    label="Override Lead Type"
                    hint="Generally affects UpType in Elead"
                    class="styled-field"
                    clearable
                    persistent-hint />
                <div class="text-xs-center mt-5">
                    <styled-button
                        :loading="loading">
                        UPDATE SETTINGS
                    </styled-button>
                </div>
            </v-form>
        </div>
    </styled-slideout>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import StyledSlideout from '@/components/globals/StyledSlideout';
import { mapActions, mapState } from 'vuex';
import { isEmpty, pick } from 'lodash';

export default {
    components: {
        StyledButton,
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            leadTypes: [{
                text: 'Internet',
                value: 'internet'
            },{
                text: 'Phone',
                value: 'phone'
            },{
                text: 'Campaign',
                value: 'campaign'
            },{
                text: 'Showroom',
                value: 'showroom'
            }],
            loading: false,
            error: null,
            complete: false,
            valid: false,
            managedKeys: [
                'override_xml_provider_name',
                'override_xml_id_sequence',
                'override_xml_id_source',
                'override_xml_lead_type'
            ],
            configuration: {}
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        })
    },
    watch: {
        value(newValue, oldValue) {
            // If we're transitioning to open and the form was previously
            // submitted reset it
            if (newValue === true
                && oldValue === false
                && this.complete === true) {
                this.complete = false;
            }
        },
        currentDealer() {
            this.syncConfiguration();
        }
    },
    created() {
        this.syncConfiguration();
    },
    methods: {
        ...mapActions(['updateCurrentDealer']),
        syncConfiguration() {
            let configuration = {};

            // Map in the keys this form is managing
            this.managedKeys.forEach(key => {
                configuration[key] = '';
            });

            const storedConfiguration = pick(
                this.currentDealer.configuration,
                this.managedKeys
            );

            // If the dealer stored configuration that's managed
            // here map it all together
            if (!isEmpty(storedConfiguration)) {
                configuration = {
                    ...configuration,
                    ...storedConfiguration
                };
            }

            this.configuration = configuration;
        },
        async handleSubmit() {
            try {
                this.error = null;
                this.complete = false;

                this.$refs.form.validate();

                // Ensure the form validates
                await this.$nextTick();

                if (!this.valid) {
                    return;
                }

                this.loading = true;

                // Update the dealer's configuration (if there's anything to update)
                await this.updateConfiguration();

                // If an update was made ensure the UI has the new dealer
                await this.updateCurrentDealer();

                this.complete = true;

            } catch(error) {
                console.error('There was an error updating the settings', error.response || error);
                this.error = 'There was an error updating the settings.  Please try again or contact support';
            } finally {
                this.loading = false;
            }
        },
        async updateConfiguration() {

            const newConfiguration = {};

            // Prep the data on the server
            for (let key in this.configuration) {

                // Convert empty strings to null values to
                // ensure correct behaviors
                // @todo we need a better solution for this since an empty
                // string might imply different things for overrides vs stationary
                if (this.configuration[key] === '') {
                    newConfiguration[key] = null;
                } else {
                    newConfiguration[key] = this.configuration[key];
                }
            }

            // Run the central configuration update function
            await this.$apiRepository.updateDealerConfiguration(
                this.currentDealer.id,
                newConfiguration
            );
        }
    }
};
</script>

import ApiRepository from '@/api-repository';
import { default as channelsConfig } from '@/components/onboarding/channel-setup/channels';
const apiRepository = new ApiRepository();

export const getNotEnrolledChannels = async (availableChannels, enrolledChannels, agencyId, useChannelsConfig) => {
    let available = null;
    if (useChannelsConfig) {
        available = availableChannels.filter(channel => (
            channel.products.every(product => (
                product.public == true
            ))
            // Ensure only channels that have been mapped
            // in the UI display as upgrades since that step
            // relies on the channel config data
            && channelsConfig.some(channelConfig => (
                channelConfig.key === channel.name
            ))
        ));
    } else {
        available = availableChannels.filter(channel => channel.products.every( product => product.public == true));
    }
    const current = enrolledChannels.map(channel => channel.id);
    // List of platforms that currently support omni authentication
    const allowedOmniAuthenticationChannels = ['snapchat', 'tiktok', 'pinterest'];
    const notEnrolledChannels = await Promise.all(available
        .filter(available => !current.includes(available.id))
        .map(async(channel) => {
            let authStatus = true;
            if (allowedOmniAuthenticationChannels.includes(channel.name.toLowerCase())) {
                try {
                    await apiRepository.getPlatformAuthStatus(agencyId, channel.name.toLowerCase()).then(response => {
                        if(response.data.token) {
                            authStatus = true;
                        } else {
                            authStatus = false;
                        }
                    });
                } catch (error) {
                    authStatus = false;
                }
            }
            return {
                ...channel,
                authStatus,
            };
        }));
    return notEnrolledChannels;
}
<template>
    <div>
        <h3>
            Request for Approval
        </h3>
        <p>
            Send this message to an authorized contact at {{ currentDealer.name }} that
            authorizes {{ feedProvider.name }} to export their vehicle inventory to BuyerBridge.
        </p>
        <p>
            <em>
                Note: if you are already an authorized contact at {{ feedProvider.name }}
                for {{ currentDealer.name }} you can skip this step
            </em>
        </p>
        <copy-text-area
            hide-download
            :email-subject="`[APPROVAL REQ'D] ${feedProvider.name} <> ${currentDealer.name} request RE: Inventory Export`"
            class="mb-4">
            <p>
                Greetings!
            </p>
            <p>
                In order to activate inventory advertising solutions with us
                we need access to your vehicle inventory through {{ feedProvider.name }}.
            </p>
            <p>
                To proceed with this process {{ feedProvider.name }} will need your
                approval.
            </p>
            <p>
                Please REPLY ALL with "Approved" so we can work with {{ feedProvider.name }}
                to finalize the export of your inventory.
            </p>
            <p>
                Thanks!<br>
                The {{ currentDealer.agency.name }} Team
            </p>
        </copy-text-area>
    </div>
</template>

<script>
import CopyTextArea from '@/components/globals/CopyTextArea';
import { mapState } from 'vuex';

export default {
    components: {
        CopyTextArea
    },
    props: {
        feedProvider: {
            type: Object,
            required: true
        },
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        })
    }
};
</script>

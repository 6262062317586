<template>
    <div class="chat-bubble-wrapper">
        <div
            :class="{
                'chat-bubble': true,
                'chat-bubble-left': (align === 'left'),
                'chat-bubble-right': (align === 'right'),
            }">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        align: {
            type: String,
            default: 'left'
        },
    }
};
</script>

<style lang="scss" scoped>

.chat-bubble-wrapper {
    padding-bottom: 15px;
}

.chat-bubble {
    padding: 15px 25px;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: -15px;
        border-style: solid;
        display: block;
        width: 0;
    }
}

.chat-bubble-left {
    background-color: $alabaster-dark;
    border-left: 5px solid $blue-primary;
    &::after {
        border-color: $alabaster-dark transparent;
        left: 20px;
        border-width: 15px 0 0 15px;
    }
}

.chat-bubble-right {
    background-color: $blue-bg;
    border-right: 5px solid $blue-primary;
    color: $white;
    &::after {
        border-color: $blue-bg transparent;
        left: calc(100% - 35px);
        border-width: 15px 15px 0 0;
    }
}
</style>
<template>
    <styled-slideout
        :value="value"
        :nested.sync="deactivateDealerDialogActive"
        :width="700"
        @input="$emit('input', $event)">
        <div class="header">
            <h2>Manage Subscriptions</h2>
            <p>
                Below are your current products.  If you would like to
                cancel one or more please select them below and hit "Remove Products".
            </p>
        </div>
        <div class="channels">
            <div
                v-for="channel in channels"
                :key="channel.id"
                class="channel">
                <div class="badge">
                    <img
                        class="logo"
                        :src="'/img/platforms/' + channel.name + '.svg'">
                    <p class="name">
                        {{ channel.display_name }}
                    </p>
                </div>
                <div class="products">
                    <div
                        v-for="product in channel.products"
                        :key="product.id"
                        class="product">
                        <p class="name">
                            {{ product.display_name }}
                        </p>
                        <v-checkbox
                            v-model="productsToRemove"
                            class="styled-checkbox"
                            color="primary"
                            :value="product"
                            label="Remove"
                            multiple />
                        <div
                            v-if="checkUpgradeEligibility(product)"
                            class="upgrade">
                            <a href="#">Upgrade</a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="!dealerProducts.length"
                class="no-products">
                There are no products assoicated with this account
            </div>
        </div>
        <div class="footer">
            <div
                v-if="productsToRemove.length"
                class="mb-4">
                <v-checkbox
                    v-model="removeProductVerification"
                    color="primary"
                    class="ma-0 py-1 styled-checkbox">
                    <template #label>
                        <p>
                            Check here to confirm that you agree to remove this product <b>and acknowledge that you are responsible for any campaigns that may still be active in the platform's Ads manager</b> in line with our
                            <a
                                href="https://buyerbridge.io/our-terms/"
                                target="_blank">
                                <span>terms of use</span>
                            </a>.
                        </p>
                    </template>
                </v-checkbox>
            </div>
            <div class="remove-button">
                <styled-button
                    :disabled="!productsToRemove.length || !removeProductVerification"
                    :loading="loadingRemoveProducts"
                    @click="onRemoveProducts()">
                    REMOVE
                </styled-button>
            </div>

            <div class="deactivate-account">
                <p>
                    If you would like to deactivate this account
                    entirely click the link below and follow the prompts
                </p>
                <a
                    href="#"
                    @click.prevent="deactivateDealerDialogActive = true">
                    Deactivate Account
                </a>
            </div>
        </div>
        <template #nested>
            <deactivate-dealer />
        </template>
    </styled-slideout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledButton from '@/components/globals/StyledButton';
import DeactivateDealer from './DeactivateDealer';

export default {
    name: 'Subscriptions',
    components: {
        StyledSlideout,
        StyledButton,
        DeactivateDealer
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        channels: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            deactivateDealerDialogActive: false,
            loadingRemoveProducts: false,
            productsToRemove: [],
            removeProductVerification: false
        };
    },
    computed: {
        ...mapGetters([
            'dealerProducts',
            'activeDealerOnboardings'
        ])
    },
    methods: {
        ...mapActions([
            'updateCurrentDealer'
        ]),
        checkUpgradeEligibility(product) {
            // Facebook Specific Logic
            if(product.platform_id == 1) {
                if(product.id == 19 || product.id == 21) {
                    return true;
                }
            }
            return false;

        },
        async onRemoveProducts() {
            this.loadingRemoveProducts = true;

            // Remove associated onboardings, if applicable
            await this.removeAssociatedOnboardings();

            // Execute the initial requests to delete the products
            const removePromises = this.productsToRemove.map(product => {
                return this.$apiRepository.deleteDealerProduct(product.dealer_product.id);
            });

            await Promise.all(removePromises);

            this.productsToRemove = [];

            await this.updateCurrentDealer();

            this.loadingRemoveProducts = false;

            this.$emit('input', false);
        },
        async removeAssociatedOnboardings() {

            // If there's no onboardings there's nothing to do!
            if (!this.activeDealerOnboardings.length) {
                return;
            }

            const onboardingsToRemove = [];

            // Loop through all the active onboardings and determine
            // which to delete based on the product
            this.activeDealerOnboardings.forEach(onboarding => {
                if(this.productsToRemove.includes(onboarding.product_id) && onboarding.play_id == null) {
                    onboardingsToRemove.push(onboarding.id);
                }
            });

            // Execute the initial onboarding removal promises
            const removeObPromises = onboardingsToRemove.map(onboardingId => {
                return this.$apiRepository.deleteDealerProductOnboarding(onboardingId);
            });

            await Promise.all(removeObPromises);
        }
    }
};
</script>

<style lang="scss" scoped>
.header {
    margin: 30px;
    h2 {
        margin-bottom: 15px;
    }
    p {
        margin: 0 15px;
    }
}
.channels {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.channel {
    display: flex;
    width: 100%;
    padding: 30px 60px;
    border-bottom: 1px solid $border-list;

    .badge {
        width: 20%;
        text-align: center;
        .logo {
            height: 30px;
            width: auto;
        }
        .name {
            color: $blue-primary;
        }
    }

    .products {
        width: 80%;
        display: flex;
        flex-direction: column;
    }
}
.product {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    .name {
        width: 50%;
        padding-left: 45px;
        color: $black;
    }
    .upgrade {
        font-size: 12px;
        margin: 0 30px;
    }
}
.footer {
    padding: 45px;
    text-align: center;

    .deactivate-account {
        max-width: 350px;
        margin: 30px auto;
    }
}

</style>

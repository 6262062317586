<template>
    <span>
        {{ timeAgo }}
    </span>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        value: {
            type: Date,
            required: true
        }
    },
    data() {
        return {
            timeAgo: null
        };
    },
    watch: {
        value() {
            // Reset when a new value comes in
            this.setTimeAgo();
            this.stopInterval();
            this.startInterval();
        }
    },
    created() {
        this.setTimeAgo();
        this.startInterval();
    },
    destroyed() {
        this.stopInterval();
    },
    methods: {
        setTimeAgo() {
            this.timeAgo = moment(this.value).fromNow();
        },
        startInterval() {
            this.interval = setInterval(() => {
                this.setTimeAgo();
            }, 30000);
        },
        stopInterval() {
            clearInterval(this.interval);
        }
    }
};
</script>

<template>
    <styled-slideout
        :value="value"
        :width="1100"
        @input="$emit('input', $event)">
        <div class="ma-5">
            <h3 class="mb-4 ml-1">
                Website Inventory Review - {{ rootDomain }}
            </h3>
            <website-inventory-table
                :inventory="inventory"
                :root-domain="rootDomain"
                :loading="loading"
                :previous-page="previousPage"
                :next-page="nextPage"
                @go-to-previous="$emit('go-to-previous')"
                @go-to-next="$emit('go-to-next')" />
        </div>
    </styled-slideout>
</template>

<script>
import WebsiteInventoryTable from '@/components/globals/WebsiteInventoryTable';
import StyledSlideout from '@/components/globals/StyledSlideout';

export default {
    components: {
        WebsiteInventoryTable,
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        inventory: {
            type: Array,
            required: true
        },
        rootDomain: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        previousPage: {
            type: Number,
            default: 0
        },
        nextPage: {
            type: Number,
            default: 0
        }
    }
};
</script>

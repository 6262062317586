<template>
    <div class="vehicle">
        <div class="vehicle-image-wrapper">
            <div class="vehicle-image">
                <img :src="vehicleImage">
            </div>
        </div>
        <div class="vehicle-info-first">
            <div class="vehicle-info">
                <span class="info-title">Year:</span>
                <span class="info-desc">{{ vehicleYear }}</span>
            </div>
            <div class="vehicle-info">
                <span class="info-title">Make:</span>
                <span class="info-desc">{{ vehicleMake }}</span>
            </div>
            <div class="vehicle-info">
                <span class="info-title">Model:</span>
                <span class="info-desc">{{ vehicleModel }}</span>
            </div>
            <div class="vehicle-info">
                <span class="info-title">Trim:</span>
                <span class="info-desc">{{ vehicleTrim }}</span>
            </div>
        </div>
        <div class="vehicle-info-second">
            <div class="vehicle-info">
                <span class="info-title">Type:</span>
                <span class="info-desc">{{ vehicleType }}</span>
            </div>
            <div class="vehicle-info">
                <span class="info-title">VIN:</span>
                <span class="info-desc">{{ vehicleVin }}</span>
            </div>
            <div class="vehicle-info">
                <span class="info-title">Stock:</span>
                <span class="info-desc">{{ vehicleStock }}</span>
            </div>
            <div class="vehicle-info">
                <span class="info-title">Price:</span>
                <span class="info-desc">{{ vehiclePrice | numFormat('$0,0') }}</span>
            </div>
        </div>
        <div class="vehicle-info-links">
            <div class="vehicle-links">
                <template v-if="reviewActive">
                    <button
                        class="vehicle-button"
                        @click="onReview">
                        Review
                    </button>
                </template>
                <template v-else>
                    <div
                        class="vehicle-link"
                        @click="onViewDetail">
                        Details
                    </div>
                    <div
                        class="vehicle-link"
                        @click="onViewVDP">
                        VDP
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        vehicle: {
            type: Object,
            default: () => {},
        },
        reviewActive: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
        }),
        vehicleImage() {
            return this.vehicle?.images?.[0] ?? this.vehicle.photo_links?.[0] ?? '';
        },
        vehicleYear() {
            return this.vehicle?.year ?? '';
        },
        vehicleMake() {
            return this.vehicle?.make_name ?? this.vehicle?.make ?? '';
        },
        vehicleModel() {
            return this.vehicle?.model_name ?? this.vehicle?.model ?? '';
        },
        vehicleTrim() {
            return this.vehicle?.trim_name ?? this.vehicle?.trim ?? '';
        },
        vehicleType() {
            return this.vehicle?.vehicle_type_name ?? this.vehicle?.vehicle_type ?? '';
        },
        vehicleVin() {
            return this.vehicle?.vin ?? '';
        },
        vehicleStock() {
            return this.vehicle?.stock_number ?? this.vehicle?.stock_no ?? '';
        },
        vehiclePrice() {
            return this.vehicle?.price ?? '';
        }
    },
    methods: {
        onViewVDP() {
            const url = this.vehicle?.vdp_url ?? this.vehicle?.more_info;
            window.open(url, '_blank');
        },
        onViewDetail() {
            if (this.vehicle.vehicle_id) {
                const routeData = this.$router.resolve({
                    name: 'inventory-id',
                    params: {
                        dealer_id: this.currentDealerId,
                        id: this.vehicle.vehicle_id
                    }
                });
                window.open(routeData.href, '_blank');
            } else {
                this.$emit('open-details', this.vehicle);
            }
        },
        onReview() {
            // to do
        }
    }
};
</script>

<style lang="scss" scoped>
.vehicle {
    width: 100%;
    max-width: 590px;
    margin: 23px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    background-color: #FAFBFC;
    display: inline-flex;
    box-sizing: border-box;
}
.vehicle-image {
    width: 122px;
    height: 122px;
    border: 1px solid #979797;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vehicle-image img {
    width: 100%;
    height: auto;
}
.vehicle-image-wrapper {
    width: 25%;
}
.vehicle-info-first {
    width: 25%;
}
.vehicle-info-second {
    width: 35%;
}
.vehicle-info-links {
    width: 15%;
}
.vehicle-info {
    font-size: 13px;
    margin-top: 9px;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.info-title {
    text-transform: uppercase;
    color: #8F9EA6;
    margin-right: 5px;
}
.info-desc {
    color: #4A4A4A;
}
.vehicle-info-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.vehicle-links {
    width: 100%;
}
.vehicle-link {
    text-decoration: underline;
    color: $blue-bg;
    font-size: 14px;
    text-transform: uppercase;
    margin: 8px 0;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
}
.vehicle-button {
    color: $white;
    background-color: $blue-bg;
    padding: 5px 15px;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
}
</style>
<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div class="container">
            <h3>Account Address</h3>
            <div class="form-fields">
                <v-select
                    ref="countryField"
                    v-model="country"
                    :rules="requiredRules"
                    :items="countries"
                    item-text="name"
                    item-value="abbreviation"
                    label="Country"
                    class="styled-field"
                    return-object />
                <v-text-field
                    ref="streetField"
                    v-model="street"
                    :label="addressLabel"
                    :rules="requiredRules"
                    class="styled-field"
                    @blur="validateAddress" />
                <div class="field-group">
                    <v-text-field
                        ref="cityField"
                        v-model="city"
                        label="City"
                        :rules="localityRules"
                        class="styled-field"
                        @blur="validateAddress" />
                    <v-autocomplete
                        ref="administrativeAreaField"
                        v-model="administrativeArea"
                        :label="administrativeAreaLabel"
                        :rules="requiredRules"
                        :items="administrativeAreas"
                        item-text="name"
                        item-value="value"
                        :no-data-text="`No matching ${administrativeAreaLabel} found...`"
                        class="styled-field" />
                    <v-text-field
                        ref="postalCodeField"
                        v-model="postalCode"
                        :label="postalLabel"
                        :rules="[...requiredRules, ...postalRules]"
                        class="styled-field"
                        @blur="validateAddress" />
                </div>
            </div>

            <p class="map-info">
                Sometimes the map pin can be a bit off target. If you see any discrepancies to your address above please drag the pin to your desired location.
            </p>
            <div class="map">
                <map-marker-selector
                    v-model="coordinates"
                    :zoom="zoom"
                    update-center />
            </div>
            <styled-button
                @click="updateDealer">
                Save
            </styled-button>
        </div>
    </styled-slideout>
</template>

<script>
import { mapState } from 'vuex';
import StyledSlideout from '@/components/globals/StyledSlideout';
import MapMarkerSelector from '@/components/globals/MapMarkerSelector';
import StyledButton from '@/components/globals/StyledButton';
import validationRules from '@/helpers/validationRules';
import countryAreas from '@/helpers/countries';
import { get } from 'lodash';

export default {
    name: 'UpdateDealer',
    components: {
        StyledSlideout,
        StyledButton,
        MapMarkerSelector
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // Validation
            requiredRules: validationRules.requiredRules,
            localityRules: [],
            postalRules: [],
            addressLabel: '',
            administrativeAreaLabel: '',
            postalLabel: '',

            // Map Marker Data
            zoom: 15,
            coordinates: {},

            // Select Data
            countries: [],

            // Dealer Data
            country: '',
            street: '',
            city: '',
            administrativeArea: '',
            postalCode: '',
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer
        }),
        administrativeAreas() {
            if (!this.country) {
                return [];
            }

            return this.country.areas.map(area => {

                let name = area.name;
                // Append the abbreviation if it's set
                if (area.abbreviation) {
                    name += ` (${area.abbreviation})`;
                }

                return {
                    name,
                    value: area.abbreviation || area.name
                };
            });
        },
        formattedAddress() {
            if (!this.street ||
                !this.city ||
                !this.administrativeArea ||
                !this.postalCode ||
                !this.country) {
                return null;
            }

            // Validate all address fields
            if (
                !get(this.$refs,'streetField.valid') ||
                !get(this.$refs,'cityField.valid') ||
                !get(this.$refs,'administrativeAreaField.valid') ||
                !get(this.$refs,'postalCodeField.valid') ||
                !get(this.$refs,'countryField.valid')
            ) {
                return null;
            }

            return {
                street: this.street,
                city: this.city,
                administrativeArea: this.administrativeArea,
                postalCode: this.postalCode,
                country: this.country.abbreviation,
                formatted: `${this.street}, ${this.city}, ${this.administrativeArea}, ${this.postalCode}, ${this.country.name}`
            };
        },
    },
    mounted() {
        // Syncing input values with dealer values
        this.coordinates = {
            lat: parseFloat(this.dealer.latitude),
            lng: parseFloat(this.dealer.longitude)
        };
        this.countries = countryAreas;
        this.country = this.countries.find(country => country.abbreviation == this.dealer.country_code);
        this.addressLabel = this.country.translations.address;
        this.administrativeAreaLabel = this.country.translations.administrativeArea;
        this.postalLabel = this.country.translations.postal;
        this.localityRules = validationRules.localityRules(this.country.abbreviation);
        this.postalRules = validationRules.postalRules(this.country.abbreviation);
        this.street = this.dealer.address;
        this.city = this.dealer.city;
        this.administrativeArea = this.dealer.state;
        this.postalCode = this.dealer.zip;
    },
    methods: {
        async updateDealer() {
            console.log('Validating Address...');
            const valid = await this.validateAddress();
            console.log('Valid Address:', valid);

            if(valid) {
                const address = {
                    address: this.formattedAddress.street,
                    city: this.formattedAddress.city,
                    state: this.formattedAddress.administrativeArea,
                    zip: this.formattedAddress.postalCode,
                    country_code: this.formattedAddress.country,
                    latitude: this.coordinates.lat,
                    longitude: this.coordinates.lng
                };
                // Update Dealer Address
                await this.$apiRepository.updateDealerAddress(this.dealer.id, address);
                this.$emit('updated');
                this.$emit('input', false);
            }
        },
        async validateAddress() {
            // Validate Address against Facebook
            const location = await this.$apiRepository.getFacebookLocation(this.dealer.name + ' ' + this.formattedAddress.formatted);
            this.coordinates = location.position;
            return true;
        },
    }
};
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
}
h3 {
    align-self: flex-start;
    margin-bottom: 20px;
}
.mismatch-message,
.low-quality-message {
    background: $alabaster;
    padding: 15px;
    color: $carnation;
    text-align: center;
}
.mismatch-label {
    font-size: 18px;
}
.mismatches {
    list-style: none;
    margin-bottom: 10px;
}
.no-fix {
    color: $carnation;
    margin-bottom: 20px;
}
.form-fields {
    margin-left: 30px;
}
.field-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .styled-field:nth-of-type(1) {
        margin-right: 10px;
    }
}
.styled-field {
    margin-bottom: 20px;
}
.map-info {
    color: $gray-text;
}
.map {
    margin: 30px 0;
    width: 450px;
    height: 260px;
}
</style>
import { ExportToCsv } from 'export-to-csv';

const exportToCsv = (filename, data, options = {}) => {
    // Exporter defaults
    const defaults = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename
    };

    options = { ...defaults, ...options };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
};

export default exportToCsv;

<template>
    <div>
        <email-instructions
            v-model="instructionsActive"
            :txt="txt"
            :cname="cname"
            :cname-is-valid="cnameIsValid" />
        <div
            class="holder"
            :class="{'slim': slim}">
            <div class="holder-left">
                <h2 class="holder-title">
                    EventFlowPLUS DNS Setup
                </h2>
                <p :class="{'mb-5': !slim}">
                    EventFlowPLUS needs to operate under {{ currentDealer.name }}'s primary domain to ensure that all data
                    and user identifiers function in a "first-party" manner. This effectively makes {{ currentDealer.name }} the
                    primary data broker for all audience signals and, likewise, ensures they maintain ownership over privacy controls.
                    For this to happen, you'll need to work through the DNS record installation as noted below.
                </p>
            </div>
            <div class="holder-right">
                <button
                    :disabled="loading"
                    class="styled-button"
                    @click="getDNSRecords">
                    <icon
                        class="mr-2"
                        name="refresh"
                        color="#00A2EA"
                        size="12" />
                    Recheck Status
                </button>
                <button
                    :disabled="loading || setupCompleted"
                    class="ml-3 styled-button"
                    @click="instructionsActive = true">
                    <icon
                        class="mr-2"
                        name="mail"
                        color="#00A2EA"
                        size="12" />
                    Email Instructions
                </button>
            </div>
        </div>
        <loader
            v-if="loading"
            label="Provisioning EventFlowPLUS..." />
        <div
            v-else
            class="main">
            <div
                class="records"
                :class="{'slim': slim}">
                <div class="record-wrapper">
                    <dns-record
                        title="Validation TXT"
                        :record="txt"
                        :valid="txtIsValid" />
                </div>
                <div class="record-wrapper">
                    <dns-record
                        title="Sub-Domain CNAME"
                        :record="cname"
                        :valid="cnameIsValid" />
                </div>
            </div>
            <div
                v-if="!slim"
                class="mt-5 layout align-center">
                <back-button
                    @click="$store.dispatch('goToPreviousStep')" />
                <styled-button
                    class="ml-auto"
                    :disabled="!setupCompleted"
                    @click="$store.dispatch('goToNextStep')">
                    CONTINUE
                </styled-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Icon from '@/components/globals/Icon';
import Loader from '@/components/globals/Loader';
import DnsRecord from '@/components/globals/DnsRecord';
import BackButton from '@/components/globals/BackButton';
import StyledButton from '@/components/globals/StyledButton';
import EmailInstructions from '@/components/dealers/settings/panels/EmailInstructions';

export default {
    components: {
        Icon,
        Loader,
        DnsRecord,
        BackButton,
        StyledButton,
        EmailInstructions,
    },
    props: {
        slim: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            records: [],
            loading: true,
            cnameIsValid: false,
            eventFlowPlusStatus: null,
            instructionsActive: false,
            checkRecordsInterval: null,
        };
    },
    computed: {
        ...mapState({
            currentDealer: state => state.dealer.currentDealer
        }),
        txt() {
            const txt = this.records.find(record => record.type === 'TXT');
            if (txt) { return txt }
            return '';
        },
        txtIsValid() {
            if (this.txt) { return false }
            return true;
        },
        cname() {
            const cname = this.records.find(record => record.type === 'CNAME');
            if (cname) {
                return cname;
            }
            return '';
        },
        setupCompleted() {
            if (this.cnameIsValid && !this.txt) { return true }
            return false;
        }
    },
    async mounted() {
        await this.setupEventFlowPlus();
        await this.checkStatusAndGetDNS();
    },
    activated() {
        this.checkRecordsInterval = setInterval(() => {
            if (!this.setupCompleted) {
                this.getDNSRecords();
            }
        }, 60000);
    },
    deactivated() {
        clearInterval(this.checkRecordsInterval);
    },
    methods: {
        async setupEventFlowPlus() {
            try {
                await this.$apiRepository.setupEventFlowPlus(this.currentDealer.id);
            } catch(e) {
                console.log(e);
            }
        },
        async checkStatusAndGetDNS() {
            await this.getFeatureStatus();
            if (this.eventFlowPlusStatus === 'incomplete' || this.eventFlowPlusStatus === 'complete') {
                this.getDNSRecords();
            } else {
                // repeat again after 2 sec until we get desired status
                setTimeout(() => {
                    this.checkStatusAndGetDNS();
                }, 2000);
            }
        },
        async getDNSRecords() {
            this.loading = true;
            try {
                const response = await this.$apiRepository.getDNSRecords(this.currentDealer.id);
                this.records = response.data.records;
                this.cnameIsValid = response.data.valid;
            } catch(e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async getFeatureStatus() {
            try {
                const response = await this.$apiRepository.getFeatureStatus(this.currentDealer.id, 'event_flow_plus');
                this.eventFlowPlusStatus = response.data.status;
            } catch(error) {
                console.log(error);
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.holder {
    display: flex;
    &.slim {
        flex-direction: column;
    }
}
.holder-title {
    margin-bottom: 10px;
    font-size: 26px;
}
.holder-left {
    width: 64%;
}
.holder-right {
    width: 36%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.slim .holder-left {
    width: 100%;
    margin-bottom: 25px;
}
.slim .holder-right {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 30px;
}
.styled-button {
    border-radius: 6px;
    border: 1px solid $blue-bg;
    padding: 6px 14px;
    text-transform: uppercase;
    color: $blue-bg;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    &:disabled {
        opacity: 0.4;
    }
}
.records {
    display: flex;
    justify-content: space-around;
    margin-bottom: 60px;
    &.slim {
        flex-direction: column;
        margin-bottom: 0;
        .record-wrapper {
            width: 100%;
            margin-bottom: 45px;
        }
    }
}
.record-wrapper {
    width: 45%;
}
</style>
<template>
    <span class="product-logo">
        <img
            v-if="src"
            :style="styles"
            :alt="name"
            :src="src">
        <span v-else-if="name">
            {{ name }}
        </span>
    </span>
</template>

<script>
import {
    PRODUCT_CATEGORY_MARKETPLACE,
    PRODUCT_CATEGORY_MARKETPLACE_PLUS,
    PRODUCT_CATEGORY_ADVERTISING_LITE,
    PRODUCT_CATEGORY_ADVERTISING,
    PRODUCT_CATEGORY_ADVERTISING_PLUS,
    PRODUCT_CATEGORY_REPORTING,
    PRODUCT_CATEGORY_MARKETPLACE_CPC,
    PRODUCT_CATEGORY_EVENT_FLOW_PLUS,
    PRODUCTS,
} from '@/helpers/globals';

export default {
    props: {
        value: {
            type: [String, Number],
            required: true
        },
        name: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'vertical'
        },
        height: {
            type: String,
            default: null
        }
    },
    computed: {
        styles() {
            if (this.height) {
                return {
                    height: this.height
                };
            }

            return {};
        },
        productDetails() {
            return PRODUCTS.find(product => (
                parseInt(this.value) === product.id
            ));
        },
        src() {
            const map = {
                [PRODUCT_CATEGORY_MARKETPLACE]: {
                    small: '/img/marketplace-small.png',
                    vertical: '/img/marketplace.png',
                    horizontal: '/img/marketplace-horizontal.png',
                },
                [PRODUCT_CATEGORY_MARKETPLACE_PLUS]: {
                    small: '/img/marketplace-plus-small.png',
                    vertical: '/img/marketplace-plus.png',
                    horizontal: '/img/marketplace-plus-horizontal.png',
                },
                [PRODUCT_CATEGORY_MARKETPLACE_CPC]: {
                    small: '/img/marketplace-cpc.svg',
                    vertical: '/img/marketplace-cpc.svg',
                    horizontal: '/img/marketplace-cpc-horizontal.svg',
                },
                [PRODUCT_CATEGORY_ADVERTISING_LITE]: {
                    small: '/img/avts-lite-small.png',
                    vertical: '/img/avts-lite.png',
                    horizontal: '/img/avts-lite-horizontal.png',
                },
                [PRODUCT_CATEGORY_ADVERTISING]: {
                    small: '/img/avts-small.png',
                    vertical: '/img/avts.png',
                    horizontal: '/img/avts-horizontal.png',
                },
                [PRODUCT_CATEGORY_ADVERTISING_PLUS]: {
                    small: '/img/avts-plus-small.png',
                    vertical: '/img/avts-plus.png',
                    horizontal: '/img/avts-plus-horizontal.png',
                },
                [PRODUCT_CATEGORY_REPORTING]: {
                    small: '/img/reporting-small.png',
                    vertical: '/img/reporting.png',
                    horizontal: '/img/reporting-horizontal.png',
                },
                [PRODUCT_CATEGORY_EVENT_FLOW_PLUS]: {
                    small: '/img/event-flow-plus.png',
                    vertical: '/img/event-flow-plus-vertical.png',
                    horizontal: '/img/event-flow-plus.png',
                },
            };

            return map[this.productDetails?.category]?.[this.type] ?? '';
        }
    }
};
</script>

<style lang="scss" scoped>
.product-logo {
    img {
        width: auto;
        vertical-align: top;
    }
}
</style>

<template>
    <styled-slideout
        :value="value"
        :nested.sync="submitRequestDialogOpen"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2>
                Request a New Feed Provider
            </h2>
            <p>
                Each new feed provider can require significant development
                resources depending on the type of data and how closely we need
                to work with the provider.  As a result we evaluate each request
                to determine feasibility and priority in the integration.
            </p>
            <p>
                <em>
                    Note that even when accepted, new provider requests can take
                    up to 2 weeks to complete.
                </em>
            </p>
            <p>
                Once your request is submitted here's what to expect:
            </p>
            <styled-list align-middle>
                <div class="mb-3">
                    Our team will evaluate the new provider to determine whether
                    supporting them is feasible and follow up within 48 hours
                    with next steps.  If we determine support isn't feasible we
                    will provide additional details around our analysis.
                </div>
                <div class="mb-3">
                    If we accept the new provider we will work directly with them
                    to coordinate the integration in line with
                    <a
                        href="https://support.buyerbridge.com/knowledge/inventory-setup-schema-details-for-new-providers"
                        target="_blank">
                        this article
                    </a>.
                    This process can take up to 2-weeks and sometimes requires
                    lots of communication between our team, yours and the provider's
                    so please be patient with us through the process! 😉
                </div>
                <div class="mb-3">
                    Once the integration is complete we will test the live data and
                    confirm once we believe everything is working correctly at which
                    point you will be able to review the new inventory in our dashboard
                    and complete the onboarding process!
                </div>
            </styled-list>
            <p class="mb-5">
                If you are comfortable with the steps above and are ready to submit a new request please click the button below to open a new request.
            </p>
            <div class="text-xs-center">
                <styled-button
                    outline
                    @click="submitRequestDialogOpen = true">
                    Submit Request
                </styled-button>
            </div>
        </div>
        <template #nested>
            <div class="pa-5">
                <hub-spot-form
                    form-id="255ecc03-c6e4-4430-8713-4f5aa249a230"
                    :form-submit-callback="handlePreSubmit"
                    :field-values="{
                        'TICKET.ticket___buyerbridge_id': currentDealer.id,
                        'TICKET.form___dealer_name': currentDealer.name,
                        'TICKET.agency_id': currentDealer.agency.id,
                        'TICKET.agency_name': currentDealer.agency.name,
                        'TICKET.ticket_contact_email': user.email,
                        'email': user.email
                    }" />
            </div>
        </template>
    </styled-slideout>
</template>

<script>
import HubSpotForm from './HubSpotForm';
import StyledList from './StyledList';
import StyledButton from './StyledButton';
import StyledSlideout from './StyledSlideout';
import { mapState } from 'vuex';

export default {
    components: {
        HubSpotForm,
        StyledList,
        StyledButton,
        StyledSlideout,
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            submitRequestDialogOpen: false,
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            user: (state) => state.user.user
        })
    },
    methods: {
        handlePreSubmit($form) {
            const $subjectField = $form.find('[name="TICKET.subject"]');
            const $providerField = $form.find('[name="TICKET.provider_name"]');
            const $contentField = $form.find('[name="TICKET.content"]');
            $contentField.val(`
            • User: ${this.user.name}
            • Organization: ${this.currentDealer.agency.name}
            • Account(s): ${this.currentDealer.name}
            • Account ID(s): ${this.currentDealer.id}
            • Notes:
            ${$contentField.val()}`);
            $subjectField.val(`New Feed Provider - ${$providerField.val()} - ${this.currentDealer.agency.name} - ${this.currentDealer.name} - Support`);
        }
    }
};
</script>

<template>
    <div>
        <styled-slideout
            :value="value"
            @input="$emit('input', $event)">
            <div class="pa-5">
                <div
                    v-if="currentStage == STAGE_DEFAULT"
                    class="text-xs-center">
                    <h2>Convert GTM Container to EventFlow 2.0</h2>
                    <p>
                        EventFlow 2.0 offers many new streamlined features such as improved lead tracking,
                        more consistent search events & better product associations with all event types.
                        Most importantly, EventFlow 2.0 is required for omni-channel. After converting you
                        will need to verify the installation.
                    </p>
                    <p class="mb-4">
                        Once the GTM container is converted to use EventFlow 2.0 you will need to take some
                        time to verify that the installation is still working correctly.
                    </p>
                    <styled-button
                        @click="convertToEventFlow">
                        UPDATE TO LATEST VERSION
                    </styled-button>
                </div>

                <!-- Processing Stage Start -->
                <div v-if="currentStage == STAGE_CONVERTING">
                    <h2>Converting GTM Container to EventFlow 2.0</h2>
                    <p>
                        Please wait a moment while we convert the GTM container to use the latest version of EventFlow (2.0).  This process can take up to 5 minutes so please bear with us!
                    </p>
                    <v-progress-linear
                        indeterminate
                        color="primary"
                        height="28"
                        class="mb-2" />

                    <div class="layout">
                        <div class="flex">
                            <strong>Process:</strong> {{ currentUpdate }}
                        </div>
                    </div>
                </div>
                <!-- Processing Stage End -->

                <!-- Verification Stage Start -->
                <div v-if="currentStage == STAGE_VALIDATION">
                    <h3>Verify Installation</h3>
                    <p>
                        Please follow the steps below to ensure the EventFlow
                        script was migrated correctly and the inventory was
                        updated correctly!
                    </p>

                    <verify-site-provider-installation
                        :dealer="dealer"
                        :change-site-provider-dialog-open="value" />
                </div>
                <!-- Verification Stage End -->
            </div>
        </styled-slideout>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import StyledSlideout from '@/components/globals/StyledSlideout';
import VerifySiteProviderInstallation from '@/components/globals/VerifySiteProviderInstallation';
import uuidv1 from 'uuid/v1';

const STAGE_DEFAULT = 'default';
const STAGE_CONVERTING = 'converting';
const STAGE_VALIDATION = 'validation';

export default {
    name: 'ConvertToEventFlowDialog',
    components: {
        VerifySiteProviderInstallation,
        StyledButton,
        StyledSlideout,
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        dealer: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            currentStage: STAGE_DEFAULT,
            currentUpdate: '',
            channelId: null,

            STAGE_DEFAULT,
            STAGE_CONVERTING,
            STAGE_VALIDATION
        };
    },
    watch: {
        value(newValue, oldValue) {
            // If we're transitioning to open and the form was previously
            // submitted reset it
            if (newValue === true
                && oldValue === false
                && this.complete === true) {
                this.complete = false;
            }
        }
    },
    methods: {
        async convertToEventFlow() {
            try {
                // Set to processing
                this.currentStage = STAGE_CONVERTING;

                this.channelId = uuidv1();

                this.$echo.channel('gtm-channel-'+this.channelId)
                    .listen('TagManagerStatusUpdated', (e) => {
                        this.currentUpdate = e.update;
                        if (e.update.includes('Finished setting up Eventflow')) {
                            this.$store.dispatch('updateCurrentDealer');
                            this.currentStage = STAGE_VALIDATION;
                        }
                        console.log(e);
                    });

                await this.$http.post(`dealers/${this.dealer.id}/gtm-to-eventflow`, {
                    channel_id: this.channelId,
                });

                this.$flash('Process engaged...', 'green');

            } catch(error) {
                console.log(error);
            } finally {
                console.log('');
            }
        }
    }
};
</script>
<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div
            v-if="vehicle"
            class="pa-5">
            <h2 class="mb-4">
                Vehicle Details
            </h2>

            <v-carousel
                v-if="images.length"
                class="vehicle-carousel mb-4"
                :interval="60000"
                height="300"
                hide-delimiters
                light>
                <v-carousel-item
                    v-for="url in images"
                    :key="url"
                    :src="url" />
            </v-carousel>

            <div class="layout wrap pa-4 pl-5">
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            VIN
                        </template>
                        {{ vehicle.vin }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            Stock No.
                        </template>
                        {{ vehicle.stock_no }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            Price
                        </template>
                        {{ vehicle.price | numFormat(numberFormats.currencyRound) }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            MSRP
                        </template>
                        {{ vehicle.msrp | numFormat(numberFormats.currencyRound) }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            Miles
                        </template>
                        {{ vehicle.miles | numFormat(numberFormats.number) }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            Carfax
                        </template>
                        {{ carfaxDetails || 'NA' }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            Interior Color
                        </template>
                        {{ vehicle.interior_color }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            Exterior Color
                        </template>
                        {{ vehicle.exterior_color }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            Body
                        </template>
                        {{ vehicle.vehicle_type }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            Condition
                        </template>
                        {{ vehicle.inventory_type | capitalize }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            Make
                        </template>
                        {{ vehicle.make }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            Model
                        </template>
                        {{ vehicle.model }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            Trim
                        </template>
                        {{ vehicle.trim }}
                    </data-field>
                </div>
                <div class="flex xs6 mb-4">
                    <data-field class="mx-4">
                        <template #label>
                            Year
                        </template>
                        {{ vehicle.year }}
                    </data-field>
                </div>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from './StyledSlideout';
import DataField from './DataField';
import getNumberFormats from '../../helpers/numberFormats';

export default {
    components: {
        DataField,
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        vehicle: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            numberFormats: getNumberFormats(),
        };
    },
    computed: {
        images() {
            return this.vehicle?.photo_links ?? [];
        },
        carfaxDetails() {
            const details = [];

            if (this.vehicle.carfax_1_owner) {
                details.push('1-Owner');
            }

            if (this.vehicle.carfax_clean_title) {
                details.push('Clean Title');
            }

            if (details.length) {
                return details.join(', ');
            }

            return null;
        }
    }
};
</script>

<style>
.vehicle-carousel .theme--light.v-icon {
    color: white;
}
</style>

<template>
    <div>
        <v-autocomplete
            v-model="selectedFeedProviderId"
            :items="feedProviders"
            item-text="name"
            item-value="id"
            :disabled="!!dealerFeedRdi"
            :rules="requiredRules"
            :loading="loading"
            class="styled-field mb-2"
            label="Select Feed Provider" />
        <a
            v-if="!feedRdiConfigured"
            href="#"
            @click.prevent="$emit('open-provider-dialog')">
            Click here if you don't see your provider
        </a>
    </div>
</template>

<script>
import { requiredRules } from '@/helpers/validationRules';

export default {
    props: {
        feedProviderId: {
            type: [String, Number],
            default: ''
        },
        feedProviders: {
            type: Array,
            default: () => []
        },
        dealerFeedRdi: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        },
        feedRdiConfigured: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            requiredRules,
            selectedFeedProviderId: ''
        };
    },
    watch: {
        feedProvider(value) {
            if (this.selectedFeedProviderId !== value) {
                this.selectedFeedProviderId = value;
            }
        },
        selectedFeedProviderId(value) {
            this.$emit('input', value);
        }
    },
    mounted() {
        this.selectedFeedProviderId = this.feedProviderId;
    }
};
</script>

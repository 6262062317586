<template>
    <div>
        <styled-card>
            <template #heading>
                Account Branding
            </template>
            <loader v-if="loading" />
            <div
                v-if="!loading"
                class="branding">
                <div class="branding__images">
                    <div class="branding__title">
                        Logos
                    </div>
                    <div class="branding__images-flex">
                        <div class="branding__image">
                            <asset-upload
                                :centered-text="true"
                                :centered-title="true"
                                :shortened="true"
                                :initial-image="primaryLogoUrl"
                                title="Primary"
                                :records="['dealer_id', 'agency_id']"
                                :types="['jpeg', 'jpg', 'png', 'gif']"
                                @asset-uploaded="onPrimaryAssetUploaded" />
                        </div>
                        <div class="branding__image">
                            <asset-upload
                                :centered-text="true"
                                :centered-title="true"
                                :shortened="true"
                                :darked="true"
                                :initial-image="darkBackgroundLogoUrl"
                                title="Dark Background"
                                :records="['dealer_id', 'agency_id']"
                                :types="['jpeg', 'jpg', 'png', 'gif']"
                                @asset-uploaded="onDarkBackgroundAssetUploaded" />
                        </div>
                    </div>
                </div>
                <div class="branding__colors">
                    <div class="branding__title">
                        Colors
                    </div>
                    <div class="branding__colors-flex">
                        <div
                            class="branding__color">
                            <editable-color-field
                                label="Primary Color"
                                :value="primaryColor"
                                :handler="setPrimaryColor" />
                            <action-button
                                v-if="primaryColor"
                                v-clipboard:copy="primaryColor"
                                v-clipboard:success="onCopy"
                                class="copy-btn"
                                size="17"
                                icon="copy" />
                        </div>
                        <div
                            class="branding__color">
                            <editable-color-field
                                label="Secondary Color"
                                :value="secondaryColor"
                                :handler="setSecondaryColor" />
                            <action-button
                                v-if="secondaryColor"
                                v-clipboard:copy="secondaryColor"
                                v-clipboard:success="onCopy"
                                class="copy-btn"
                                size="17"
                                icon="copy" />
                        </div>
                    </div>
                </div>
            </div>
        </styled-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ActionButton from '@/components/globals/ActionButton';
import StyledCard from '@/components/globals/StyledCard';
import AssetUpload from '@/components/globals/AssetUpload';
import Loader from '@/components/globals/Loader';
import EditableColorField from '@/components/globals/fields/EditableColorField';

export default {
    name: 'AccountBranding',
    components: {
        Loader,
        AssetUpload,
        StyledCard,
        ActionButton,
        EditableColorField
    },
    data() {
        return {
            accountBranding: null,
            primaryLogo: null,
            primaryLogoUrl: null,
            darkBackgroundLogo: null,
            darkBackgroundLogoUrl: null,
            primaryColor: null,
            secondaryColor: null,
            loading: true
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer
        })
    },
    async created() {
        this.updateDealerById(this.dealer.id);
        await this.waitForDealerLoaded();
        await this.getAccountBranding();
        this.loading = false;
    },
    methods: {
        ...mapActions([
            'updateDealerById',
            'waitForDealerLoaded'
        ]),
        onCopy() {
            this.$flash('Color copied to clipboard!', 'green');
        },
        async getAccountBranding() {
            this.accountBranding = this.dealer.account_branding;
            this.primaryColor = this.accountBranding?.configuration?.primary_color;
            this.secondaryColor = this.accountBranding?.configuration?.secondary_color;

            if (this.accountBranding && this.accountBranding.logos) {
                for (let index in this.accountBranding.logos) {
                    if (this.accountBranding.logos[index].type == 'primary') {
                        this.primaryLogo = this.accountBranding.logos[index];
                        this.primaryLogoUrl = this.primaryLogo.url;
                    } else if (this.accountBranding.logos[index].type == 'dark_background') {
                        this.darkBackgroundLogo = this.accountBranding.logos[index];
                        this.darkBackgroundLogoUrl = this.darkBackgroundLogo.url;
                    }
                }
            }
        },
        async setPrimaryColor(value) {
            let brandId;
            if (!this.accountBranding || this.accountBranding.length === 0) {
                brandId = await this.createBrand();
            }

            let configuration = this.accountBranding?.configuration ?? {};

            configuration.primary_color = value;

            await this.$http.put('/brands/' + (this.accountBranding?.brand_id == undefined ? brandId : this.accountBranding?.brand_id), {
                configuration
            });
            this.primaryColor = value;
            if (!this.accountBranding || this.accountBranding.length === 0) {
                this.accountBranding = {
                    brand_id: brandId,
                    configuration: {
                        primary_color: value
                    }
                };
            }
        },
        async setSecondaryColor(value) {
            let brandId;
            if (!this.accountBranding || this.accountBranding.length === 0) {
                brandId = await this.createBrand();
            }

            let configuration = this.accountBranding?.configuration ?? {};

            configuration.secondary_color = value;

            await this.$http.put('/brands/' + (this.accountBranding?.brand_id == undefined ? brandId : this.accountBranding?.brand_id), {
                configuration
            });
            this.secondaryColor = value;
            if (!this.accountBranding || this.accountBranding.length === 0) {
                this.accountBranding = {
                    brand_id: brandId,
                    configuration: {
                        secondary_color: value
                    }
                };
            }
        },
        async onPrimaryAssetUploaded(assetId) {
            let brandId;
            if (!this.accountBranding || this.accountBranding.length === 0) {
                brandId = await this.createBrand();
            }

            if (!this.primaryLogo) {
                await this.$http.post('/asset_brand', {
                    asset_id: assetId,
                    brand_id: this.accountBranding?.brand_id ?? brandId,
                    asset_brand_type_id: 1
                });
            } else {
                await this.$http.put('/asset_brand/' + this.primaryLogo.asset_brand_id, {
                    asset_id: assetId
                });
            }
            await this.$store.dispatch('updateDealerById', this.dealer.id);
            await this.getAccountBranding();
        },
        async onDarkBackgroundAssetUploaded(assetId) {
            let brandId;
            if (!this.accountBranding || this.accountBranding.length === 0) {
                brandId = await this.createBrand();
            }

            if (!this.darkBackgroundLogo) {
                await this.$http.post('/asset_brand', {
                    asset_id: assetId,
                    brand_id: this.accountBranding?.brand_id ?? brandId,
                    asset_brand_type_id: 2
                });
            } else {
                await this.$http.put('/asset_brand/' + this.darkBackgroundLogo.asset_brand_id, {
                    asset_id: assetId
                });
            }
            await this.$store.dispatch('updateDealerById', this.dealer.id);
            await this.getAccountBranding();
        },
        async createBrand() {
            let response = await this.$http.post('/brands', {
                dealer_id: this.dealer.id,
                name: this.dealer.name + ' Brand',
                public: true
            });

            await this.$http.post('/brand_dealer', {
                dealer_id: this.dealer.id,
                brand_id: response.data.data.id,
                account_branding: true
            });

            return response.data.data.id;
        }
    }
};
</script>

<style lang="scss" scoped>
.branding{
    display: flex;
    &__title{
        font-size: 16px;
        margin-bottom: 15px;
    }
    &__images{
        width: 50%;
        padding: 30px 0 30px 30px;
    }
    &__images-flex{
        display: flex;
        justify-content: space-between;
        border-right: 1px solid #DFDFDF;
        padding-right: 20%;
        padding-bottom: 15px;
    }
    &__image{
        width: 40%;
    }
    &__colors{
        width: 50%;
        padding-left: 30px;
        padding-top: 30px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    &__colors-flex{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: 45px;
    }
    &__color{
        display: flex;
        align-items: flex-end;
        width: 40%;
        .copy-btn{
            margin-bottom: 5px;
        }
    }
}
</style>
<template>
    <gmap-map
        :center="currentCenter"
        :zoom="zoom"
        :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: true
        }"
        :style="{ width: '100%', height: '100%', 'min-height': '200px' }"
        @click="$emit('input', getCoordinates($event))">
        <gmap-marker
            v-if="value"
            :position="value"
            :clickable="true"
            :draggable="true"
            @dragend="$emit('input', getCoordinates($event))" />
    </gmap-map>
</template>

<script>
import GmapMap from 'vue2-google-maps/src/components/map';
import GmapMarker from 'vue2-google-maps/src/components/marker';

export default {
    components: {
        GmapMap,
        GmapMarker
    },
    props: {
        value: {
            default: null,
            validator(coordinates) {
                return (coordinates.lat && !isNaN(coordinates.lat)) &&
                    (coordinates.lng && !isNaN(coordinates.lng));
            }
        },
        center: {
            default: null,
            validator(coordinates) {
                return (coordinates.lat && !isNaN(coordinates.lat)) &&
                    (coordinates.lng && !isNaN(coordinates.lng));
            }
        },
        updateCenter: {
            type: Boolean,
            default: false
        },
        zoom: {
            type: Number,
            default: 13
        }
    },
    data() {
        return  {
            currentCenter: null
        };
    },
    watch: {
        value() {
            if (this.updateCenter) {
                this.currentCenter = this.value;
            }
        },
        /**
         * Allow external users to update the center
         */
        center(coordinates) {
            if (!coordinates) {
                return;
            }
            this.currentCenter = coordinates;
        }
    },
    created() {
        const centerOfUS = {
            lat: 39.8283,
            lng: -98.5795
        };
        // Set center on map creation with fallback logic
        this.currentCenter = this.center || this.value || centerOfUS;
    },
    methods: {
        /**
         * Retrieves coordinates from the map events
         */
        getCoordinates(event) {
            return {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            };
        },

    }
};
</script>



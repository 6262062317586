<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <template v-if="complete">
            <div class="pa-5">
                <div class="mb-4">
                    <h3 class="primary--text font-weight-regular">
                        User Successfully Assigned!
                    </h3>
                    <p>
                        {{ selectedUser.name }} has been successfully assigned
                        to {{ dealer.name }}! You can close this dialog to
                        continue.
                    </p>
                </div>
                <div class="text-xs-center">
                    <v-btn
                        depressed
                        outline
                        color="primary"
                        class="text-none font-weight-bold"
                        @click="closeDialog">
                        Close
                    </v-btn>
                </div>
            </div>
        </template>
        <template v-else>
            <v-container
                fluid
                grid-list-md>
                <v-alert
                    :value="error"
                    type="error"
                    transition="fade-transition"
                    class="mt-2 mx-2">
                    An error occurred. Please try again or
                    <a @click="$intercom.show()">contact support</a>.
                </v-alert>
                <v-form
                    id="change-page-form"
                    ref="form"
                    v-model="valid"
                    class="px-3 py-1"
                    @submit.prevent="attachDealerUser">
                    <div
                        class="flex layout column align-center justify-end manage-user-wrap">
                        <button
                            type="button"
                            @click="
                                $router.push({
                                    name: 'users-all',
                                    params: { dealerId: dealer.id },
                                })
                            ">
                            <icon name="cog" />
                        </button>
                        <p class="manage-user-p">
                            MANAGE ALL USERS
                        </p>
                    </div>
                    <h2 class="mt-5 primary--text font-weight-regular">
                        Add User
                    </h2>
                    <p class="mb-5">
                        This tool will allow you to assign an existing user
                        account to {{ dealer.name }}. Select the user you want
                        to assign to this dealer below. This will allow them to
                        login to the dashboard and view this account.
                    </p>

                    <div class="mb-5 flex layout justify-space-around">
                        <v-btn
                            class="px-4 custom-user-btn"
                            :class="{ active: assignExistingUser }"
                            color="#00A2EA"
                            depressed
                            @click="toggleView('assign')">
                            ASSIGN AN EXISTING
                        </v-btn>
                        <v-btn
                            class="px-4 custom-user-btn"
                            :class="{ active: isANewUser }"
                            depressed
                            color="#00A2EA"
                            @click="toggleView('new')">
                            ADD NEW
                        </v-btn>
                    </div>
                    <h3
                        v-if="assignExistingUser || isANewUser"
                        class="mb-2">
                        New User
                    </h3>
                    <div v-if="assignExistingUser">
                        <v-autocomplete
                            v-model="selectedUser"
                            :rules="requiredRules"
                            :items="users"
                            item-text="name"
                            label="User Name"
                            class="styled-field"
                            :message="`Select a user to assign to ${dealer.name}`"
                            return-object />
                    </div>
                    <template v-if="isANewUser || addMoreUsers">
                        <add-user
                            :show-form="showForm"
                            @onAddUser="onAddNewUser" />
                    </template>

                    <div
                        v-if="assignExistingUser"
                        class="text-xs-center mb-5 mt-5">
                        <v-btn
                            type="submit"
                            form="change-page-form"
                            :loading="loading"
                            depressed
                            :style="{ color: 'white', 'border-radius': '4px' }"
                            :disabled="!selectedUser"
                            large
                            color="#00A2EA"
                            class="text-none font-weight-bold">
                            Add user
                        </v-btn>
                    </div>
                    <div
                        v-if="addMoreUsers"
                        class="flex layout column align-center add-more-user-container">
                        <v-btn
                            class="px-4 add-user-btn"
                            depressed
                            @click="toggleView('new')">
                            Add another new user
                        </v-btn>
                        <span
                            class="ma-3"
                            @click.prevent="$emit('input', false)">
                            Close
                        </span>
                    </div>
                </v-form>
            </v-container>
        </template>
    </styled-slideout>
</template>

<script>
import { mapState } from 'vuex';
import HTTP from '@/http';
import ApiRepository from '@/api-repository';
import { requiredRules } from '@/helpers/validationRules';
import StyledSlideout from '@/components/globals/StyledSlideout';
import Icon from '@/components/globals/Icon';
import AddUser from '@/components/globals/AddUser';

export default {
    name: 'AddUserPanel',
    components: {
        StyledSlideout,
        Icon,
        AddUser,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            valid: false,
            loading: false,
            complete: false,
            error: false,
            loadingUsers: false,
            users: [],
            selectedUser: null,
            assignExistingUser: false,
            isANewUser: false,
            addMoreUsers: false,
            showForm: true,
            requiredRules,
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer
        })
    },
    watch: {
        value(newValue, oldValue) {
            // If we're transitioning to open and the form was previously
            // submitted reset it
            if (
                newValue === true &&
                oldValue === false &&
                this.complete === true
            ) {
                this.complete = false;
            }
        },
    },
    created() {
        this.$apiRepository = new ApiRepository();
        this.getUsers();
    },
    methods: {
        async getUsers() {
            try {
                this.loadingUsers = true;
                const response = await this.$apiRepository.getUsers();
                this.users = response.data.data;
            } catch (error) {
                this.error = 'Error loading users';
                this.$emit('error', error);
                // eslint-disable-next-line no-console
                console.error(error);
            } finally {
                this.loadingUsers = false;
            }
        },
        async attachDealerUser() {
            this.$refs.form.validate();

            if (!this.valid) {
                return;
            }

            this.loading = true;
            this.error = false;

            try {
                const data = {
                    related: [
                        {
                            related_object_key: this.dealer.id,
                        },
                    ],
                };
                const response = await new HTTP().post(
                    `users/${this.selectedUser.id}/dealers/attach`,
                    data
                );
                this.complete = true;
                this.$emit('complete', response.data.data);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error changing page:', error);
                this.error = true;
                this.$emit('error', error);
            } finally {
                this.loading = false;
            }
        },
        toggleView(view) {
            if (!view) return;
            if (view === 'assign') {
                this.assignExistingUser = true;
                this.isANewUser = false;
                this.selectedUser = null;
            } else if (view === 'new') {
                this.assignExistingUser = false;
                this.isANewUser = true;
                this.addMoreUsers = false;
                this.showForm = true;
            }
        },
        onAddNewUser(value) {
            this.addMoreUsers = false;
            this.showForm = true;
            if (value) {
                this.isANewUser = false;
                this.addMoreUsers = true;
                this.showForm = false;
            }
            this.$emit('updated');
        },
        closeDialog() {
            this.$emit('input', false);
            this.reset();
        },
        reset() {
            this.assignExistingUser = false;
            this.isANewUser = false;
            this.addMoreUsers = false;
            this.selectedUser = {};
        },
    },
};
</script>
<style lang="scss" scoped>
.custom-user-btn {
    height: 30px;
    border-radius: 5px;
    color: #00a2ea;
    border: solid 1px #00a2ea;
    background-color: white !important;
    font-size: 14px;
    font-weight: 600;
    box-shadow: none;
    &.active {
        background-color: #00a2ea !important;
        color: white;
    }
}
.manage-user-wrap {
    float: right;
    .manage-user-p {
        font-size: 10px;
    }
}
.add-more-user-container {
    span {
        color: $blue-bg;
        font-size: 18px;
        &:hover {
            cursor: pointer;
        }
    }
    .add-user-btn {
        border-radius: 4px !important;
        background-color: $blue-bg !important;
        font-size: 20px;
        font-weight: 600;
        color: white;
        text-transform: none;
    }
}
</style>
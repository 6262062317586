var render = function render(){var _vm=this,_c=_vm._self._c;return _c('gmap-map',{style:({ width: '100%', height: '100%', 'min-height': '200px' }),attrs:{"center":_vm.currentCenter,"zoom":_vm.zoom,"options":{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true
    }},on:{"click":function($event){_vm.$emit('input', _vm.getCoordinates($event))}}},[(_vm.value)?_c('gmap-marker',{attrs:{"position":_vm.value,"clickable":true,"draggable":true},on:{"dragend":function($event){_vm.$emit('input', _vm.getCoordinates($event))}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
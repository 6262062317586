<template>
    <v-data-table
        :items="onboardingUserCampaignTree"
        :headers="headers"
        item-key="name"
        class="styled-table"
        hide-actions>
        <template #items="props">
            <tr
                :class="[
                    'campaign-row',
                    (props.index % 2) ? 'row-even' : 'row-odd',
                ]">
                <td class="pa-3">
                    <button @click="props.expanded = !props.expanded">
                        <icon
                            size="15"
                            :class="{
                                'expand-icon': true,
                                'is-open': props.expanded
                            }"
                            name="chevron-down" />
                    </button>
                </td>
                <td class="pa-3 text-xs-left">
                    {{ props.item.name }}
                </td>
                <td class="pa-3 text-xs-center">
                    <template v-if="props.item.ad_set_radius_max == props.item.ad_set_radius_min">
                        {{ props.item.ad_set_radius_max }}mi
                    </template>
                    <template v-else>
                        {{ props.item.ad_set_radius_min }}-{{ props.item.ad_set_radius_max }}mi
                    </template>
                </td>
                <td class="pa-3 text-xs-center">
                    {{ props.item.budget | numFormat(numberFormats.currency) }}
                </td>
                <td class="pa-3 text-xs-center">
                    {{ (props.item.budget * 30) | numFormat(numberFormats.currency) }}
                </td>
            </tr>
        </template>
        <template #expand="{ item }">
            <table class="expand-table">
                <tr
                    v-for="adSet in item.ad_sets"
                    :key="`${item.remote_name}_${adSet.remote_name}`">
                    <td width="50px" />
                    <td class="pl-5">
                        {{ adSet.name }}
                    </td>
                    <td
                        width="18%"
                        class="text-xs-center">
                        {{ adSet.radius }}mi
                    </td>
                    <td width="18%" />
                    <td width="18%" />
                </tr>
            </table>
            <table class="expand-table">
                <tr
                    v-for="adCreative in item.ad_creatives"
                    :key="`${item.remote_name}_${adCreative.remote_name}`">
                    <td width="50px" />
                    <td class="pl-5">
                        {{ adCreative.name }}
                    </td>
                    <td
                        width="18%"
                        class="text-xs-center">
                        {{ adCreative.landing_page_url }}
                    </td>
                    <td width="18%" />
                    <td width="18%" />
                </tr>
            </table>
        </template>
        <template #no-data>
            <div class="py-5 text-xs-center">
                Campaigns not yet configured
            </div>
        </template>
        <template #footer>
            <tr class="expand-table--footer">
                <th />
                <th />
                <th>Totals</th>
                <th>{{ dailyBudgetTotal | numFormat(numberFormats.currency) }}</th>
                <th>{{ monthlyBudgetTotal | numFormat(numberFormats.currency) }}</th>
            </tr>
        </template>
    </v-data-table>
</template>


<script>
import Icon from '../../globals/Icon';
import { mapGetters } from 'vuex';
import getNumberFormats from '../../../helpers/numberFormats';
import colors from '../../../helpers/colors';
import cloneDeep from 'lodash/cloneDeep';

export default {
    components: {
        Icon
    },
    data() {
        return {
            colors,
            emails: [],
            numberFormats: getNumberFormats(),
            headers: [{
                text: '',
                sortable: false,
                width: '50px'
            },{
                text: '',
                sortable: false
            },{
                text: 'Targeting',
                sortable: false,
                align: 'center',
                width: '18%'
            },{
                text: 'Daily Budget',
                sortable: false,
                align: 'center',
                width: '18%'
            },{
                text: 'Est. Monthly',
                sortable: false,
                align: 'center',
                width: '18%'
            }],
            expand: false
        };
    },
    computed: {
        ...mapGetters([
            'dailyBudgetTotal',
            'monthlyBudgetTotal',
            'onboardingUserCampaigns',
            'onboardingUserAdSets',
            'onboardingUserAdCreatives'
        ]),
        onboardingUserCampaignTree() {

            if (!this.onboardingUserAdSets.length || !this.onboardingUserCampaigns.length) {
                return [];
            }

            // Prime the adset array
            const campaigns = cloneDeep(this.onboardingUserCampaigns).map(campaign => {
                campaign.ad_sets = [];
                campaign.ad_creatives = [];
                return campaign;
            });
            // Copy the ad sets to alleviate mutation
            const adSets = cloneDeep(this.onboardingUserAdSets);

            adSets.forEach(adSet => {

                const campaign = campaigns.find(campaign => {
                    return campaign.remote_name === adSet.campaign_remote_name;
                });

                // Add the ad set to the correct campaign
                campaign.ad_sets.push(adSet);
            });

            const adCreatives = cloneDeep(this.onboardingUserAdCreatives);

            adCreatives.forEach(adCreative => {

                const campaign = campaigns.find(campaign => {
                    return campaign.remote_name === adCreative.campaign_remote_name;
                });

                // Add the ad set to the correct campaign
                campaign.ad_creatives.push(adCreative);
            });

            // Now compute underlying targeting aggregations
            campaigns.forEach(campaign => {
                const radii = campaign.ad_sets.map(adSet => adSet.radius);

                campaign.ad_set_radius_min = Math.min(...radii);
                campaign.ad_set_radius_max = Math.max(...radii);
            });

            return campaigns;
        }
    }
};
</script>


<style lang="scss" scoped>

.expand-icon {
    transition: transform 0.3s ease-in-out;
    &.is-open {
        transform: rotate(180deg);
    }
}

.expand-table {
    width: 100%;
    border-collapse: collapse;
    td {
        background-color: lighten($gray, 36%);
    }
    .expand-table--footer {
        color: $gray;
    }
}

.campaign-row {
    td:nth-child(2) {
        font-weight: 600;
    }
}
</style>


<template>
    <label
        class="styled-radio"
        :class="{
            'inline': inline,
            'dotted': dotStyle,
            'disabled': disabled
        }">
        {{ label }}
        <input
            type="radio"
            :disabled="disabled"
            :value="inputValue"
            :checked="inputValue === value"
            :name="name"
            @click="handleClick">
        <span class="styled-radio-checkmark" />
    </label>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Boolean],
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        inputValue: {
            type: [String, Boolean],
            required: true
        },
        inline: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        },
        dotStyle: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleClick() {
            if (this.value !== this.inputValue) {
                this.$emit('input', this.inputValue);
            } else {
                this.$emit('input', '');
            }
        }
    }
};
</script>

<style lang="scss" scoped>

.styled-radio {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    &:hover input ~ .styled-radio-checkmark {
        background-color: $alabaster-dark;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked {
            & ~ .styled-radio-checkmark {
                background-color: $blue-btn;
                border-color: $blue-btn;
                &::after {
                    display: block;
                }
            }
        }
    }
    .styled-radio-checkmark {
        position: relative;
        display: block;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 2px solid;
        border-color: $gray;
        background-color: $white;
        transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        &::after {
            content: '';
            position: absolute;
            display: none;
            left: 5px;
            top: 1px;
            width: 6px;
            height: 11px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }

    }
    &.inline {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 7px;
        .styled-radio-checkmark {
            margin-right: 8px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
.styled-radio.dotted input:checked {
    & ~ .styled-radio-checkmark:after {
        background: $white;
        border-radius: 50%;
        height: 100%;
        width: 100%;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }
    & ~ .styled-radio-checkmark:before {
        content: '';
        position: absolute;
        height: calc(100% - 6px);
        width: calc(100% - 6px);
        background: $blue-btn;
        border-radius: 50%;
        bottom: 3px;
        right: 3px;
        left: 3px;
        top: 3px;
        z-index: 2;
    }
}
</style>

<template>
    <div class="record">
        <div class="record-header">
            <div class="record-title">
                {{ title }} Record
            </div>
            <div class="record-status">
                <div class="record-status-title">
                    STATUS
                </div>
                <div
                    class="status-ball"
                    :class="{'valid': valid}" />
                <div v-if="!valid">
                    Not Detected
                </div>
                <div v-else>
                    Active
                </div>
            </div>
        </div>
        <div
            v-if="valid"
            class="record-valid">
            <icon
                name="check-circle"
                color="#7ED321"
                size="36" />
            <div class="record-success">
                {{ title }} Record has been successfully installed!
            </div>
        </div>
        <div
            v-else
            class="record-body">
            <div class="record-line">
                <div class="record-name">
                    TYPE
                </div>
                <div class="record-value">
                    {{ type }}
                </div>
            </div>
            <div class="record-line">
                <div class="record-name">
                    NAME
                </div>
                <div class="record-value">
                    {{ name }}
                    <action-button
                        v-clipboard:copy="name"
                        v-clipboard:success="onCopy"
                        size="17"
                        icon="copy" />
                </div>
            </div>
            <div class="record-line">
                <div class="record-name">
                    VALUE
                </div>
                <div class="record-value">
                    {{ value }}
                    <action-button
                        v-clipboard:copy="value"
                        v-clipboard:success="onCopy"
                        size="17"
                        icon="copy" />
                </div>
            </div>
            <div class="record-line">
                <div class="record-name">
                    TTL
                </div>
                <div class="record-value">
                    Auto / Default / Lowest number
                </div>
            </div>
        </div>
        <div
            v-if="record.type === 'CNAME'"
            class="record-sidenote">
            Important: You need to disable the "Proxied" option if you are on Cloudflare
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import ActionButton from '@/components/globals/ActionButton';

export default {
    components: {
        Icon,
        ActionButton
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        record: {
            type: [Object, String],
            default: () => {}
        },
        valid: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        name() {
            return this.record?.name ?? '';
        },
        value() {
            return this.record?.value ?? '';
        },
        type() {
            return this.record?.type ?? '';
        }
    },
    methods: {
        onCopy() {
            this.$flash('Copied to clipboard!', 'green');
        }
    }
};
</script>

<style lang="scss" scoped>
.record {
    background: #FAFBFC;
    border-radius: 6px;
    border: 1px solid #D8D8D8;
    position: relative;
}
.record-header {
    background: #F2F3F5;
    padding: 10px 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.record-title {
    font-size: 17px;
    color: #4B555B;
}
.record-status-title {
    font-size: 10px;
    color: #8F9EA6;
}
.record-status {
    display: flex;
    align-items: center;
    font-size: 12px;
}
.status-ball {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #F66B5C;
    margin-left: 8px;
    margin-right: 8px;
    &.valid {
        background: $success-500;
    }
}
.record-body {
    padding: 5px 25px 10px 25px;
}
.record-line {
    border-bottom: 1px solid #EFF0F1;
    padding: 12px 0;
    display: flex;
    &:last-of-type {
        border-bottom: none;
    }
}
.record-name {
    color: #8F9EA6;
    width: 35%;
}
.record-value {
    width: 65%;
}
.record-sidenote {
    font-style: italic;
    color: #4B555B;
    font-size: 13px;
    position: absolute;
    top: calc(100% + 10px);
    left: 10px;
    right: 0;
    text-align: left;
}
.record-valid {
    height: 206px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.record-success {
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #4B555B;
    text-align: center;
    max-width: 270px;
}
</style>
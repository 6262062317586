<template>
    <div>
        <website-inventory-vehicle-slideout
            v-if="currentVehicle"
            v-model="vehicleSlideoutOpen"
            :vehicle="currentVehicle" />
        <styled-table
            class="pb-5"
            :headers="headers"
            :items="inventory"
            :loading="loading"
            min-width="900px"
            hide-header
            hide-actions
            external-control
            hide-margins>
            <template #items="{ item, index }">
                <tr
                    :class="[
                        (index % 2) ? 'row-even' : 'row-odd',
                    ]">
                    <td class="pa-1">
                        <fade-in-image
                            :key="item.id"
                            class="ma-2 vin-img"
                            :src="getVehicleImage(item)"
                            placeholder="/img/car-placeholder.svg"
                            width="75px"
                            height="50px" />
                    </td>
                    <td class="pa-1">
                        {{ item.vin }}
                    </td>
                    <td class="pa-1">
                        {{ item.vehicle_type }}
                    </td>
                    <td class="pa-1">
                        {{ item.inventory_type | capitalize }}
                    </td>
                    <td class="pa-1">
                        {{ item.make }}
                    </td>
                    <td class="pa-1">
                        {{ item.model }}
                    </td>
                    <td class="pa-1">
                        {{ item.trim }}
                    </td>
                    <td class="pa-1">
                        {{ item.year }}
                    </td>
                    <td class="pa-1">
                        <span v-if="item.miles">
                            {{ item.miles | numFormat(numberFormats.number) }}
                        </span>
                        <span v-else>
                            -
                        </span>
                    </td>
                    <td class="pa-1">
                        <span v-if="item.price">
                            {{ item.price | numFormat(numberFormats.currencyRound) }}
                        </span>
                        <span v-else>
                            -
                        </span>
                    </td>
                    <td class="pa-1">
                        <a
                            :href="item.more_info"
                            class="mr-2"
                            target="_blank">
                            <span>VDP</span>
                        </a>
                        <a
                            href="#"
                            target="_blank"
                            @click.prevent="openVehicleSlideout(item)">
                            <span>Details</span>
                        </a>
                    </td>
                </tr>
            </template>
        </styled-table>
        <div class="layout justify-center">
            <div class="mr-auto" />
            <div>
                <styled-button
                    :disabled="!previousPage"
                    class="mr-4"
                    outline
                    small
                    @click="$emit('go-to-previous')">
                    <strong>PREVIOUS</strong>
                </styled-button>
                <styled-button
                    :disabled="!nextPage"
                    outline
                    small
                    @click="$emit('go-to-next')">
                    <strong>NEXT</strong>
                </styled-button>
            </div>
            <div class="ml-auto">
                <action-button
                    icon="download"
                    position="right"
                    :loading="loadingExport"
                    @click="exportInventory()">
                    EXPORT
                </action-button>
            </div>
        </div>
    </div>
</template>

<script>
import WebsiteInventoryVehicleSlideout from '@/components/globals/WebsiteInventoryVehicleSlideout';
import StyledTable from '@/components/globals/StyledTable';
import FadeInImage from '@/components/globals/FadeInImage';
import StyledButton from '@/components/globals/StyledButton';
import ActionButton from '@/components/globals/ActionButton';
import exportToCsv from '@/helpers/exportToCsv';
import getNumberFormats from '@/helpers/numberFormats';

export default {
    components: {
        WebsiteInventoryVehicleSlideout,
        StyledTable,
        FadeInImage,
        StyledButton,
        ActionButton
    },
    props: {
        inventory: {
            type: Array,
            required: true
        },
        rootDomain: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        previousPage: {
            type: Number,
            default: 0
        },
        nextPage: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            vehicleSlideoutOpen: false,
            currentVehicle: null,
            numberFormats: getNumberFormats(),
            loadingExport: false,
            headers: [
                {
                    text: '',
                    align: 'left',
                    width: '110px',
                    sortable: false
                },
                {
                    text: 'VIN',
                    align: 'left',
                    width: '160px',
                    sortable: false,
                    value: 'vin'
                },
                {
                    text: 'Body',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Condition',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Make',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Model',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Trim',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Year',
                    align: 'left',
                    sortable: false,
                    value: 'year'
                },
                {
                    text: 'Miles',
                    align: 'left',
                    sortable: false,
                    value: 'miles'
                },
                {
                    text: 'Price',
                    align: 'left',
                    sortable: false,
                    value: 'price'
                },
                {
                    text: '',
                    align: 'left',
                    width: '115px',
                    sortable: false,
                }
            ],
        };
    },
    methods: {
        openVehicleSlideout(vehicle) {
            this.currentVehicle = vehicle;
            this.vehicleSlideoutOpen = true;
        },
        getVehicleImage(vehicle) {
            return vehicle.photo_links?.[0] ?? null;
        },
        async exportInventory() {
            this.loadingExport = true;
            const inventory = await this.getAllWebsiteInventory();
            // Determine the maximum number of images found so we can build up the columns
            // otherwise the library we use doesn't know how to deal with variable columns
            // and sets them undefined
            const maxImages = Math.max.apply(Math, inventory.map(vehicle => {
                return vehicle?.photo_links?.length ?? [];
            }));
            const inventoryExport = inventory.map(vehicle => {
                const data = {
                    vin: vehicle.vin ?? '',
                    stock_number: vehicle.stock_no ?? '',
                    body: vehicle.body_type ?? '',
                    condition: vehicle.inventory_type ?? '',
                    make: vehicle.make ?? '',
                    model: vehicle.model ?? '',
                    trim: vehicle.trim ?? '',
                    transmission: vehicle.transmission ?? '',
                    exterior_color: vehicle.exterior_color ?? '',
                    interior_color: vehicle.interior_color ?? '',
                    year: vehicle.year ?? '',
                    miles: vehicle.miles ?? '',
                    price: vehicle.price ?? '',
                    vdp: vehicle.more_info ?? '',
                    first_seen: vehicle.first_seen_at ? new Date(vehicle.first_seen_at * 1000).toString() : ''
                };
                const images = vehicle?.photo_links ?? [];
                // Ensure all image columns are populated
                for (let i = 0; i < maxImages; i++) {
                    data['image_' + i] = images[i] ?? '';
                }
                return data;
            });
            exportToCsv(`buyerbridge_inventory_${this.rootDomain}_${new Date().getTime()}`, inventoryExport);
            this.loadingExport = false;
        },
        async getAllWebsiteInventory() {
            const size = 50;
            let allInventory = [];
            const getWebsiteInventoryPage = async(from) => {
                const data = {
                    website: this.rootDomain,
                    size,
                    from,
                };
                const response = await this.$apiRepository.getWebsiteInventory(data);
                const inventory = response.data?.hits?.hits?.map(hit => hit._source) ?? [];
                const totalVehicles = response.data?.hits?.total?.value ?? 0;

                allInventory = allInventory.concat(inventory);

                // If there's more listings past the current page call it again
                if (totalVehicles > (from + size)) {
                    await getWebsiteInventoryPage(from + size);
                }
            };
            await getWebsiteInventoryPage(0);
            return allInventory;
        },
    }
};
</script>

<template>
    <styled-card>
        <template #heading>
            Data Sources
        </template>
        <template #action-buttons>
            <div class="add-provider-buttons">
                <styled-tooltip
                    class="mr-3"
                    position="top"
                    :max-width="300">
                    <template #content>
                        <span v-if="feedWithProcessingStatus">
                            Inventory Changes cannot be made while processing is active.
                            Please check back in a few minutes once processing is complete to continue.
                        </span>
                        <span v-else-if="activeWebsiteInventory && feedWithAutoDetection">
                            Please complete ID assignment for the pending feed inventory to finalize the switch
                            from website inventory before adding additional feed data sources.
                        </span>
                        <span v-else-if="inactiveProviders.length">
                            This account has disabled data sources which must be removed before switching to feed inventory.
                            Please delete all inactive data sources to proceed.
                        </span>
                        <span v-else-if="activeWebsiteInventory">
                            Feed inventory will require you to work with {{ dealer.name }}'s
                            data provider to export inventory to BuyerBridge.
                            Your website inventory will remain active until you have successfully
                            entered a remote ID which will be given to you by the data provider.
                        </span>
                        <span v-else-if="activeFeedInventories.length">
                            Feed inventory will require you to work with {{ dealer.name }}'s
                            data provider to export inventory to BuyerBridge.
                            You can add multiple feed providers if you would like.
                            If you would like to change providers, add the new one and delete the original one once you have assigned
                            the ID in the new provider.
                        </span>
                        <span v-else>
                            Feed inventory will require you to work with {{ dealer.name }}'s
                            data provider to export inventory to BuyerBridge.
                            Feed inventory can sometimes be more reliable than the website inventory but can take while to obtain.
                            Use this option if you are concerned about scraping inventory from the website.
                        </span>
                    </template>
                    <button
                        class="add-provider-button"
                        :disabled="feedWithProcessingStatus || (activeWebsiteInventory && feedWithAutoDetection) || inactiveProviders.length > 0"
                        @click="addProvider('feed')">
                        <div class="icon-wrapper">
                            <div class="indicator-add">
                                <template v-if="activeWebsiteInventory">
                                    <icon
                                        color="#00A2EA"
                                        name="switch"
                                        size="12" />
                                </template>
                                <template v-else>
                                    <icon
                                        color="#00A2EA"
                                        name="indicator-add"
                                        size="12" />
                                </template>
                            </div>
                            <icon
                                color="gray"
                                name="data"
                                size="20" />
                        </div>
                        <span class="ml-2">
                            <span v-if="activeWebsiteInventory">Switch to</span>
                            <span v-else>Add</span>
                            Feed Inventory
                        </span>
                    </button>
                </styled-tooltip>
                <styled-tooltip
                    class="mr-3"
                    position="top"
                    :max-width="300">
                    <template #content>
                        <span v-if="!hasWebsiteInventory">
                            Website inventory is not available for {{ dealer.name }}.
                            If you believe you're seeing this message in error please contact support.
                            Or if you need to change the website you can do that on the “Account Settings” page.
                        </span>
                        <span v-else-if="feedWithProcessingStatus">
                            Inventory Changes cannot be made while processing is active.
                            Please check back in a few minutes once processing is complete to continue.
                        </span>
                        <span v-else-if="activeWebsiteInventory">
                            Website Inventory is already active for {{ dealerRootDomain }}.
                            If you would like to change to a different domain, delete the data source below,
                            change the website on the “Account Settings” page and come back to this screen to continue.
                        </span>
                        <span v-else-if="inactiveProviders.length">
                            This account has disabled data sources which must be removed before switching to feed inventory.
                            Please delete all inactive data sources to proceed.
                        </span>
                        <span v-else-if="activeFeedInventories.length">
                            Website Inventory provides the fastest way to get up and running quickly.
                            During the process you'll have the opportunity to review what our scraper has
                            indexed for {{ dealerRootDomain }} and you can decline it if you notice any issues.
                            Once you approve the website inventory it will immediately replace you feed inventory.
                        </span>
                        <span v-else>
                            Website Inventory provides the fastest way to get up and running quickly.
                            During the process you'll have the opportunity to review what our scraper
                            has indexed for {{ dealerRootDomain }} and you can decline it and try feed inventory if you notice any issues.
                            You can also start with website inventory then switch to feed inventory!
                        </span>
                    </template>
                    <button
                        class="add-provider-button feed-type"
                        :disabled="!hasWebsiteInventory || activeWebsiteInventory || feedWithProcessingStatus || inactiveProviders.length > 0"
                        @click="addProvider('website')">
                        <div class="icon-wrapper">
                            <div class="indicator-add">
                                <template v-if="activeFeedInventories.length">
                                    <icon
                                        color="#00A2EA"
                                        name="switch"
                                        size="12" />
                                </template>
                                <template v-else>
                                    <icon
                                        color="#00A2EA"
                                        name="indicator-add"
                                        size="12" />
                                </template>
                            </div>
                            <icon
                                color="gray"
                                name="browser"
                                size="23" />
                        </div>
                        <span class="ml-2">
                            <span v-if="activeFeedInventories.length">Switch to</span>
                            <span v-else>Add</span>
                            Website Inventory
                        </span>
                    </button>
                </styled-tooltip>
            </div>
            <action-button
                v-if="!refreshing"
                icon="refresh"
                class="ml-2"
                @click="refresh">
                Refresh
            </action-button>
            <action-button
                v-if="!refreshing"
                icon="cog"
                class="ml-2"
                :disabled="activeProviders.length == 0"
                @click="onProcessAll">
                Reprocess All
            </action-button>
        </template>
        <div
            ref="wrapper"
            class="vehicle-data-wrapper">
            <loader v-if="refreshing" />
            <div
                v-else
                id="data-sources-table">
                <v-data-table
                    :headers="headers"
                    :items="dataSources"
                    item-key="name"
                    hide-actions
                    no-data-text="No active data sources. Please select “Add Website Inventory” or “Add Feed Inventory” above."
                    :loading="loadingView || loadingDownload">
                    <template #items="props">
                        <tr>
                            <td>
                                {{ props.item.feed_type }}
                            </td>
                            <td>
                                {{ props.item.name }}
                            </td>
                            <td>
                                <span
                                    v-if="props.item.autoDetecting"
                                    class="status-wrapper">
                                    <span class="status-ball detecting" /> Detecting
                                </span>
                                <span
                                    v-else-if="props.item.rdi == 'Unassigned'"
                                    class="status-wrapper">
                                    <span class="status-ball waiting" /> Waiting for ID
                                </span>
                                <span
                                    v-else-if="props.item.isProcessing"
                                    class="status-wrapper">
                                    <span class="status-ball is-processing" /> Processing
                                </span>
                                <span
                                    v-else-if="props.item.isActive"
                                    class="status-wrapper">
                                    <span class="status-ball is-active" /> Active
                                </span>
                                <styled-tooltip
                                    v-else
                                    position="top"
                                    nudge-right="-20"
                                    :max-width="300">
                                    <template #content>
                                        This data source is inactive because it was delegated to the active data source
                                        by our support team
                                        in the earlier version of our inventory system. You can safely delete this data
                                        source if you'd like!
                                    </template>
                                    <span
                                        class="status-wrapper">
                                        <span class="status-ball inactive" /> Inactive
                                    </span>
                                </styled-tooltip>
                            </td>
                            <td v-if="props.item.rdi == 'Unassigned'">
                                <styled-tooltip
                                    position="top"
                                    nudge-top="10"
                                    :max-width="300">
                                    <template #content>
                                        <span v-if="feedWithProcessingStatus">
                                            Inventory Changes cannot be made while processing is active.
                                            Please check back in a few minutes once processing is complete to continue.
                                        </span>
                                        <span v-else>
                                            Click here if you know the provider's ID
                                        </span>
                                    </template>
                                    <button
                                        class="assign-id"
                                        :class="{'disabled': feedWithProcessingStatus}"
                                        :disabled="feedWithProcessingStatus"
                                        @click="assignId(props.item)">
                                        Assign ID
                                    </button>
                                </styled-tooltip>
                            </td>
                            <td
                                v-else
                                class="truncate">
                                <truncate-text
                                    :value="props.item.rdi"
                                    :characters="50" />
                            </td>
                            <td>
                                <span
                                    v-if="props.item.rdi == 'Unassigned'"
                                    class="empty"><span /></span>
                                <span v-else>{{ props.item.totalVehicles }}</span>
                            </td>
                            <td>
                                <span
                                    v-if="props.item.rdi == 'Unassigned'"
                                    class="empty"><span /></span>
                                <span v-else>{{ props.item.normalized }}</span>
                            </td>
                            <td>
                                <span
                                    v-if="props.item.rdi == 'Unassigned'"
                                    class="empty"><span /></span>
                                <span v-else>{{ props.item.vin_decode }}</span>
                            </td>
                            <td>
                                <span
                                    v-if="props.item.rdi == 'Unassigned'"
                                    class="empty"><span /></span>
                                <span v-else>{{ props.item.lastProcessed }}</span>
                            </td>
                            <td>
                                <div class="table-buttons">
                                    <action-button
                                        class="mx-2 text-xs-center"
                                        icon="cog"
                                        :disabled="props.item.rdi === 'Unassigned' || props.item.isProcessing || !props.item.isActive"
                                        @click="onProcessSingle(props.item)">
                                        Reprocess
                                    </action-button>
                                    <styled-tooltip
                                        v-if="props.item.actionEnabled"
                                        position="top"
                                        nudge-top="20"
                                        :max-width="250">
                                        <template #content>
                                            Please note that certain large data sources like Homenet &amp; vAuto can
                                            take up to a minute to process.
                                        </template>
                                        <action-button
                                            class="mx-2 text-xs-center"
                                            icon="download"
                                            :loading="loadingDownload || loadingView"
                                            @click="onDownload(props)">
                                            <span>Download</span>
                                        </action-button>
                                    </styled-tooltip>
                                    <styled-tooltip
                                        v-if="props.item.actionEnabled"
                                        position="top"
                                        nudge-top="20"
                                        :max-width="250">
                                        <template #content>
                                            Please note that certain large data sources like Homenet &amp; vAuto can
                                            take up to a minute to process.
                                        </template>
                                        <action-button
                                            class="mx-2 text-xs-center"
                                            icon="data"
                                            :loading="loadingDownload || loadingView"
                                            @click="onView(props)">
                                            View Data
                                        </action-button>
                                    </styled-tooltip>
                                    <action-button
                                        v-if="props.item.name !== 'BuyerBridge'"
                                        class="mx-2 text-xs-center"
                                        icon="mail"
                                        @click="resendEmail(props.item)">
                                        Resend
                                    </action-button>
                                    <action-button
                                        class="mx-2 text-xs-center"
                                        icon="trash"
                                        :disabled="deletingLoading || feedWithProcessingStatus"
                                        :loading="deletingLoading"
                                        @click="deleteProvider(props.item)">
                                        Delete
                                    </action-button>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </div>
        <resend-feed-provider
            v-model="resendFeedProviderActive"
            :feed-provider="currentFeedProvider"
            :dealer="dealer" />
        <processing-slideout
            ref="processingSlideout"
            v-model="processingSlideoutActive"
            :feed-provider="currentFeedProvider"
            :providers="activeProviders"
            :add-provider-flow-active="addProviderFlowActive"
            :check-for-id="checkForId"
            :check-provider="checkProvider"
            :active-type="activeType"
            :active-website-inventory="activeWebsiteInventory"
            :active-feed-inventories="activeFeedInventories"
            @refresh-inventory="refreshInventory"
            @processing-started="addProviderFlowActive = false"
            @check-provider="onCheckProvider"
            @rdi-is-missing="onRdiMissing"
            @set-provider="onSetProvider"
            @close="refresh" />
        <!-- modal table -->
        <div
            id="m-table"
            :class="{'is-active': !tableHidden}">
            <div class="m-table-wrapper">
                <div class="m-table-top">
                    <div class="m-table-title">
                        {{ tableProvider }} (ID: {{ tableProviderId }})
                    </div>
                    <div
                        class="m-table-close"
                        @click="closeModalTable">
                        <icon
                            color="#00A2EA"
                            name="close"
                            size="20px" />
                    </div>
                </div>
                <div
                    v-if="viewTable"
                    ref="tableContent"
                    class="m-table-content">
                    <div class="m-table-export">
                        <action-button
                            class="mx-2 text-xs-center"
                            icon="download"
                            :loading="loadingDownload"
                            @click="onDownload(currentProviderData)">
                            <span>Export CSV</span>
                        </action-button>
                    </div>
                    <custom-table
                        ref="dataTable"
                        :fields="tableHeaders"
                        :items="tableItems"
                        :hide-header="false"
                        :hide-search="false"
                        :hide-sort="true"
                        :slim-cells="true"
                        :rows-per-page="50"
                        :modal-view="true"
                        :header-margin="97"
                        name="data-table"
                        min-width="1200px" />
                </div>
            </div>
        </div>
        <!-- END of modal table -->
        <styled-dialog
            v-model="deleteConfirmationModal"
            :width="585">
            <h2 class="confirmation-title">
                Are you sure you want to delete {{ providerForDeleting.name }}?
            </h2>
            <p class="confirmation-desc">
                <template v-if="dataSources.length === 1">
                    Once you delete this Data Source we will automatically reprocess inventory
                    which will result in having no active inventory until you add a replacement Data Source.
                </template>
                <template v-else>
                    Once you delete this Data Source we will automatically reprocess inventory
                    which will remove any vehicles originally associated with it.
                </template>
            </p>
            <div class="text-xs-center mb-4">
                <styled-button
                    class="ml-3"
                    :disabled="deletingLoading"
                    :loading="deletingLoading"
                    @click="confirmDeleting">
                    YES, CONTINUE
                </styled-button>
            </div>
            <p class="confirmation-small">
                These updates may take up to 24 hours to reflect in ads & marketplaces
            </p>
            <a
                v-if="!deletingLoading"
                href="#"
                class="red--text"
                @click.prevent="deleteConfirmationModal = false">
                Cancel
            </a>
        </styled-dialog>
    </styled-card>
</template>

<script>
import Icon from '@/components/globals/Icon';
import Loader from '@/components/globals/Loader';
import TruncateText from '@/components/globals/TruncateText';
import StyledCard from '@/components/globals/StyledCard';
import ResendFeedProvider from './panels/ResendFeedProvider';
import ProcessingSlideout from './panels/ProcessingSlideout';
import ActionButton from '@/components/globals/ActionButton';
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import { WEBSITE_INVENTORY_API_SOURCE, WEBSITE_INVENTORY_SOURCES } from '@/helpers/globals';
import { getCurrentProcessInfo } from '@/helpers/inventory-dashboard/getCurrentProcessInfo';
import { url } from '@/helpers/regexRules';
import { sleep } from '@/helpers';
import * as CSV from 'csv-string';
import { mapState, mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';
import moment from 'moment';


export default {
    name: 'DataSources',
    components: {
        Icon,
        Loader,
        TruncateText,
        StyledCard,
        CustomTable,
        ActionButton,
        StyledTooltip,
        StyledDialog,
        StyledButton,
        ResendFeedProvider,
        ProcessingSlideout,
    },
    data() {
        return {
            headers: [
                {
                    text: 'Type',
                    width: '6%',
                    sortable: false
                },
                {
                    text: 'Provider',
                    width: '11%',
                    sortable: false
                },
                {
                    text: 'Status',
                    width: '11%',
                    sortable: false
                },
                {
                    text: 'Remote ID',
                    width: '11%',
                    sortable: false
                },
                {
                    text: 'Vehicles',
                    width: '8%',
                    sortable: false
                },
                {
                    text: 'Normalized',
                    width: '7%',
                    sortable: false
                },
                {
                    text: 'VIN Decoding',
                    width: '8%',
                    sortable: false
                },
                {
                    text: 'Last Retrieved',
                    width: '13%',
                    sortable: false
                },
                {
                    text: ' ',
                    width: '25%',
                    sortable: false
                },
            ],
            loadingView: false,
            loadingDownload: false,
            refreshing: true,
            rdiList: [],
            loadedRdis: [],
            resendFeedProviderActive: false,
            processingSlideoutActive: false,
            currentFeedProvider: {},
            currentProviderData: {},
            viewTable: false,
            tableHidden: true,
            tableHeaders: [],
            tableItems: [],
            tableProvider: '',
            tableProviderId: '',
            processes: [],
            addProviderFlowActive: false,
            checkForId: false,
            checkProvider: false,
            interval: null,
            deletingLoading: false,
            deleteConfirmationModal: false,
            hasWebsiteInventory: false,
            activeType: 'feed',
            providerForDeleting: {
                name: ''
            }
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer,
        }),
        ...mapGetters([
            'dealerRootDomain'
        ]),
        feedProviders() {
            // Get all data sources
            const dataSources = get(this.dealer, 'vehicle_data_sources.data', null);
            if (!dataSources) {
                return null;
            }
            // Filter out Marketcheck (website inventory)
            const feedProviders = dataSources.filter(dataSource => {
                return (dataSource.id !== 65);
            });
            if (!feedProviders.length) {
                return null;
            }
            return feedProviders;
        },
        dataSources() {
            const remoteIds = get(this.dealer, 'remote_dealer_identifiers.data', null) || [];
            // Retrieve active remote IDs
            const activeRemoteIds = this.getActiveRemoteIds(remoteIds);

            // Manipulate the return data
            return remoteIds.filter(remoteId => {
                // Filter out inactive website RDIs
                if (remoteId.vehicle_data_source_id === WEBSITE_INVENTORY_API_SOURCE) {
                    return !(remoteId.id_string === '-- temporary --' || remoteId.id_string === null || remoteId.id_string === '');
                }
                return true;
            }).map(remoteId => {
                const dataSource = remoteId.vehicle_data_source;

                // Set a friendly name for the feed provider
                let feedProviderName = 'Not Configured';
                if (dataSource) {
                    feedProviderName = (dataSource.id === 65) ? 'BuyerBridge' : dataSource.name;
                }

                const lastProcessedData = this.dealer.vehicle_data_last_processed;
                // Default last processed to pending and override if it exists
                let lastProcessed = 'Pending';
                if (lastProcessedData) {
                    // to do - remove moment
                    lastProcessed = moment(window.convertFromUtcToLocalTime(lastProcessedData.date)).format('MM/DD/YY @ h:mma');
                }
                const rdiItem = this.rdiList.find(item => item.id === remoteId.id);

                let httpRdi = null;
                if (url.test(remoteId.id_string)) {
                    httpRdi = remoteId.id_string;
                }

                const currentRdiProcess = getCurrentProcessInfo(this.processes, remoteId.id);

                let feedType = 'Feed';
                if (WEBSITE_INVENTORY_SOURCES.includes(remoteId.vehicle_data_source_id)) {
                    feedType = 'Website';
                }

                let detecting = false;
                if (remoteId.id_string === '-- temporary --' && remoteId.auto_detect_id_string) {
                    detecting = true;
                }

                return {
                    feed_type: feedType,
                    id: remoteId.vehicle_data_source_id,
                    isActive: activeRemoteIds.includes(remoteId.id),
                    isProcessing: currentRdiProcess.is_processing,
                    autoDetecting: detecting,
                    totalVehicles: currentRdiProcess.total_vehicles,
                    name: feedProviderName,
                    default_rdi_id: remoteId.vehicle_data_source.default_rdi_id,
                    rdi: (remoteId.id_string === '-- temporary --' || remoteId.id_string === null) ? 'Unassigned' : remoteId.id_string,
                    rdiId: remoteId.id,
                    normalized: rdiItem ? (rdiItem.skip_normalization ? 'No' : 'Yes') : '-',
                    vin_decode: rdiItem ? (rdiItem.skip_vin_decoding ? 'No' : 'Yes') : '-',
                    lastProcessed,
                    actionEnabled: (!!rdiItem && remoteId.id_string !== '-- temporary --') || !!httpRdi,
                    rdiItem,
                    httpRdi
                };
            });
        },
        activeProviders() {
            return this.dataSources.filter(provider => provider.isActive === true && provider.rdi !== 'Unassigned' && !provider.isProcessing);
        },
        inactiveProviders() {
            return this.dataSources.filter(provider => provider.isActive === false);
        },
        activeWebsiteInventory() {
            return this.activeProviders.find(provider => provider.feed_type === 'Website');
        },
        activeFeedInventories() {
            return this.activeProviders.filter(provider => provider.feed_type === 'Feed');
        },
        feedWithProcessingStatus() {
            const feedIsProcessing = this.dataSources.find(provider => provider.isProcessing);
            return feedIsProcessing ? true : false;
        },
        feedWithAutoDetection() {
            const feedIsDetecting = this.dataSources.find(provider => provider.autoDetecting);
            return feedIsDetecting ? true : false;
        }
    },
    watch: {
        processingSlideoutActive(value) {
            if (!value) {
                // clear any states if we close the slideout
                this.checkForId = false;
                this.checkProvider = false;
                this.addProviderFlowActive = false;
            }
        }
    },
    async created() {
        await this.checkWebsiteInventorySupport();
        await this.refresh();
    },
    deactivated() {
        clearInterval(this.interval);
    },
    methods: {
        ...mapActions([
            'updateCurrentDealer'
        ]),
        async checkWebsiteInventorySupport() {
            try {
                this.refreshing = true;
                const { dealer_id } = await this.$apiRepository.checkWebsiteInventorySupport(this.dealerRootDomain);
                this.hasWebsiteInventory = Boolean(dealer_id);
            } catch (error) {
                console.log(error);
            } finally {
                this.refreshing = false;
            }
        },
        async deleteProvider(provider) {
            this.deleteConfirmationModal = true;
            this.providerForDeleting = provider;
        },
        async confirmDeleting() {
            await this.deleteRdi(this.providerForDeleting.rdiId);
            this.deleteConfirmationModal = false;
            this.refreshInventory();
        },
        addProvider(type) {
            this.processingSlideoutActive = true;
            this.addProviderFlowActive = true;
            this.activeType = type;
        },
        refreshInventory() {
            this.$emit('refresh-inventory');
            this.refresh();
        },
        onProcessAll() {
            this.processingSlideoutActive = true;
            this.$refs.processingSlideout.initProcessing(true);
            clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.getInventoryProcesses();
            }, 5000);
        },
        onProcessSingle(provider) {
            this.currentFeedProvider = provider;
            this.processingSlideoutActive = true;
            this.$refs.processingSlideout.initProcessing();
            clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.getInventoryProcesses();
            }, 5000);
        },
        assignId(provider) {
            this.currentFeedProvider = provider;
            this.addProviderFlowActive = false;
            this.checkForId = true;
            this.processingSlideoutActive = true;
        },
        async refresh() {
            this.refreshing = true;
            this.addProviderFlowActive = false;
            this.currentFeedProvider = {},
                this.checkForId = false;
            this.checkProvider = false;
            await this.updateCurrentDealer();
            await this.getInventoryProcesses();
            await this.getRdiList();
            this.refreshing = false;
        },
        async getInventoryProcesses() {
            try {
                const response = await this.$apiRepository.getInventoryProcesses(this.dealer.id);
                this.processes = response.data;
            } catch (error) {
                console.error('Error retrieving inventory processes');
            }
        },
        async getRdiList() {
            this.refreshing = true;
            try {
                const response = await this.$apiRepository.getRDIList(this.dealer.id);
                if (response.data instanceof Object) {
                    this.rdiList = Object.keys(response.data).map(key => {
                        return response.data[key];
                    });
                } else {
                    this.rdiList = response.data;
                }
            } catch (error) {
                console.log('Error retrieving RDI list');
            } finally {
                this.refreshing = false;
            }
        },
        handleHeader() {
            // trigger this method if we are scrolling the table in the modal window
            this.$refs.dataTable.handleHeader();
        },
        resendEmail(provider) {
            this.currentFeedProvider = provider;
            this.resendFeedProviderActive = true;
        },
        onCheckProvider(provider) {
            this.currentFeedProvider = provider;
            this.checkForId = true;
            this.checkProvider = true;
        },
        onRdiMissing() {
            this.checkForId = false;
            this.checkProvider = false;
        },
        onSetProvider(provider) {
            this.currentFeedProvider = provider;
        },
        getActiveRemoteIds(remoteIds) {
            // If there's only 1 consider it active
            if (remoteIds.length === 1) {
                return [remoteIds[0].id];
            }

            let primaryRemoteId = null;

            // Loop through all remoteIds to find the primary rdi ID
            remoteIds.forEach(remoteId => {
                if (remoteId.primary_rdi_id) {
                    primaryRemoteId = remoteId.primary_rdi_id;
                }
            });

            // If there's no primary remote ID the logic below won't apply
            // and the system considers all RDIs active so return an array of their IDs
            if (!primaryRemoteId) {
                return remoteIds.map(remoteId => remoteId.id);
            }

            // Find the primary remote ID object
            const primaryRemoteIdObj = remoteIds.find(remoteId => {
                return (remoteId.id === primaryRemoteId);
            });

            // If the primary hasn't been delegated to the secondary it's the primary
            if (!primaryRemoteIdObj.delegate_to_secondary_rdi) {
                return [primaryRemoteIdObj.id];
            }

            // Get all RDIs that aren't the primary
            const secondaryRemoteIds = remoteIds.filter(remoteId => {
                return (remoteId.id !== primaryRemoteId);
            });

            // If there's secondary RDIs return an array of the IDs
            if (secondaryRemoteIds.length) {
                return secondaryRemoteIds.map(remoteId => remoteId.id);
            }

            // This will probably never happen but just in case!
            return [];
        },
        async onView(props) {
            this.tableProvider = props.item.feedProvider;
            this.tableProviderId = props.item.rdi;
            this.currentProviderData = props;
            // If it's an HTTP RDI just open it in a new window
            if (props.item.httpRdi) {
                window.open(props.item.httpRdi, '_blank');
                return;
            }
            if (this.loadingView) {
                return;
            }
            this.loadingView = true;
            await this.$nextTick();
            await sleep(10);
            const rdiId = props.item.rdiItem.id;
            try {
                let data = this.getLoadedRdi(rdiId);
                if (!data) {
                    const csvResponse = await this.$apiRepository.getCSVResponse(this.dealer.id, rdiId);
                    data = csvResponse.data;
                    this.loadedRdis.push({ rdiId, data });
                }
                if (data) {
                    const csvArr = CSV.parse(data);
                    // set structure of headers and data for custom table
                    const headers = csvArr.find((i, ind) => ind == 0);
                    const content = csvArr.filter((i, ind) => ind > 0);
                    const structuredHeaders = [];
                    headers.forEach(header => {
                        const currentHeader = {
                            id: header.toLowerCase(),
                            header,
                            value: header.toLowerCase(),
                            width: '180px',
                            type: 'truncate',
                        };
                        structuredHeaders.push(currentHeader);
                    });
                    const structuredData = [];
                    content.forEach(row => {
                        const rowObj = {};
                        row.forEach((r, i) => {
                            const valueName = structuredHeaders[i].value;
                            const rowValue = {
                                [valueName]: r
                            };
                            Object.assign(rowObj, rowValue);
                        });
                        structuredData.push(rowObj);
                    });
                    this.tableHeaders = structuredHeaders;
                    this.tableItems = structuredData;
                    this.viewTable = true;
                    this.$nextTick(function() {
                        const tableContent = this.$refs.tableContent;
                        tableContent.addEventListener('scroll', this.handleHeader);
                    });
                }
                this.$nextTick(function() {
                    this.loadingView = false;
                    this.tableHidden = false;
                });
            } catch (error) {
                console.log(error);
                this.loadingView = false;
            }
        },
        async closeModalTable() {
            this.tableHidden = true;
            // need this to properly remove event listener!
            await this.$nextTick();
            await sleep(10);
            const tableContent = this.$refs.tableContent;
            tableContent.removeEventListener('scroll', this.handleHeader);
            this.viewTable = false;
        },
        async onDownload(props) {
            // If it's an HTTP RDI just open it in a new window
            if (props.item.httpRdi) {
                window.open(props.item.httpRdi, '_blank');
                return;
            }
            if (this.loadingDownload) {
                return;
            }
            this.loadingDownload = true;
            const rdiId = props.item.rdiItem.id;
            const csvUrl = props.item.rdiItem.url;
            const filename = csvUrl.split('/').pop();
            if (!csvUrl.includes('.csv') || !filename.includes('.csv')) {
                return;
            }
            try {
                let data = this.getLoadedRdi(rdiId);
                if (!data) {
                    const csvResponse = await this.$apiRepository.getCSVResponse(this.dealer.id, rdiId);
                    data = csvResponse.data;
                    this.loadedRdis.push({ rdiId, data });
                }
                this.loadingDownload = false;
                props.expanded = false;
                if (data) {
                    this.$downloadFile(data, filename);
                }
            } catch (error) {
                console.log(error);
                this.loadingDownload = false;
            }
        },
        getLoadedRdi(rdiId) {
            const loadedRdi = this.loadedRdis.find(rdi => rdi.rdiId === rdiId);
            return loadedRdi ? loadedRdi.data : null;
        },
        async deleteRdi(rdiId) {
            this.deletingLoading = true;
            try {
                await this.$apiRepository.deleteRdi(this.dealer.id, rdiId);
            } catch (error) {
                console.log(error);
            } finally {
                this.deletingLoading = false;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.confirmation-title {
    margin-bottom: 15px;
    text-align: center;
}

.confirmation-small {
    font-size: 10px;
    font-style: italic;
    color: $gray;
    text-align: center;
}

.confirmation-desc {
    text-align: center;
    margin-bottom: 30px;
}

// modal table styles
#m-table {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3051;
    background-color: rgba(0, 0, 0, 0.45);

    &.is-active {
        display: block;
    }
}

.m-table-wrapper {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.1);
    padding-top: 27px;
}

.m-table-top {
    display: flex;
    justify-content: space-between;
    height: 30px;
    padding: 0 27px;
    position: relative;
}

.m-table-title {
    font-size: 18px;
    color: $blue-bg;
}

.m-table-close {
    cursor: pointer;
}

.m-table-export {
    margin-right: 50px;
    margin-top: -45px;
    text-align: right;
    transform: translateY(45px);
}

.m-table-content {
    width: 100%;
    height: calc(100% - 30px);
    overflow: auto;
}

// END of modal table styles
.status-wrapper {
    display: flex;
    align-items: center;
}

.status-ball {
    width: 12px;
    height: 12px;
    background-color: $gray;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;

    &.is-active {
        background: $success-500;
    }

    &.inactive {
        background: $error-500;
    }

    &.waiting {
        background: $orange-dark;
    }

    &.is-processing {
        background: #F5A622;
    }

    &.detecting {
        background: #F5A622;
        position: relative;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $success-500;
            transform: translateX(-50%);
        }
    }
}

.assign-id {
    border: 1px solid $blue-bg;
    border-radius: 5px;
    text-transform: uppercase;
    padding: 2px 15px;
    color: $blue-bg;
    font-weight: 600;
    cursor: pointer;
}

.assign-id.disabled {
    opacity: 0.4;
}

.empty {
    position: relative;

    span {
        position: absolute;
        width: 1px;
        height: 26px;
        background-color: #CFCFCF;
        top: -13px;
        left: 13px;
        transform: rotate(45deg);
    }
}

.add-provider-buttons {
    display: flex;
    align-items: center;
}

.add-provider-button {
    display: flex;
    align-items: center;
    font-size: 10px;
    text-transform: uppercase;
    background-color: #F8F8F8;
    padding: 5px 12px;
    border-radius: 4px;

    &:disabled {
        opacity: 0.4;
    }

    &.feed-type {
        padding: 4px 12px;
    }
}

.icon-wrapper {
    position: relative;
}

.indicator-add {
    position: absolute;
    top: -4px;
    right: -5px;
    width: 15px;
    height: 15px;
    background: #F8F8F8;
    border-radius: 50%;

    svg {
        vertical-align: top;
        margin-top: 1.5px;
    }
}
</style>

<style lang="scss">
// isolate styles only for current table
#data-sources-table {
    padding: 30px;

    table.v-table thead th {
        white-space: normal;
        padding: 10px 15px;

        &:first-of-type {
            padding-left: 25px;
        }

        &:last-of-type {
            padding-left: 5px;
        }
    }

    table.v-table tbody td {
        white-space: normal;
        padding: 10px 15px;
        color: #3F4649;

        &:first-of-type {
            padding-left: 25px;
        }

        &:last-of-type {
            padding-left: 5px;
        }
    }

    table tbody tr:nth-of-type(odd) {
        background-color: #F8F8F8;
    }

    .table-buttons {
        display: flex;
        justify-content: flex-end;
    }

    button > .loader {
        margin-bottom: -3px;
    }

    // no data text
    table.v-table tbody td[colspan="9"].text-xs-center {
        padding-top: 50px;
        padding-bottom: 50px;
        font-style: italic;
        color: #757575;
    }
}
</style>

<template>
    <styled-card
        show-more
        :value="false">
        <template #heading>
            Users
        </template>
        <template #action-buttons>
            <action-button
                icon="add-permission"
                @click="$emit('input', true)">
                Add User
            </action-button>
            <action-button
                icon="cog"
                @click="$router.push({ name: 'users-all', params: { dealerId: dealer.id } })">
                Manage
            </action-button>
        </template>
        <v-layout
            column
            fill-height
            justify-space-between>
            <v-flex class="py-4 px-5">
                <template v-if="dealer.users && dealer.users.data.length">
                    <v-layout
                        v-for="(user, index) in dealer.users.data"
                        :key="`${user.id}-${index}`">
                        <v-flex
                            shrink
                            class="pr-4 pb-4">
                            <v-avatar color="grey lighten-1">
                                <span
                                    v-if="user.name"
                                    class="white--text headline">
                                    {{ user.name | getInitials }}
                                </span>
                                <v-icon
                                    v-else
                                    dark>
                                    account_circle
                                </v-icon>
                            </v-avatar>
                        </v-flex>
                        <v-flex grow>
                            <data-field>
                                <template #label>
                                    {{ user.name }}
                                </template>
                                {{ user.email ? user.email : "No email" }}
                            </data-field>
                        </v-flex>
                    </v-layout>
                </template>
                <template v-else>
                    <p class="text-xs-center mt-4 mb-5">
                        No users configured
                    </p>
                </template>
            </v-flex>
        </v-layout>
    </styled-card>
</template>

<script>
import { mapState } from 'vuex';
import StyledCard from '@/components/globals/StyledCard';
import DataField from '@/components/globals/DataField';
import ActionButton from '@/components/globals/ActionButton';

export default {
    name: 'Users',
    filters: {
        getInitials(value) {
            const names = value.trim().split(/ /);
            return `${names[0].charAt(0)}${
                names[1] ? names[1].charAt(0) : ''
            }`.toUpperCase();
        },
    },
    components: {
        DataField,
        StyledCard,
        ActionButton,
    },
    data() {
        return {
            userDialogActive: false,
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer
        })
    }
};
</script>

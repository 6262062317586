<template>
    <div>
        <h3>Request for Inventory Export</h3>
        <p>
            Forward the approval received from the previous message
            to {{ feedProvider.name }} with the message below
        </p>
        <p>
            <styled-tooltip
                high-index
                position="top">
                <template #content>
                    <p>
                        Based on proven performance data, BuyerBridge
                        highly recommends using high-quality vehicle
                        photos <strong>without overlays</strong>.
                    </p>
                    <p class="pb-0">
                        It is important to note that most feed providers
                        have the ability to remove overlays but some
                        may not.
                    </p>
                </template>
                <v-checkbox
                    v-model="addOverlayRemovalContent"
                    color="primary"
                    label="Add overlay removal request (recommended)"
                    class="styled-checkbox" />
            </styled-tooltip>
        </p>
        <copy-text-area
            hide-download
            :email-to="!!feedProvider.support_email ? feedProvider.support_email : ''"
            :email-subject="`BuyerBridge Inventory Export Request: ${currentDealer.name}`"
            class="mb-4">
            <p>
                Hello {{ feedProvider.name }}!
            </p>
            <p>
                We're in the process of setting up our advertising system with
                {{ currentDealer.name }}. To finalize the process we'd like to
                export their inventory to our technology partner BuyerBridge.
            </p>
            <p>
                Please add the inventory for the dealership listed below to
                the existing feed for BuyerBridge and let us know what the
                dealer ID will be.
            </p>
            <p v-if="addOverlayRemovalContent">
                If your system has the ability to remove overlays
                (e.g. use "clean" photos) please remove them in
                this export.
            </p>
            <p>
                <em>
                    **If possible, please manually push an updated file with
                    this dealer's inventory added**
                </em>
            </p>
            <p>
                <strong>Dealer:</strong> {{ currentDealer.name }}<br>
                <strong>Website:</strong> {{ dealerHostname }}<br>
                <strong>Phone:</strong> {{ currentDealer.phone }}<br>
                <strong>Address:</strong> {{ dealerAddressString }}
            </p>
            <p>
                Our contact at {{ currentDealer.name }} has approved this change in the thread below.
            </p>
            <p>
                Thanks!<br>
                The {{ currentDealer.agency.name }} Team
            </p>
        </copy-text-area>
    </div>
</template>

<script>
import CopyTextArea from '@/components/globals/CopyTextArea';
import StyledTooltip from '@/components/globals/StyledTooltip';
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
        CopyTextArea,
        StyledTooltip
    },
    props: {
        feedProvider: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            addOverlayRemovalContent: true,
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters([
            'dealerAddressString',
            'dealerHostname'
        ])
    }
};
</script>

<template>
    <styled-slideout
        :value="value"
        :width="750"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2 class="mb-2">
                DNS Installation Instructions
            </h2>
            <p class="mb-4">
                The following instructions need to be sent to the administrator of the DNS host for {{ currentDealer.site_base_url }}.
                For reference, commonly used DNS hosts include CloudFlare, GoDaddy, Register.com & NameCheap.
                If the DNS administrator is unknown we suggest sending this request to {{ currentDealer.name }}'s IT support provider.
            </p>
            <copy-text-area
                hide-download
                custom-padding="35"
                :email-subject="`${currentDealer.name} request RE: DNS Installation`"
                class="mb-4">
                <p>Greetings!</p>
                <p>
                    We're in the process of configuring our advertising signals system that's designed to
                    work under {{ currentDealer.site_base_url }} in order to meet current and future privacy best practices.
                    In order to activate this system we need the following records added to the DNS host for {{ currentDealer.site_base_url }}:
                </p>
                <div v-if="txt">
                    <p>
                        <b>TXT Record</b>
                    </p>
                    Type: <b>{{ txt.type }}</b><br>
                    Name: <b>{{ txt.name }}</b><br>
                    Value: <b>{{ txt.value }}</b><br>
                    TTL: <b>Auto / Default / Lowest number</b>
                </div>
                <br>
                <div v-if="!cnameIsValid">
                    <p>
                        <b>CNAME Record</b>
                    </p>
                    Type: <b>{{ cname.type }}</b><br>
                    Name: <b>{{ cname.name }}</b><br>
                    Value: <b>{{ cname.value }}</b><br>
                    TTL: <b>Auto / Default / Lowest number</b>
                </div>
                <br>
                <p>
                    <i>Important Note: You need to disable the "Proxied" option if you are on CloudFlare!</i>
                </p>
                <p>
                    Thank you in advance for your assistance with these tasks, and please let me know if you have any questions about the updates!
                </p>
                <p>
                    Thanks!<br>
                    The {{ currentDealer.agency.name }} Team
                </p>
            </copy-text-area>
        </div>
    </styled-slideout>
</template>

<script>
import { mapState } from 'vuex';
import CopyTextArea from '@/components/globals/CopyTextArea';
import StyledSlideout from '@/components/globals/StyledSlideout';

export default {
    components: {
        CopyTextArea,
        StyledSlideout,
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        txt: {
            type: [Object, String],
            default: () => {}
        },
        cname: {
            type: [Object, String],
            default: () => {}
        },
        cnameIsValid: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            currentDealer: state => state.dealer.currentDealer
        })
    }
};
</script>
<template>
    <div>
        <loader v-if="loading" />
        <template v-else>
            <div class="layout">
                <v-text-field
                    ref="feedRdiStringField"
                    v-model="feedRdiString"
                    :label="`Enter ${feedRdiType.display_name}`"
                    :rules="feedRdiValidationRules"
                    class="styled-field mb-2 flex xs12 md6"
                    @keyup="updateFeedRdiStringStatus" />
            </div>
            <div class="mb-4">
                <a
                    v-if="!showRdiTypeSelection"
                    href="#"
                    @click.prevent="showRdiTypeSelection = true">
                    Using type: {{ feedRdiType.display_name }}
                </a>
                <styled-tooltip position="top">
                    <template #content>
                        Certain data sources support multiple ID types.
                        Only change this value if you know what you are doing!
                    </template>
                    <v-radio-group
                        v-if="showRdiTypeSelection"
                        v-model="feedRdiTypeIdValue"
                        class="styled-radio d-inline-block"
                        row>
                        <v-radio
                            v-for="type in feedRdiTypes"
                            :key="type.id"
                            :label="type.display_name"
                            :value="type.id"
                            color="primary" />
                    </v-radio-group>
                </styled-tooltip>
            </div>

            <styled-button
                :loading="feedRdiStringValidationLoading"
                :disabled="!feedRdiStringFieldIsValid"
                class="mb-4"
                outline
                @click="validateIdStringExists">
                Validate ID
            </styled-button>

            <styled-callout
                v-if="feedRdiStringStatus"
                class="layout align-center mb-3">
                <div class="flex shrink mr-4">
                    <status-icon
                        :value="feedRdiStringStatus == 'found' ? 'success' : 'warning'"
                        size="25" />
                </div>
                <div class="flex">
                    <template v-if="feedRdiStringStatus == 'found'">
                        <p v-if="feedRdiTypeId == 2">
                            The file was successfully found in {{ feedProvider.name }}'s feed directory.
                            We'll begin processing as soon as you click continue below.
                        </p>
                        <p v-else-if="feedRdiTypeId == 4">
                            We have successfully validated the provided URL!
                            We'll begin processing as soon as you click continue below.
                        </p>
                        <p v-else>
                            ID String Successfully Found in {{ feedProvider.name }}'s Feed!
                            We'll begin processing as soon as you click continue below.
                        </p>
                    </template>
                    <template v-else>
                        <h3>
                            {{ idStringValidationError }}
                        </h3>
                        <template v-if="feedRdiTypeId == 1 || feedRdiTypeId == 2">
                            <p>
                                This might just mean that it's not been updated yet as most
                                "{{ feedRdiType.display_name }}" providers only upload to us daily.
                                If you think you're seeing this in error you can override this and process
                                it anyways. Otherwise, please enter notification emails below and we will
                                email you once the inventory has been processed so you can complete onboarding.
                            </p>
                            <v-radio-group v-model="idStringNotFoundChoiceValue">
                                <v-radio
                                    value="notify"
                                    color="primary">
                                    <template #label>
                                        <span class="mr-1">Notify me when it's available and processed:</span>
                                        <notification-emails v-model="rdiNotificationEmails" />
                                    </template>
                                </v-radio>
                                <v-radio
                                    value="process"
                                    color="primary">
                                    <template #label>
                                        I'm certain it's there, process it anyways!
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </template>
                        <template v-else-if="feedRdiTypeId == 4">
                            <p>
                                If you think you're receiving this error incorrectly you
                                can override the detection and continue onboarding using the checkbox
                                below but note that you will likely run into issues.  Otherwise, please
                                check your input or contact support.
                            </p>
                            <v-checkbox
                                v-model="idStringNotFoundChoiceValue"
                                color="primary"
                                true-value="process"
                                :false-value="null"
                                label="I'm certain it's there, process it anyways!"
                                class="styled-checkbox" />
                        </template>
                    </template>
                </div>
            </styled-callout>
        </template>
    </div>
</template>

<script>
import NotificationEmails from '@/components/globals/NotificationEmails';
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledCallout from '@/components/globals/StyledCallout';
import StyledButton from '@/components/globals/StyledButton';
import StatusIcon from '@/components/globals/StatusIcon';
import Loader from '@/components/globals/Loader';
import { requiredRules, urlRules } from '@/helpers/validationRules';
import { WEBSITE_INVENTORY_SOURCE_TYPE } from '@/helpers/globals';
import { mapState } from 'vuex';

export default {
    components: {
        NotificationEmails,
        StyledTooltip,
        StyledCallout,
        StyledButton,
        StatusIcon,
        Loader,
    },
    props: {
        feedRdiTypeId: {
            type: [Number, String],
            default: 1
        },
        feedProvider: {
            type: Object,
            default: () => {}
        },
        idStringNotFoundChoice: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            loading: false,
            feedRdiString: '',
            feedRdiStringFieldIsValid: false,
            feedRdiTypes: [],
            showRdiTypeSelection: false,
            feedRdiStringValidationLoading: false,
            idStringValidationError: null,
            feedRdiStringStatus: null,
            rdiNotificationEmails: [],
        };
    },
    computed: {
        idStringNotFoundChoiceValue: {
            get() {
                return this.idStringNotFoundChoice;
            },
            set(value) {
                this.$emit('update:id-string-not-found-choice', value);
            }
        },
        feedRdiTypeIdValue: {
            get() {
                return this.feedRdiTypeId;
            },
            set(value) {
                this.$emit('update:feed-rdi-type-id', value);
            }
        },
        ...mapState({
            onboardingData: (state) => state.onboarding.data
        }),
        feedRdiType() {
            return this.feedRdiTypes.find(type => type.id === this.feedRdiTypeId) || {};
        },
        feedRdiValidationRules() {
            if (this.feedRdiTypeId === 4) {
                return [ ...requiredRules, ...urlRules ];
            }
            return requiredRules;
        },
        feedProviderId() {
            return this.feedProvider.id;
        }
    },
    watch: {
        feedRdiString(value) {
            this.feedRdiStringFieldIsValid = value.length ? this.$refs.feedRdiStringField.valid : false;
            this.$emit('set-rdi-string', value);
        },
        feedRdiStringStatus(value) {
            this.$emit('set-feed-rdi-string-status', value);
        },
        loading(value) {
            this.$emit('set-loading-state', value);
        },
        feedProviderId() {
            this.feedRdiStringStatus = null;
        },
        rdiNotificationEmails: {
            handler(value) {
                this.$emit('set-rdi-notification-emails', value);
            },
            deep: true
        },
    },
    created() {
        this.getRdiTypes();
    },
    methods: {
        updateFeedRdiStringStatus() {
            this.feedRdiStringFieldIsValid = this.feedRdiString.length ? this.$refs.feedRdiStringField.valid : false;
        },
        async getRdiTypes() {
            this.loading = true;
            try {
                const response = await this.$apiRepository.getRdiTypes();
                const types = response.data.data;
                this.feedRdiTypes = types.filter(type => type.id !== WEBSITE_INVENTORY_SOURCE_TYPE);
            } catch (error) {
                console.error('Error retrieving RDI types', error);
            } finally {
                this.loading = false;
            }
        },
        async validateIdStringExists() {
            this.idStringValidationError = null;
            this.feedRdiStringStatus = null;
            this.feedRdiStringValidationLoading = true;
            const data = {
                // To allow backwards compatibility the backend expects strings here :(
                id_string: this.feedRdiString,
                rdi_type: this.feedRdiTypeId,
            };
            try {
                const response = await this.$http.post(`feed_providers/${this.feedProviderId}/validate-id-string`, data);
                if (response.data.in_feed) {
                    this.feedRdiStringStatus = 'found';
                }
            } catch(error) {
                this.feedRdiStringStatus = 'not_found';
                if (error.response) {
                    this.idStringValidationError = error.response.data.error?.messages[0] || 'ID string could not be validated';
                } else {
                    this.idStringValidationError = 'ID string could not be validated';
                }
            } finally {
                this.feedRdiStringValidationLoading = false;
            }
        },
    }
};
</script>

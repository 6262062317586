<template>
    <styled-slideout
        :width="720"
        :value="value"
        :disable-background-click="true"
        @input="closeSlideout">
        <template v-if="addProviderFlowActive && !showProcessing">
            <add-provider
                :rdi-loading="rdiCreatingLoading"
                :active-type="activeType"
                :active-website-inventory="activeWebsiteInventory"
                :active-feed-inventories="activeFeedInventories"
                @close="confirmClosing"
                @start-processing="startProcessingOfAddedProvider"
                @rdi-known="onRdiKnown"
                @rdi-not-known="onRdiNotKnown"
                @set-emails="onSetEmails"
                @set-notification="onSetNotification"
                @save-without-id="saveWithoutID"
                @set-provider="onSetProvider" />
        </template>
        <process-inventory
            v-if="showProcessing"
            ref="processInventory"
            @start="processingIsActive = true; reviewIsActive = false"
            @end="processingIsActive = false; reviewIsActive = true" />
        <div
            v-if="checkForId && !showProcessing"
            class="pa-5"
            :class="{'check-provider': checkProvider}">
            <h2
                v-if="!checkProvider"
                class="mb-2">
                Assign {{ feedProvider.name }} ID
            </h2>
            <p
                v-if="!checkProvider"
                class="mb-3">
                Enter the ID you received from {{ feedProvider.name }} below to begin processing inventory
            </p>
            <assign-id
                :feed-provider="feedProvider"
                :feed-rdi-type-id.sync="feedRdiTypeId"
                :id-string-not-found-choice.sync="idStringNotFoundChoice"
                @set-rdi-string="setRdiString"
                @set-feed-rdi-string-status="setFeedRdiStringStatus"
                @set-rdi-notification-emails="setRdiNotificationEmails" />
            <button
                class="save-button"
                :disabled="!valid || rdiLoading"
                :class="{'disabled': !valid || rdiLoading}"
                @click="onStartProcessing">
                <span v-if="rdiLoading">Loading...</span>
                <span v-else>Save & Process Inventory</span>
            </button>
        </div>
        <styled-dialog v-model="showConfirmationModal">
            <template #heading>
                <h2>Are you sure you want to exit?</h2>
            </template>
            <p>
                Inventory processing will continue in the background and its status will
                be displayed in the "Data Sources" panel on the next screen but you
                will need to verify the inventory yourself once it's complete.
            </p>
            <template #actions>
                <styled-button
                    outline
                    class="ml-auto"
                    @click="showConfirmationModal = false">
                    Cancel
                </styled-button>
                <styled-button
                    class="ml-3"
                    @click="confirmClosing">
                    Continue & Exit
                </styled-button>
            </template>
        </styled-dialog>
    </styled-slideout>
</template>

<script>
import AssignId from '@/components/globals/AssignId';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import ProcessInventory from '@/components/globals/ProcessInventory';
import AddProvider from '@/components/globals/data-sources/AddProvider';
import { mapState } from 'vuex';

export default {
    components: {
        AssignId,
        StyledSlideout,
        StyledDialog,
        StyledButton,
        ProcessInventory,
        AddProvider,
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        checkForId: {
            type: Boolean,
            default: false
        },
        checkProvider: {
            type: Boolean,
            default: false
        },
        activeType: {
            type: String,
            default: 'feed'
        },
        activeWebsiteInventory: {
            type: Object,
            default: null
        },
        activeFeedInventories: {
            type: Array,
            default: () => []
        },
        addProviderFlowActive: {
            type: Boolean,
            default: false
        },
        feedProvider: {
            type: Object,
            default: () => {}
        },
        providers: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            feedRdiTypeId: 1,
            idStringNotFoundChoice: '',
            feedRdiString: '',
            feedRdiStringStatus: null,
            rdiNotificationEmails: [],
            rdiLoading: false,
            showProcessing: false,
            showConfirmationModal: false,
            processingIsActive: false,
            reviewIsActive: false,
            providersForReprocessing: [],
            createdRdi: null,
            autoDetection: false,
            autoDetectNotificationEmails: [],
            rdiCreatingLoading: false,
            sendAutoDetectNotification: true
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        }),
        valid() {
            // Consider the form valid if the user has defined a choice in
            // cases where the ID isn't found
            if (this.idStringNotFoundChoice) {
                return true;
            }
            // Don't consider the step valid until the RDI status has been retrieved
            // if the user has indicated that they know the RDI string
            if (this.feedRdiStringStatus !== 'found') {
                return false;
            }
            return true;
        }
    },
    watch: {
        feedProvider() {
            if (this.feedProvider.default_rdi_id) {
                this.feedRdiTypeId = this.feedProvider.default_rdi_id;
            }
        },
    },
    methods: {
        onRdiNotKnown() {
            this.$emit('rdi-is-missing');
        },
        onSetEmails(emails) {
            this.autoDetectNotificationEmails = emails;
        },
        onRdiKnown(provider) {
            this.$emit('check-provider', provider);
        },
        onSetProvider(provider) {
            this.$emit('set-provider', provider);
        },
        onSetNotification(value) {
            this.sendAutoDetectNotification = value;
        },
        closeSlideout(value) {
            if (!value && this.processingIsActive) {
                this.showConfirmationModal = true;
            } else if (!value && this.reviewIsActive) {
                this.refresh();
                this.$emit('refresh-inventory');
                this.$emit('input', value);
            } else {
                this.$emit('input', value);
            }
        },
        confirmClosing() {
            this.refresh();
            this.$emit('input', false);
            this.$emit('close');
            this.showConfirmationModal = false;
        },
        refresh() {
            this.idStringNotFoundChoice = '';
            this.feedRdiString = '';
            this.feedRdiStringStatus = null;
            this.rdiNotificationEmails = [];
            this.providersForReprocessing = [];
            this.showProcessing = false;
            this.reviewIsActive = false;
            this.autoDetection = false;
            this.sendAutoDetectNotification = true;
        },
        setRdiString(value) {
            this.feedRdiString = value;
        },
        setFeedRdiStringStatus(value) {
            this.feedRdiStringStatus = value;
        },
        setRdiNotificationEmails(value) {
            this.rdiNotificationEmails = value;
        },
        setAssignIdLoading(value) {
            this.assignIdLoading = value;
        },
        startProcessingOfAddedProvider(createdRdi) {
            this.$emit('processing-started');
            this.showProcessing = true;
            this.$nextTick(function() {
                const addedProvider = [{
                    rdiId: createdRdi.id,
                    name: createdRdi.id_string,
                    rdi: createdRdi.primary_rdi_id
                }];
                this.$refs.processInventory.startProcessing(addedProvider);
            });
        },
        async initProcessing(initAll = false) {
            this.showProcessing = true;
            this.$nextTick(function() {
                if (initAll) {
                    this.$refs.processInventory.startProcessing(this.providers);
                } else {
                    this.$refs.processInventory.startProcessing([this.feedProvider]);
                }
            });
        },
        async saveWithoutID(autoDetection) {
            if (autoDetection) { this.autoDetection = true }
            await this.createRdi();
            this.refresh();
            this.$emit('input', false);
            this.$emit('close');
        },
        async deleteWebsiteInventory(rdiId) {
            try {
                await this.$apiRepository.deleteRdi(this.currentDealer.id, rdiId);
            } catch(error) {
                console.log(error);
            }
        },
        async onStartProcessing() {
            if (this.rdiLoading) return; // prevent double clicking
            this.rdiLoading = true;
            if (this.activeWebsiteInventory) {
                await this.deleteWebsiteInventory(this.activeWebsiteInventory.rdiId);
            }
            // If there's no current Rdi create one
            if (!this.feedProvider.rdiId) {
                await this.createRdi();
                // If the RDI string was found or the user chose to override
                // the validation try to process it
                if (this.feedRdiStringStatus === 'found' || this.idStringNotFoundChoice === 'process') {
                    this.feedProvider.rdiId = this.createdRdi;
                    this.initProcessing();
                }
            }
            // Otherwise if there's not a current ID string and the user has entered one
            // activate that RDI
            else if ((!this.feedProvider.rdiItem.id_string.length || this.feedProvider.rdiItem.id_string == '-- temporary --') && this.feedRdiString.length) {
                await this.activateRdi();
                // If the RDI string was found or the user chose to override
                // the validation try to process it
                if (this.feedRdiStringStatus === 'found' || this.idStringNotFoundChoice === 'process') {
                    this.initProcessing();
                }
            }
            this.rdiLoading = false;
        },
        async createRdi() {
            this.rdiCreatingLoading = true;
            try {
                const data = {
                    feed_provider_id: this.feedProvider.id,
                    rdi_type: this.feedRdiTypeId,
                    id_string: this.feedRdiString,
                };
                if (this.idStringNotFoundChoice === 'notify') {
                    data.notification_email = this.rdiNotificationEmails;
                }
                if (this.autoDetection) {
                    data.auto_detect_id_string = true;
                }
                if (this.autoDetectNotificationEmails.length && this.sendAutoDetectNotification) {
                    data.notification_email = this.autoDetectNotificationEmails;
                }
                const response = await this.$apiRepository.createRdi(this.currentDealer.id, data);
                this.createdRdi = response.data.data.id;
            } catch(error) {
                console.log(error);
            } finally {
                this.rdiCreatingLoading = false;
            }
        },
        async activateRdi() {
            try {
                const data = {
                    id_string: this.feedRdiString
                };
                if (this.idStringNotFoundChoice === 'notify') {
                    data.notification_email = this.rdiNotificationEmails;
                }
                await this.$apiRepository.activateRdi(this.currentDealer.id, this.feedProvider.rdiId, data);
            } catch(error) {
                console.log(error);
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.save-button {
    background-color: $blue-bg;
    color: $white;
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 30px;
    margin: 0 auto;
    margin-top: 30px;
    display: table;
    &.disabled {
        opacity: 0.5;
    }
}
.check-provider {
    padding-top: 0;
    margin-top: -90px;
}
</style>
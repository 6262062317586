<template>
    <div>
        <configure-event-flow-dialog
            v-model="configureEventFlowDialogOpen"
            :dealer="dealer" />
        <change-site-provider-dialog
            v-model="changeSiteProviderDialogOpen"
            :dealer="dealer" />
        <convert-to-event-flow-dialog
            v-model="convertToEventFlowDialogOpen"
            :dealer="dealer" />
        <resend-site-provider
            v-if="dealer && dealer.site_provider && !userIsClient"
            v-model="resendSiteProviderActive"
            :site-provider="dealer.site_provider"
            :dealer="dealer" />
        <view-event-flow-code-dialog
            v-model="viewEventFlowCodeDialogActive"
            :dealer="dealer" />
        <dealer-product-update-dialog
            v-model="productDialogActive"
            :dealer="dealer"
            :product-ids="selectedProducts" />
        <dealer-dns-status-dialog
            v-model="dnsStatusDialogActive" />
        <dealer-tag-status-dialog
            v-model="tagStatusDialogActive"
            @show-instructions="showTagInstructions = true" />
        <tag-installation-instructions
            v-model="showTagInstructions"
            :site-provider="dealer.site_provider" />
        <privacy-settings-dialog
            v-model="privacyDialogActive" />

        <styled-card>
            <template #heading>
                EventFlow
            </template>
            <template
                v-if="!loading"
                #action-buttons>
                <action-button
                    v-if="hasEventFlowPlus"
                    icon="handshake"
                    @click="privacyDialogActive = true">
                    Privacy Settings
                </action-button>
                <action-button
                    v-if="dealerInAutoVertical"
                    icon="data-remap"
                    @click="changeSiteProviderDialogOpen = true">
                    Change Provider
                </action-button>
                <action-button
                    icon="code"
                    @click="hasEventFlowPlus ? tagStatusDialogActive = true : viewEventFlowCodeDialogActive = true">
                    View Script
                </action-button>
                <action-button
                    v-if="dealerInAutoVertical"
                    icon="mail"
                    @click="hasEventFlowPlus ? showTagInstructions = true: resendSiteProviderActive = true">
                    Resend
                </action-button>
                <action-button
                    v-if="$userHasRole(['super_admin', 'admin']) && dealerInAutoVertical"
                    icon="cog"
                    @click="configureEventFlowDialogOpen = true">
                    Configure
                </action-button>
            </template>
            <loader v-if="loading && hasEventFlowPlus" />
            <div
                v-else
                class="layout wrap pa-5 align-center">
                <div class="shrink layout align-center column mr-5 px-3">
                    <img
                        v-if="hasEventFlowPlus"
                        class="mb-3"
                        src="/img/event-flow-plus-vertical.png"
                        style="width: 150px;">
                    <img
                        v-else
                        class="mb-4"
                        src="/img/product_eventflow_logo.svg"
                        style="width: 80px;">
                    <styled-button
                        v-if="needsEventFlowPlusUpgrade && userIsAdmin"
                        small
                        @click="upgradeToEventPlus">
                        UPGRADE
                    </styled-button>
                    <a
                        v-else-if="hasEventFlowPlus"
                        href="https://s3.amazonaws.com/assets.buyerbridge.com/BuyerBridge-EventFlow-One-Pager.pdf"
                        target="_blank">
                        Learn More
                    </a>
                    <a
                        v-else
                        href="https://support.buyerbridge.io/knowledge/what-is-buyerbridge-eventflow"
                        target="_blank">
                        Learn More
                    </a>
                </div>
                <div
                    v-if="needsEventFlowUpgrade && !hasEventFlowPlus"
                    class="flex xs12 md3">
                    <p>
                        To utilize the latest BuyerBridge features across
                        multiple channels you will need to update EventFlow
                        to the latest version!
                    </p>
                    <styled-button
                        @click="convertToEventFlowDialogOpen = true">
                        Update to Latest Version
                    </styled-button>
                </div>
                <div
                    v-else-if="needsEventFlowPlusUpgrade && userIsAdmin"
                    class="flex xs12 md3 mr-4">
                    <p>
                        Did you know your ads could be performing better?
                        Protect your ads from privacy changes with our first-party
                        tracking solution EventFlowPLUS!
                    </p>
                    <a
                        href="https://support.buyerbridge.io/knowledge/what-is-buyerbridge-eventflow"
                        target="_blank">
                        Learn More
                    </a>
                </div>
                <template v-if="hasEventFlowPlus">
                    <div
                        class="flex">
                        <div>
                            <div class="status-title">
                                DNS Status
                            </div>
                            <styled-tooltip
                                v-if="dnsIsNotAvailable"
                                :max-width="300"
                                nudge-right="-35"
                                position="top">
                                <template #content>
                                    Please proceed with onboarding to have your DNS instructions created
                                </template>
                                <div class="status-holder">
                                    <div class="status-ball" />
                                    <div class="status-action">
                                        <a
                                            href="#"
                                            class="mr-2"
                                            @click.prevent>Missing</a>
                                    </div>
                                </div>
                            </styled-tooltip>
                            <div
                                v-else
                                class="status-holder">
                                <div
                                    class="status-ball"
                                    :class="{'valid': dnsStatus === 'Configured'}" />
                                <div v-if="dnsStatus === 'Configured'">
                                    Configured
                                </div>
                                <div
                                    v-else
                                    class="status-action"
                                    @click.prevent="dnsStatusDialogActive = true">
                                    <a
                                        href="#"
                                        class="mr-2">{{ dnsStatus }}</a>
                                    <icon
                                        name="cog"
                                        size="15" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex">
                        <div>
                            <div class="status-title">
                                Tag Status
                            </div>
                            <div class="status-holder">
                                <div
                                    class="status-ball"
                                    :class="{'valid': tagStatus === 'completed'}" />
                                <div
                                    class="status-action"
                                    @click.prevent="tagStatusDialogActive = true">
                                    <a
                                        href="#"
                                        class="mr-2">
                                        <span v-if="tagStatus === 'completed'">Installed</span>
                                        <span v-else>Not Detected</span>
                                    </a>
                                    <icon
                                        name="cog"
                                        size="15" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex">
                        <div>
                            <div class="status-title">
                                Health
                            </div>
                            <styled-tooltip
                                v-if="tagStatus !== 'completed' || dnsStatus !== 'Configured'"
                                :max-width="300"
                                nudge-right="-35"
                                position="top">
                                <template #content>
                                    There is an issue with the DNS records and/or tag installation.
                                    Please check the respective fields for more details.
                                </template>
                                <div class="status-holder">
                                    <div class="status-ball" />
                                    <div class="status-action">
                                        <a
                                            href="#"
                                            class="mr-2"
                                            @click.prevent>Setup Issues</a>
                                        <icon
                                            class="status-indicator"
                                            name="indicator-info"
                                            size="15" />
                                    </div>
                                </div>
                            </styled-tooltip>
                            <div
                                v-else
                                class="status-holder">
                                <div
                                    class="status-ball"
                                    :class="{'valid': eventFlowAlerts.length == 0}" />
                                <div class="status-action">
                                    <a
                                        v-if="eventFlowAlerts.length"
                                        href="#"
                                        class="mr-2"
                                        @click.prevent="showAlerts">
                                        {{ eventFlowAlerts.length }} Active Alert<span v-if="eventFlowAlerts.length > 1">s</span>
                                    </a>
                                    <div v-else>
                                        No Issues
                                    </div>
                                    <icon
                                        v-if="eventFlowAlerts.length"
                                        name="outbound-link"
                                        size="15" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div
                    v-if="dealer.site_provider && dealerInAutoVertical"
                    class="flex">
                    <data-field>
                        <template #label>
                            Site Provider
                        </template>
                        {{ dealer.site_provider.name }}
                    </data-field>
                </div>
                <div
                    v-if="dealer.site_base_url"
                    class="flex">
                    <data-field>
                        <template #label>
                            Website
                        </template>
                        <a
                            :href="dealer.site_base_url"
                            target="_blank">
                            {{ website }}
                        </a>
                    </data-field>
                </div>
                <div
                    v-if="dealer.tag_manager_container_id"
                    class="flex">
                    <data-field>
                        <template #label>
                            GTM Container
                        </template>
                        <div class="layout row align-center">
                            <span>{{ dealer.tag_manager_container_id }}</span>
                            <v-btn
                                class="ml-2 download-button"
                                text
                                icon
                                small
                                @click="onGtmDownloadClick">
                                <v-icon color="primary">
                                    download
                                </v-icon>
                            </v-btn>
                            <copy-text
                                class="closer-to-right"
                                show-icon-only
                                :value="dealer.tag_manager_container_id" />
                        </div>
                    </data-field>
                </div>
                <div
                    v-if="dealer.ua_code"
                    class="flex">
                    <data-field>
                        <template #label>
                            GA Property
                        </template>
                        <div class="layout row align-center">
                            <span>{{ dealer.ua_code }}</span>
                            <copy-text
                                show-icon-only
                                :value="dealer.ua_code" />
                        </div>
                    </data-field>
                </div>
            </div>
        </styled-card>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import ConfigureEventFlowDialog from '@/components/globals/ConfigureEventFlowDialog';
import ChangeSiteProviderDialog from '@/components/globals/ChangeSiteProviderDialog';
import DataField from '@/components/globals/DataField';
import CopyText from '@/components/globals/CopyText';
import ResendSiteProvider from './panels/ResendSiteProvider';
import { downloadGtmCode } from '@/helpers/gtmCode';
import ViewEventFlowCodeDialog from '@/components/globals/ViewEventFlowCodeDialog';
import StyledButton from '@/components/globals/StyledButton';
import ConvertToEventFlowDialog from '@/components/globals/ConvertToEventFlowDialog';
import {
    PRODUCT_EVENT_FLOW_PLUS,
    FEATURE_EVENT_FLOW_PLUS,
    FEATURE_EVENT_FLOW_PLUS_TAG,
    FEATURE_EVENT_FLOW_PLUS_LEGAL,
    FEATURE_EVENT_FLOW_PLUS_DATA_SHARING
} from '@/helpers/globals';
import TagInstallationInstructions from '@/components/dealers/settings/panels/TagInstallationInstructions';
import DealerProductUpdateDialog from '@/components/globals/DealerProductUpdateDialog';
import DealerDnsStatusDialog from '@/components/globals/DealerDnsStatusDialog';
import DealerTagStatusDialog from '@/components/globals/DealerTagStatusDialog';
import PrivacySettingsDialog from '@/components/globals/PrivacySettingsDialog';
import StyledTooltip from '@/components/globals/StyledTooltip.vue';
import Loader from '@/components/globals/Loader';
import Icon from '@/components/globals/Icon';

export default {
    name: 'EventFlow',
    components: {
        Icon,
        Loader,
        StyledTooltip,
        DealerDnsStatusDialog,
        DealerTagStatusDialog,
        PrivacySettingsDialog,
        DealerProductUpdateDialog,
        TagInstallationInstructions,
        ConvertToEventFlowDialog,
        StyledButton,
        ViewEventFlowCodeDialog,
        ConfigureEventFlowDialog,
        ChangeSiteProviderDialog,
        ActionButton,
        StyledCard,
        DataField,
        CopyText,
        ResendSiteProvider,
    },
    data() {
        return {
            changeSiteProviderDialogOpen: false,
            configureEventFlowDialogOpen: false,
            resendSiteProviderActive: false,
            viewEventFlowCodeDialogActive: false,
            codeDialogOpen: false,
            convertToEventFlowDialogOpen: false,
            dnsStatusDialogActive: false,
            tagStatusDialogActive: false,
            productDialogActive: false,
            privacyDialogActive: false,
            showTagInstructions: false,
            selectedProducts: [],
            cnameIsValid: false,
            loading: false,
            sharingStatus: '',
            legalStatus: '',
            tagStatus: '',
            records: [],
            alerts: [],
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer
        }),
        ...mapGetters([
            'userIsAdmin',
            'userIsClient',
            'dealerUsingEventFlow',
            'dealerHasFeature',
            'dealerInAutoVertical'
        ]),
        needsEventFlowUpgrade() {
            return !this.dealer.uses_event_flow;
        },
        needsEventFlowPlusUpgrade() {
            return !this.dealerHasFeature(FEATURE_EVENT_FLOW_PLUS);
        },
        hasEventFlowPlus() {
            return this.dealerHasFeature(FEATURE_EVENT_FLOW_PLUS);
        },
        dnsIsNotAvailable() {
            if (this.legalStatus === 'complete' && this.sharingStatus === 'complete') {
                return false;
            }
            return true;
        },
        txt() {
            const txt = this.records.find(record => record.type === 'TXT');
            if (txt) { return txt }
            return '';
        },
        dnsStatus() {
            if (this.txt && !this.cnameIsValid) { return 'Both Records Missing'}
            else if (this.txt) { return 'TXT Record Missing' }
            else if (!this.cnameIsValid) { return 'CNAME Record Missing'}
            return 'Configured';
        },
        eventFlowAlerts() {
            const eventFlowAlerts = [
                'pixel_missing_events',
                'pixel_under_performing',
                'pixel_missing_all_events'
            ];
            return this.alerts.filter(alert => eventFlowAlerts.includes(alert.alert_type.name));
        },
        website() {
            if (!this.dealer.site_base_url) {
                return null;
            }
            const url = new URL(this.dealer.site_base_url);
            return url.hostname;
        }
    },
    async mounted() {
        this.checkEventFlowPlus();
    },
    activated() {
        this.checkEventFlowPlus();
    },
    methods: {
        async checkEventFlowPlus() {
            if (this.hasEventFlowPlus) {
                this.loading = true;
                await this.getDNSRecords();
                await this.checkLegalStatus();
                await this.checkSharingStatus();
                await this.checkTagStatus();
                await this.getDealerAlerts();
                this.loading = false;
            }
        },
        upgradeToEventPlus() {
            this.productDialogActive = true;
            this.selectedProducts = [PRODUCT_EVENT_FLOW_PLUS];
        },
        showAlerts() {
            this.$router.push({
                name: 'communication-central',
                params: {
                    agency_id: this.dealer.agency.id
                },
                query: {
                    dealer_id: this.dealer.id
                }
            });
        },
        async getDealerAlerts() {
            try {
                const response = await this.$apiRepository.getDealerAlerts(this.dealer.id);
                this.alerts = response.data.data;
            } catch(e) {
                console.log(e);
            }
        },
        async getDNSRecords() {
            try {
                const response = await this.$apiRepository.getDNSRecords(this.dealer.id);
                this.records = response.data.records;
                this.cnameIsValid = response.data.valid;
            } catch(e) {
                console.log(e);
            }
        },
        async checkTagStatus() {
            try {
                const response = await this.$apiRepository.getFeatureStatus(this.dealer.id, FEATURE_EVENT_FLOW_PLUS_TAG);
                this.tagStatus = response.data.status;
            } catch(e) {
                console.log(e);
            }
        },
        async checkLegalStatus() {
            try {
                const response = await this.$apiRepository.getFeatureStatus(this.dealer.id, FEATURE_EVENT_FLOW_PLUS_LEGAL);
                this.legalStatus = response.data.status;
            } catch(e) {
                console.log(e);
            }
        },
        async checkSharingStatus() {
            try {
                const response = await this.$apiRepository.getFeatureStatus(this.dealer.id, FEATURE_EVENT_FLOW_PLUS_DATA_SHARING);
                this.sharingStatus = response.data.status;
            } catch(e) {
                console.log(e);
            }
        },
        onGtmDownloadClick() {
            downloadGtmCode(this.dealer.tag_manager_container_id);
        },
    }
};
</script>

<style lang="scss" scoped>
.status-title {
    color: $loblolly;
    padding-bottom: 5px;
}
.status-holder {
    display: flex;
    align-items: center;

}
.status-ball {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #F66B5C;
    margin-right: 8px;
    &.valid {
        background: $success-500;
    }
}
.status-action {
    cursor: pointer;
    align-items: center;
    display: flex;
}
.closer-to-right {
    margin-left: -20px;
}
</style>
<style>
.status-indicator path {
    fill-opacity: 1;
}
</style>
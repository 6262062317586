<template>
    <styled-slideout
        :width="800"
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <event-flow-plus slim />
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import EventFlowPlus from '@/components/onboarding/workflow/steps/EventFlowPlus';

export default {
    components: {
        StyledSlideout,
        EventFlowPlus
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<template>
    <div>
        <div
            v-if="siteProvider.support_phone || siteProvider.support_email"
            class="contact-panel">
            <p>
                {{ siteProvider.name }} Contact Information
            </p>
            <div class="layout wrap">
                <data-field
                    v-if="siteProvider.support_phone"
                    class="mr-3"
                    no-indent>
                    <template #label>
                        Phone
                    </template>
                    <a
                        :href="`tel:${siteProvider.support_phone}`"
                        target="_blank">
                        {{ siteProvider.support_phone }}
                    </a>
                </data-field>
                <data-field
                    v-if="siteProvider.support_email"
                    no-indent>
                    <template #label>
                        Email
                    </template>
                    <a
                        :href="`mailto:${siteProvider.support_email}`"
                        target="_blank">
                        {{ siteProvider.support_email }}
                    </a>
                </data-field>
            </div>
        </div>

        <styled-list class="my-4">
            <div>
                <h3>
                    Request for Approval
                </h3>
                <p>
                    Send this message to an authorized contact at {{ dealer.name }} that
                    authorizes {{ siteProvider.name }} to install the script.
                </p>
                <p>
                    <em>
                        Note: if you are already an authorized contact at {{ siteProvider.name }}
                        for {{ dealer.name }} you can skip this step
                    </em>
                </p>
                <copy-text-area
                    hide-download
                    :email-subject="`[APPROVAL REQ'D] ${siteProvider.name} <> ${dealer.name} request RE: ${dealer.agency.name} GTM`"
                    class="mb-4">
                    <p>
                        Greetings!
                    </p>
                    <p>
                        In order to activate the system that enables call tracking, retargeting
                        and enhanced reporting on Facebook ads we need to install our Google
                        Tag Manager container on {{ dealerHostname }}.
                    </p>
                    <p>
                        To proceed with this process {{ siteProvider.name }} will need your
                        approval.
                    </p>
                    <p>
                        Please REPLY ALL with "Approved" so we can work with {{ siteProvider.name }}
                        to finalize the Google Tag Manager installation.
                    </p>
                    <p>
                        Thanks!<br>
                        The {{ dealer.agency.name }} Team
                    </p>
                </copy-text-area>
            </div>
            <div>
                <h3>Request for Install</h3>
                <p>
                    Forward the approval received from the previous message
                    to {{ siteProvider.name }} with the message below.
                </p>

                <copy-text-area
                    hide-download
                    :email-to="!!siteProvider.support_email ? siteProvider.support_email : ''"
                    :email-subject="`Tag Manager Install Request: ${dealer.name} (${dealerHostname})`"
                    class="mb-4">
                    <p>
                        Hello {{ siteProvider.name }}!
                    </p>
                    <p>
                        We're in the process of setting up our advertising system with {{ dealer.name }}.
                        To finalize the process we'd like to install our Google Tag Manager container
                        ({{ dealer.tag_manager_container_id }}) on {{ dealerHostname }}.
                    </p>
                    <p>
                        You can find the container scripts to install at the link below:<br>
                        <a :href="publicGtmSetupUrl">
                            {{ publicGtmSetupUrl }}
                        </a>
                    </p>
                    <p>
                        Our contact at {{ dealer.name }} has approved this change in the thread below.
                    </p>
                    <p>
                        Thanks!<br>
                        The {{ dealer.agency.name }} Team
                    </p>
                </copy-text-area>
            </div>
        </styled-list>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CopyTextArea from './CopyTextArea';
import StyledList from './StyledList';
import DataField from './DataField';

export default {
    components: {
        CopyTextArea,
        StyledList,
        DataField
    },
    props: {
        siteProvider: {
            type: Object,
            required: true
        },
        dealer: {
            type: Object,
            required: true
        },
    },
    computed: {
        publicGtmSetupUrl() {
            const gtmSetupRoute = this.$router.resolve({
                name: 'gtm-setup',
                params: {
                    container_id: this.dealer.tag_manager_container_id
                }
            });

            return window.location.origin + gtmSetupRoute.href;
        },
        ...mapGetters(['dealerHostname'])
    }
};
</script>

<style lang="scss" scoped>
.contact-panel {
    padding: 30px;
    background-color: rgba($gray, 0.05);
}
</style>
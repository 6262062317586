<template>
    <div>
        <new-feed-provider-request-slideout v-model="newProviderDialogOpen" />
        <loader v-if="loading" />
        <template v-else>
            <website-inventory-review
                v-if="websiteInventoryPath"
                :active-feed-inventories="activeFeedInventories"
                @approved="startProcessing"
                @declined="$emit('close')" />
            <div v-if="feedInventoryPath">
                <div class="pa-5">
                    <h2 class="mb-2">
                        <span v-if="activeWebsiteInventory">Switch to Feed Inventory</span>
                        <span v-else>Add Feed Provider</span>
                    </h2>
                    <p class="mb-4">
                        To begin the process, please select the feed provider you want to add for {{ currentDealer.name }} below.
                    </p>
                    <feed-provider-picker
                        :feed-provider-id="feedProviderId"
                        :feed-providers="feedProviders"
                        :loading="loadingFeedProviders"
                        @input="setFeedProviderId"
                        @open-provider-dialog="newProviderDialogOpen = true" />
                    <div
                        v-if="feedProvider"
                        class="mb-5 mt-5">
                        <p>
                            Do you know the provider's ID for {{ currentDealer.name }}?
                        </p>
                        <feed-provider-id-picker
                            :feed-rdi-known="feedRdiKnown"
                            :feed-provider-name="feedProvider.name"
                            @input="setRdiKnown" />
                        <div v-if="rdiNotKnown">
                            <div class="mt-5 mb-4">
                                Follow the steps below to obtain approval from {{ currentDealer.name }} and have {{ feedProvider.name }} export inventory to BuyerBridge.
                            </div>
                            <contact-information
                                v-if="feedProvider.support_phone || feedProvider.support_email"
                                :feed-provider="feedProvider" />
                            <styled-list>
                                <div class="mt-5">
                                    <request-approval-message
                                        :feed-provider="feedProvider" />
                                </div>
                                <div>
                                    <request-for-export-message
                                        :feed-provider="feedProvider" />
                                </div>
                                <div v-if="feedProvider.sync_elasticsearch">
                                    <h3>Wait for Inventory to Automatically Process</h3>
                                    <p>
                                        BuyerBridge can automatically detect when {{ feedProvider.name }} has sent us
                                        feed inventory. We will send you a notification once it's been processed.
                                        If you don't receive that notification within 48 hours please contact support.
                                    </p>
                                    <v-checkbox
                                        v-model="useFeedAutoDetection"
                                        color="primary"
                                        class="styled-checkbox">
                                        <template #label>
                                            <span class="mr-1">Enable notifications and send to:</span>
                                            <notification-emails
                                                v-model="autoDetectNotificationEmails"
                                                @clear-emails="autoDetectNotificationEmails = []" />
                                        </template>
                                    </v-checkbox>
                                </div>
                                <div v-else>
                                    <div class="no-detection">
                                        <div class="detection-text">
                                            <h3>Assign the Provided ID</h3>
                                            <p>
                                                Once the {{ feedProvider.name }} has completed the export process they will send you
                                                an ID for the inventory in their system. You will need to copy that ID from the email and paste it
                                                into the Data Sources panel on the inventory dashboard.
                                            </p>
                                        </div>
                                        <div class="detection-image">
                                            <div class="img-wrapper">
                                                <div class="img-shadow" />
                                                <img src="/img/assign-id.png">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </styled-list>
                            <button
                                class="save-button"
                                :disabled="rdiLoading"
                                :class="{'disabled': rdiLoading}"
                                @click="saveAndExit(feedProvider.sync_elasticsearch)">
                                <span v-if="rdiLoading">Loading...</span>
                                <span v-else>Save & Exit</span>
                            </button>
                            <div class="have-id">
                                <span @click="feedRdiKnown = 'yes'">Click here if you are already have the ID</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import NewFeedProviderRequestSlideout from '@/components/globals/NewFeedProviderRequestSlideout';
import WebsiteInventoryReview from '@/components/globals/WebsiteInventoryReview';
import FeedProviderIdPicker from '@/components/globals/FeedProviderIdPicker';
import FeedProviderPicker from '@/components/globals/FeedProviderPicker';
import RequestApprovalMessage from '@/components/globals/InventoryRequestMessages/RequestApprovalMessage';
import RequestForExportMessage from '@/components/globals/InventoryRequestMessages/RequestForExportMessage';
import ContactInformation from '@/components/globals/InventoryRequestMessages/ContactInformation';
import NotificationEmails from '@/components/globals/NotificationEmails';
import StyledList from '@/components/globals/StyledList';
import Loader from '@/components/globals/Loader';'';
import { mapState } from 'vuex';
import { WEBSITE_INVENTORY_SOURCES } from '@/helpers/globals';

export default {
    components: {
        NewFeedProviderRequestSlideout,
        WebsiteInventoryReview,
        FeedProviderIdPicker,
        FeedProviderPicker,
        RequestApprovalMessage,
        RequestForExportMessage,
        ContactInformation,
        NotificationEmails,
        StyledList,
        Loader,
    },
    props: {
        rdiLoading: {
            type: Boolean,
            default: false
        },
        activeType: {
            type: String,
            default: 'feed'
        },
        activeWebsiteInventory: {
            type: Object,
            default: null
        },
        activeFeedInventories: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            websiteInventoryPath: false,
            feedInventoryPath: false,
            loading: false,
            newProviderDialogOpen: false,
            loadingFeedProviders: false,
            feedProviders: [],
            feedProviderId: '',
            feedRdiKnown: null,
            rdiNotKnown: false,
            autoDetectNotificationEmails: [],
            useFeedAutoDetection: true
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        feedProvider() {
            return this.feedProviders.find(feedProvider => {
                return feedProvider.id === this.feedProviderId;
            });
        },
    },
    watch: {
        feedInventoryPath() {
            if (this.feedInventoryPath) {
                this.getFeedProviders();
            }
        },
        feedProvider() {
            this.$emit('set-provider', this.feedProvider);
        },
        autoDetectNotificationEmails() {
            this.$emit('set-emails', this.autoDetectNotificationEmails);
        },
        useFeedAutoDetection() {
            this.$emit('set-notification', this.useFeedAutoDetection);
        },
        activeType() {
            this.setPath();
        }
    },
    mounted() {
        this.setPath();
    },
    methods: {
        setFeedProviderId(id) {
            this.feedProviderId = id;
        },
        setRdiKnown(value) {
            this.feedRdiKnown = value;
            if (value === 'yes') {
                this.rdiNotKnown = false;
                this.$emit('rdi-known', this.feedProvider);
            }
            if (value === 'no') {
                this.rdiNotKnown = true;
                this.$emit('rdi-not-known');
            }
        },
        saveAndExit(sync) {
            this.$emit('save-without-id', sync);
        },
        async getFeedProviders() {
            try {
                this.loadingFeedProviders = true;
                const response = await this.$apiRepository.getFeedProviders();
                const providers = response.data.data;
                // Filter our marketcheck
                this.feedProviders = providers.filter(provider => {
                    return !WEBSITE_INVENTORY_SOURCES.includes(provider.id);
                });
            } catch (error) {
                console.error('Error retrieving feed providers', error);
            } finally {
                this.loadingFeedProviders = false;
            }
        },
        startProcessing(createdRdi) {
            this.$emit('start-processing', createdRdi);
        },
        setPath() {
            if (this.activeType === 'feed') {
                this.feedInventoryPath = true;
                this.websiteInventoryPath = false;
            } else if (this.activeType === 'website') {
                this.feedInventoryPath = false;
                this.websiteInventoryPath = true;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.save-button {
    background-color: $blue-bg;
    color: $white;
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 30px;
    margin: 0 auto;
    margin-top: 30px;
    display: table;
    margin-bottom: 60px;
}
.save-button.disabled {
    opacity: 0.5;
}
.switcher-wrapper {
    padding: 0 45px;
    margin-top: -15px;
}
.save-button {
    background-color: $blue-bg;
    color: $white;
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 30px;
    margin: 0 auto;
    margin-top: 30px;
    display: table;
    &.disabled {
        opacity: 0.5;
    }
}
.have-id {
    text-align: center;
    padding: 15px;
    span {
        color: $blue-bg;
        cursor: pointer;
        text-decoration: underline;
    }
}
.no-detection {
    display: flex;
}
.detection-image {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -15px;
    img {
        max-width: 200px;
        height: auto;
        position: relative;
        z-index: 2;
        vertical-align: top;
    }
}
.img-wrapper {
    position: relative;
}
.img-shadow {
    position: absolute;
    z-index: 1;
    box-shadow: 0 0 15px 3px rgba(0,0,0,0.14);
    border-radius: 50%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
.detection-text {
    width: 50%;
}
</style>
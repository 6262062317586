<template>
    <div class="pa-5">
        <h2 class="mb-2">
            Processing Complete
        </h2>
        <p class="mb-4">
            We have successfully processed inventory for {{ currentDealer.name }}.
            Please review the details and sample vehicles below to ensure
            that everything looks correct before closing this process.
        </p>
        <p class="mb-4 please-note">
            Please note that these updates may take up to 24 hours to reflect in ads & marketplaces.
        </p>
        <loader v-if="loading" />
        <div
            v-else-if="error"
            class="review-failed">
            {{ error }}
        </div>
        <template v-else>
            <div v-if="usedCount !== 0 || newCount !== 0">
                <inventory-analysis
                    :has-inventory="true"
                    :small-style="true"
                    :used-count="usedCount"
                    :new-count="newCount" />
                <h2 class="example-title">
                    Example Vehicles
                </h2>
                <p class="mb-4">
                    We've selected some random vehicles from your feed below.
                    Please review each field against the actual VDP to ensure the data is accurate.
                </p>
                <div class="example-vehicles">
                    <div
                        v-for="(vehicle, index) in randomVehicles"
                        :key="index"
                        class="example-vehicle">
                        <vehicle-review :vehicle="vehicle" />
                    </div>
                </div>
                <div
                    class="look-off">
                    <div class="look-off-left">
                        <span class="look-off-title">
                            Does something look off?
                        </span>
                        <span class="look-off-text">
                            If you see inaccuracies in the data above please contact support for assistance!
                        </span>
                    </div>
                    <div class="look-off-right">
                        <div
                            class="look-off-button"
                            @click.prevent="$store.dispatch('createOnboardingSupportTicket', 'Problem with Feed Processing')">
                            Contact Support
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="review-failed">
                <div class="failed-left">
                    <div class="failed-title">
                        Processing Completed Without Inventory
                    </div>
                    <div class="failed-text">
                        Processing completed succesfully but resulted in not available inventory.
                        This indicates an issue with the feed that our support team will need to assist with.
                    </div>
                </div>
                <div class="failed-right">
                    <div
                        class="failed-button"
                        @click.prevent="$store.dispatch('createOnboardingSupportTicket', 'Feed Processing Completed Without Inventory')">
                        Contact Support
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import InventoryAnalysis from '@/components/inventory-dashboard/InventoryAnalysis';
import Loader from '@/components/globals/Loader';
import VehicleReview from '@/components/globals/VehicleReview';
import { mapState } from 'vuex';

export default {
    components: {
        InventoryAnalysis,
        VehicleReview,
        Loader
    },
    data() {
        return {
            newCount: 0,
            usedCount: 0,
            loading: false,
            randomVehicles: [],
            randomUsedVehicles: [],
            randomNewVehicles: [],
            error: null
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        }),
    },
    async mounted() {
        this.loading = true;
        await Promise.all([
            this.getInventory(),
            this.getInventoryUsed(),
            this.getInventoryNew()
        ]);
        this.loading = false;
        if (this.randomUsedVehicles.length && this.randomNewVehicles.length) {
            this.randomVehicles = [...this.randomUsedVehicles, ...this.randomNewVehicles];
        }
        if ((this.randomUsedVehicles.length && !this.randomNewVehicles.length) ||
            (!this.randomUsedVehicles.length && this.randomNewVehicles.length)) {
            this.randomVehicles = this.randomVehicles.slice(0, 4);
        }
    },
    methods: {
        async getInventory() {
            try {
                const inventoryData = {
                    filters: {
                        dealer_id: this.currentDealer.id,
                    },
                    aggregations: ['used'],
                    order: 'random'
                };
                const response = await this.$apiRepository.getInventoryVehicles(inventoryData);
                const newCount = response.data.aggregations.used.filter(a => a.key == 'false')[0];
                const usedCount = response.data.aggregations.used.filter(a => a.key == 'true')[0];
                this.newCount = newCount?.count ?? 0;
                this.usedCount = usedCount?.count ?? 0;
                this.randomVehicles = response.data.data;
            } catch (error) {
                this.error = error;
            }
        },
        async getInventoryUsed() {
            try {
                const inventoryData = {
                    filters: {
                        dealer_id: this.currentDealer.id,
                        used: true,
                    },
                    order: 'random'
                };
                const response = await this.$apiRepository.getInventoryVehicles(inventoryData);
                this.randomUsedVehicles = response.data.data.slice(0, 2);
            } catch (error) {
                this.error = error;
            }
        },
        async getInventoryNew() {
            try {
                const inventoryData = {
                    filters: {
                        dealer_id: this.currentDealer.id,
                        used: false,
                    },
                    order: 'random'
                };
                const response = await this.$apiRepository.getInventoryVehicles(inventoryData);
                this.randomNewVehicles = response.data.data.slice(0, 2);
            } catch (error) {
                this.error = error;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.review-failed {
    background-color: #F8CACA;
    border: 1px solid #E12020;
    padding: 24px 26px;
    font-size: 13px;
    display: flex;
}
.failed-title {
    font-weight: 600;
    margin-bottom: 15px;
}
.failed-left {
    width: 60%;
}
.failed-right {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.failed-button {
    padding: 4px 15px;
    border-radius: 5px;
    background: $error-500;
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}
.example-title {
    margin-top: 80px;
    margin-bottom: 8px;
}
.example-vehicle > div {
    margin: 12px 23px;
}
.look-off {
    padding: 24px 26px;
    font-size: 13px;
    display: flex;
}
.look-off-title {
    font-weight: 600;
}
.look-off-left {
    width: 60%;
}
.look-off-right {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.look-off-button {
    padding: 4px 15px;
    border-radius: 5px;
    background: $blue-bg;
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}
.please-note {
    font-style: italic;
    color: $gray;
}
</style>
<template>
    <div>
        <styled-slideout
            :value="value"
            :width="700"
            @input="$emit('input', $event)">
            <verify-site-provider-installation-slideout
                v-model="showVerifySiteProviderInstallationDialog"
                :dealer="dealer" />
            <div
                ref="container"
                class="pa-5">
                <div
                    v-if="disclaimerApproved === false"
                    class="text-xs-center">
                    <h2>EventFlow Configuration Change Disclaimer</h2>
                    <p>
                        This tool is for technical users only.  If you’re not
                        comfortable with lower level browser and tracking
                        concepts this tool might not be safe for use.  In addition,
                        after any change we will prompt you to re-verify EventFlow
                        to ensure our system continues to function after your changes are made.
                    </p>
                    <p class="mb-4">
                        Are you ready to continue with these steps?
                    </p>
                    <styled-button
                        @click="disclaimerApproved = true">
                        I'm Ready!  Continue!
                    </styled-button>
                </div>

                <div v-else>
                    <h2>Configure EventFlow</h2>
                    <p>
                        Use the form below to configure how EventFlow detects product impressions on product
                        detail and search pages. If you are not seeing these events reports in each channel's
                        debugging tool there is likely an issue with this configuration.
                    </p>
                    <div
                        v-if="configurationType !== 'Custom'"
                        class="configuration-disclaimer mb-4">
                        DU Auto Group is using the default configuration for their site provider, {{ dealer.site_provider.name }}.
                        If you would like to override the configuration click, "Override &amp; Edit" below
                    </div>
                    <div class="layout align-center mb-4">
                        <strong>
                            Configuration: {{ configurationType }}
                        </strong>
                        <styled-button
                            v-if="configurationType !== 'Custom'"
                            class="ml-auto"
                            small
                            @click="configurationType = 'Custom'">
                            OVERRIDE &amp; EDIT
                        </styled-button>
                    </div>
                    <div
                        v-if="configuration.website_config"
                        :class="{
                            'standard-config-overlay': configurationType !== 'Custom'
                        }">
                        <v-alert
                            :value="error"
                            type="error"
                            class="mb-4">
                            {{ error }}
                        </v-alert>
                        <v-form
                            ref="form"
                            v-model="formIsValid"
                            @submit.prevent="saveUpdates">
                            <div
                                v-for="(pageType, key) in configuration.website_config.page_types"
                                :key="key"
                                class="page-type-block">
                                <h3 class="mb-4">
                                    {{ getPageTypeTitle(pageType.type) }}
                                </h3>
                                <v-text-field
                                    v-model="configuration.website_config.page_types[key].url_regex[0]"
                                    label="MATCH PATTERN"
                                    required
                                    class="mb-4 styled-field"
                                    :rules="requiredRules" />
                                <v-checkbox
                                    v-if="configuration.website_config.page_types[key].hasOwnProperty('enable_url_fallback')"
                                    v-model="configuration.website_config.page_types[key].enable_url_fallback"
                                    label="Enable URL fallback"
                                    color="primary"
                                    class="styled-checkbox" />
                                <v-checkbox
                                    v-model="configuration.website_config.page_types[key].case_sensitive"
                                    label="Case sensitive"
                                    color="primary"
                                    class="styled-checkbox my-3"
                                    @change="setCaseSensitive(key)" />
                                <h4>Product Identifiers</h4>
                                <div
                                    v-for="(field, fieldKey) in configuration.website_config.page_types[key].fields"
                                    :key="fieldKey"
                                    class="page-type-field-block">
                                    <div class="layout mb-3">
                                        <action-button
                                            icon="indicator-error"
                                            class="ml-auto"
                                            @click.prevent="removeProductIdentifier(key, fieldKey)" />
                                    </div>
                                    <v-select
                                        v-model="configuration.website_config.page_types[key].fields[fieldKey].match"
                                        class="mb-4 styled-field"
                                        :items="matchProductIdentifierByTypes"
                                        item-text="name"
                                        item-value="id"
                                        label="MATCH PRODUCT IDENTIFIER BY"
                                        :rules="requiredRules"
                                        @input="setMatchProductIdentifierBy(key, fieldKey)" />
                                    <v-select
                                        :value="configuration.website_config.page_types[key].fields[fieldKey].field_name"
                                        class="mb-4 styled-field"
                                        :items="getProductIdentifierFieldOptions(key, fieldKey)"
                                        item-text="name"
                                        item-value="id"
                                        label="PRODUCT IDENTIFIER FIELD"
                                        :rules="requiredRules"
                                        @input="setProductIdentifierField($event, key, fieldKey)" />
                                    <v-text-field
                                        v-if="configuration.website_config.page_types[key].fields[fieldKey].hasOwnProperty('query')"
                                        v-model="configuration.website_config.page_types[key].fields[fieldKey].query"
                                        label="QUERY SELECTOR"
                                        :rules="requiredRules"
                                        class="mb-4 styled-field" />
                                    <v-select
                                        v-if="configuration.website_config.page_types[key].fields[fieldKey].hasOwnProperty('query')"
                                        class="styled-field"
                                        :value="configuration.website_config.page_types[key].fields[fieldKey].filter.data"
                                        :items="getFunctionFilterTypes(key, fieldKey)"
                                        item-text="name"
                                        item-value="id"
                                        label="FILTER"
                                        placeholder="Select..."
                                        @input="setFunctionFilter($event, key, fieldKey)" />
                                    <v-select
                                        v-if="!configuration.website_config.page_types[key].fields[fieldKey].hasOwnProperty('query')"
                                        class="styled-field"
                                        :value="configuration.website_config.page_types[key].fields[fieldKey].value.data"
                                        :items="getValueFunctionTypes(key, fieldKey)"
                                        item-text="name"
                                        item-value="id"
                                        label="FUNCTION"
                                        placeholder="Select..."
                                        :rules="requiredRules"
                                        @input="setValueFunction($event, key, fieldKey)" />
                                </div>
                                <action-button
                                    icon="indicator-add"
                                    position="right"
                                    color="blue"
                                    class="mt-4"
                                    normal-text
                                    @click.prevent="addProductIdentifier(key)">
                                    Add Product Identifier
                                </action-button>
                            </div>
                            <div
                                v-if="complete"
                                class="configuration-complete">
                                <div>
                                    The EventFlow configuration for {{ dealer.name }} has been updated!
                                    We recommend to re-verify all events to ensure the system is working
                                    properly.
                                </div>
                                <styled-button
                                    small
                                    @click.prevent="showVerifySiteProviderInstallationDialog = true">
                                    Verify Installation
                                </styled-button>
                            </div>
                            <div class="text-xs-center">
                                <styled-button
                                    v-if="configurationType === 'Custom'"
                                    :loading="loading">
                                    Save Updates
                                </styled-button>
                            </div>
                            <!--<pre>{{ JSON.stringify(configuration, null, 2) }}</pre>-->
                        </v-form>
                    </div>
                </div>
            </div>
        </styled-slideout>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import StyledButton from '@/components/globals/StyledButton';
import StyledSlideout from '@/components/globals/StyledSlideout';
import { requiredRules } from '@/helpers/validationRules';
import { mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import VerifySiteProviderInstallationSlideout from './VerifySiteProviderInstallationSlideout';

export default {
    name: 'ConfigureEventFlowDialog',
    components: {
        VerifySiteProviderInstallationSlideout,
        ActionButton,
        StyledButton,
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        dealer: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            disclaimerApproved: false,
            error: '',
            requiredRules,
            formIsValid: false,
            matchProductIdentifierBy: null,
            functionFilter: null,
            configurationType: null,
            loading: false,
            complete: false,
            showVerifySiteProviderInstallationDialog: false,
            configuration: null,
            productIdentifierFieldTypes: [],
            matchProductIdentifierByTypes: [
                {
                    'name': 'Function',
                    'id': 'function'
                },
                {
                    'name': 'DOM Query Selector',
                    'id': 'dom'
                },
            ],
        };
    },
    watch: {
        dealer(newDealer, oldDealer) {
            if (newDealer?.id !== oldDealer?.id) {
                this.init();
            }
        },
        value(newValue, oldValue) {
            // If we're transitioning to open and the form was previously
            // submitted reset it
            if (newValue === true
                && oldValue === false
                && this.complete === true) {
                this.init();
            }
        },
    },
    created() {
        this.init();
    },
    methods: {
         ...mapActions([
            'updateCurrentDealer'
        ]),
        init() {
            // Reset the values in case the form was previously open
            this.complete = false;
            this.error = '';
            this.disclaimerApproved = false;

            // Set configuration and configuration type based on whether dealer or site provider config is set
            if (this.dealer.configuration?.website_config) {
                this.configuration = { ...this.dealer.configuration };
                this.configurationType = 'Custom';
            } else if (this.dealer.site_provider.configuration?.website_config) {
                this.configuration = { ...this.dealer.site_provider.configuration };
                this.configurationType = this.dealer.site_provider.name;
            } else {
                this.configuration = {
                    'website_config': {
                        'page_types': [
                            {
                                'url_regex': '(.*)',
                                'enable_url_fallback': false,
                                'type': 'product_detail',
                                'fields': [
                                    {
                                        'value': {
                                            'data': 'getVinFromUrl',
                                            'type': 'function'
                                        },
                                        'field_name': 'vin'
                                    },
                                    {
                                        'value': {
                                            'data': 'getActiveUrlWithoutParams',
                                            'type': 'function'
                                        },
                                        'field_name': 'vdp_url'
                                    }
                                ]
                            }
                        ]
                    }
                };
                this.configurationType = 'Custom';
            }

            // Set helper values for determining how to modify the configuration based on user input
            this.configuration.website_config.page_types.forEach((pageType, pageTypeIndex) => {
                // Set a 'case-sensitive' helper field based on the regex including 'ig'
                this.configuration.website_config.page_types[pageTypeIndex].case_sensitive = this.definesCaseSensitiveProperty(pageType.url_regex);
                pageType.fields.forEach((field, fieldIndex) => {
                    // Set helper field to determine dom/function matching based on if the 'query' field is set
                    this.configuration.website_config.page_types[pageTypeIndex].fields[fieldIndex].match = field.query ? 'dom' : 'function';

                    if (field.query && !field.filter) {
                        this.configuration.website_config.page_types[pageTypeIndex].fields[fieldIndex].filter = {
                            type: 'function',
                            data: ''
                        };
                    } else if(!field.value) {
                        this.configuration.website_config.page_types[pageTypeIndex].fields[fieldIndex].value = {
                            type: 'function',
                            data: ''
                        };
                    }
                });
            });
        },
        async saveUpdates() {

            this.$refs.form.validate();

            await this.$nextTick();

            this.error = '';

            if (!this.formIsValid) {
                this.error = 'The website configuration has errors.  Please review the form below and resolve any issues noted.';
                this.$refs.container.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
                return;
            }

            try {
                this.loading = true;

                // Patch the dealer with the updated configuration, cleaned for helper values via 'cleanUpConfiguration'
                await this.$apiRepository.updateDealerConfiguration(this.dealer.id, this.cleanUpConfiguration());
                await this.updateCurrentDealer();
                this.complete = true;
            } catch(error) {
                console.error('Error saving config:', error?.data || error);
                this.error = 'There was an error saving your config.  Please try again or contact support if the problem continues';
            } finally {
                this.loading = false;
            }
        },
        cleanUpConfiguration() {
            const config = cloneDeep(this.configuration);

            // Remove the helper values that were set and return the cleaned configuration to save
            for (let key in config.website_config.page_types) {
                delete config.website_config.page_types[key].case_sensitive;

                for (let fieldKey in config.website_config.page_types[key].fields) {
                    delete config.website_config.page_types[key].fields[fieldKey].match;

                    if (config.website_config.page_types[key].fields[fieldKey].hasOwnProperty('filter')) {
                        if (config.website_config.page_types[key].fields[fieldKey].filter.data === '') {
                            delete config.website_config.page_types[key].fields[fieldKey].filter;
                        }
                    }

                    if (config.website_config.page_types[key].fields[fieldKey].hasOwnProperty('value')) {
                        if (config.website_config.page_types[key].fields[fieldKey].value.data === '') {
                            delete config.website_config.page_types[key].fields[fieldKey].value;
                        }
                    }
                }
            }

            return config;
        },
        setMatchProductIdentifierBy(key, fieldKey) {
            // Use the helper match key to determine whether or not query and filter/value fields should be set
            const config = { ...this.configuration };
            if (config.website_config.page_types[key].fields[fieldKey].match === 'dom') {
                if (!config.website_config.page_types[key].fields[fieldKey].hasOwnProperty('query')) {
                    config.website_config.page_types[key].fields[fieldKey].query = '';
                }
                config.website_config.page_types[key].fields[fieldKey].filter = {
                    type: 'function',
                    data: ''
                };
                delete config.website_config.page_types[key].fields[fieldKey].value;
            } else {
                delete config.website_config.page_types[key].fields[fieldKey].query;
                delete config.website_config.page_types[key].fields[fieldKey].filter;
                config.website_config.page_types[key].fields[fieldKey].value = {
                    type: 'function',
                    data: ''
                };
            }
            this.configuration = config;
        },
        setCaseSensitive(key) {
            // Modify url regex based on case sensitive helper key to either include or exclude 'i'
            const config = { ...this.configuration };
            if (config.website_config.page_types[key].case_sensitive) {
                config.website_config.page_types[key].url_regex[1] = 'g';
            } else {
                config.website_config.page_types[key].url_regex[1] = 'ig';
            }
            this.configuration = config;
        },
        addProductIdentifier(key) {
            // Add new empty template product identifier to respective page type
            const config = { ...this.configuration };
            config.website_config.page_types[key].fields.push({
                match: 'function',
                value: {
                    data: '',
                    type: 'function'
                },
                field_name: ''
            });
            this.configuration = config;
        },
        removeProductIdentifier(key, fieldKey) {
            // Remove specified product identifier from respective page type
            const config = { ...this.configuration };
            config.website_config.page_types[key].fields.splice(fieldKey, 1);
            this.configuration = config;
        },
        getValueFunctionTypes(key, fieldKey) {
            // Get options for Function Matching (match=function)

            const field = this.configuration.website_config.page_types[key].fields[fieldKey];

            const options = [];

            if (field.field_name === 'vin') {
                options.push({
                    'name': 'Get VIN From URL',
                    'id': 'getVinFromUrl'
                });
            }

            if (field.field_name === 'vdp_url') {
                options.push({
                    'name': 'Get Current URL',
                    'id': 'getActiveUrlWithoutParams'
                });
            }

            return options;
        },
        getFunctionFilterTypes(key, fieldKey) {
            // Get options for DOM Query Selector Matching (match=dom)

            const options = [{
                'name': 'None',
                'id': ''
            }];

            const field = this.configuration.website_config.page_types[key].fields[fieldKey];

            if (field.field_name === 'vin') {
                options.push({
                    'name': 'Get VIN From String',
                    'id': 'getVinFromString'
                });
            }

            return options;
        },
        setFunctionFilter($event, key, fieldKey) {
            // Set options for DOM Query Selector Matching (match=dom)
            const config = { ...this.configuration };
            config.website_config.page_types[key].fields[fieldKey].filter.data = $event;
            this.configuration = config;
        },
        setValueFunction($event, key, fieldKey) {
            // Set options for Function Matching (match=function)
            const config = { ...this.configuration };
            config.website_config.page_types[key].fields[fieldKey].value.data = $event;
            this.configuration = config;
        },
        getProductIdentifierFieldOptions(key, fieldKey) {
            // Get options for product identifier field, only allow vin and vdp url for function matching
            const field = this.configuration.website_config.page_types[key].fields[fieldKey];
            const options = [
                {
                    'name': 'VIN',
                    'id': 'vin'
                },
                {
                    'name': 'Vehicle ID',
                    'id': 'vehicle_id'
                },
                {
                    'name': 'Stock Number',
                    'id': 'stock_number'
                },
                {
                    'name': 'External ID',
                    'id': 'external_id'
                },
                {
                    'name': 'VDP URL',
                    'id': 'vdp_url'
                },
            ];

            if (field.match === 'dom') {
                return options;
            }

            return options.filter(option => {
                return ['vdp_url', 'vin'].includes(option.id);
            });
        },
        setProductIdentifierField($event, key, fieldKey) {
            const config = { ...this.configuration };
            const field = config.website_config.page_types[key].fields[fieldKey];

            config.website_config.page_types[key].fields[fieldKey].field_name = $event;

            if (field.filter) {
                field.filter = {
                    type: 'function',
                    data: ''
                };
            }
            if (field.value) {
                field.value = {
                    type: 'function',
                    data: ''
                };
            }

            this.configuration = config;
        },
        getPageTypeTitle(value) {
            if (value === 'product_detail') {
                return 'Product Detail';
            }
            if (value === 'search') {
                return 'Search';
            }
            return '';
        },
        definesCaseSensitiveProperty(urlRegex) {
             if(urlRegex.length) {
                 return urlRegex.includes('g');
             }

             return urlRegex.include.includes('g');
        }
    }
};
</script>

<style lang="scss" scoped>
.configuration-disclaimer {
    border: 1px solid $yellow-dark;
    background-color: rgba($yellow-dark, 0.3);
    padding: 10px;
}
.configuration-complete {
    display: flex;
    align-items: center;
    border: 1px solid $carnation;
    background-color: rgba($carnation, 0.3);
    padding: 15px;
    margin-bottom: 20px;
    & > div:first-child {
        margin-right: 10px;
    }
    button {
        margin-left: auto;
        background: $carnation;
        border: 1px solid $carnation;
        text-transform: uppercase;
        white-space: nowrap;
    }
}
.page-type-block {
    border: 1px solid $gray-light;
    background-color: rgba($gray-light, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}
.page-type-field-block {
    background-color: rgba($gray-light, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.standard-config-overlay {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        background-color: rgba($white, 0.6);
    }
}
</style>

<template>
    <div class="process-body">
        <div
            class="process-main">
            <div class="process-title">
                {{ feedProvider.name }} - {{ feedProvider.rdi }}
            </div>
            <div
                v-if="!failed"
                class="process-wrapper">
                <div class="process-headers">
                    <div class="process-header">
                        Stage
                    </div>
                    <div class="process-header">
                        Status
                    </div>
                    <div class="process-header">
                        Stats
                    </div>
                    <div class="process-header-progress">
                        Progress
                    </div>
                </div>
                <single-inventory-process-bar
                    process-name="Inventory"
                    :process="feed" />
                <single-inventory-process-bar
                    process-name="VDP URLs"
                    :process="vdpUrl" />
                <single-inventory-process-bar
                    process-name="Exports"
                    :process="exports" />
            </div>
            <div
                v-else
                class="process-failed">
                <div class="failed-left">
                    <div class="failed-title">
                        Processing Failed
                    </div>
                    <div class="failed-text">
                        Processing failed due to unknown reasons.<br>
                        Please contact support for further assistance
                    </div>
                </div>
                <div class="failed-right">
                    <div
                        class="failed-button"
                        @click.prevent="$store.dispatch('createOnboardingSupportTicket', 'Processing failed')">
                        Contact Support
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SingleInventoryProcessBar from '@/components/globals/SingleInventoryProcessBar';

export default {
    components: {
        SingleInventoryProcessBar
    },
    props: {
        feedProvider: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            pusherMessages: [],
            echoChannel: null,
            failed: false,
            allProcessesNumber: 3
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        }),
        processingComplete() {
            return this.pusherMessages.filter(message => {
                const processNames = ['feed-processor', 'vdp-url-processor', 'upload-exports'];
                if (processNames.includes(message.process_name) && message.status !== 'processing') {
                    return message;
                }
            });
        },
        feed() {
            const lastFeed = this.pusherMessages.findLast(message => message.process_name === 'feed-processor');
            return lastFeed ?? {};
        },
        vdpUrl() {
            const lastVdp = this.pusherMessages.findLast(message => message.process_name === 'vdp-url-processor');
            return lastVdp ?? {};
        },
        exports() {
            const lastExports = this.pusherMessages.findLast(message => message.process_name === 'upload-exports');
            return lastExports ?? {};
        },
    },
    watch: {
        processingComplete() {
            if (this.processingComplete.length === this.allProcessesNumber) {
                const allFinished = [];
                this.processingComplete.forEach(process => {
                    if (process.status === 'finished') {
                        allFinished.push(process);
                    }
                });
                if (allFinished.length === this.allProcessesNumber) {
                    this.$emit('finished');
                } else {
                    this.failed = true;
                }
            }
        }
    },
    mounted() {
        this.startProcessing();
    },
    destroyed() {
        this.leaveChannel();
    },
    methods: {
        leaveChannel() {
            this.$echo.leave(this.echoChannel);
        },
        async startProcessing() {
            const response = await this.processRdi(this.feedProvider.rdiId);
            this.echoChannel = response.data.channel_name;
            this.$echo.channel(this.echoChannel).listen('InventoryProcessed', (e) => {
                this.pusherMessages.push(e);
            });
        },
        async processRdi(id) {
            try {
                const data = {
                    upload_exports: true,
                    notification_email: [],
                    upload_to_facebook: true,
                    activate_pusher: true,
                };
                return this.$http.post(`dealers/${this.currentDealer.id}/remote_dealer_identifiers/${id}/process-inventory`, data);
            } catch(error) {
                console.log(error);
            }
        },
    }
};
</script>

<style lang="scss">
.process-body {
    padding-bottom: 50px;
}
.process-main {
    margin-top: 16px;
    padding: 24px 16px 0 16px;
}
.process-title {
    font-weight: 600;
    color: #5D5D5D;
    margin-bottom: 20px;
}
.process-headers {
    display: flex;
    margin-bottom: 20px;
}
.process-header {
    color: #838383;
    font-size: 12px;
    width: 20%;
}
.process-header-progress {
    color: #838383;
    font-size: 12px;
    width: 40%;
}
.process-single {
    display: flex;
    margin-bottom: 15px;
}
.process-name {
    color: #3F4649;
    width: 20%;
}
.process-name-progress {
    width: 40%;
    position: relative;
    height: 13px;
}
.progress-back {
    height: 100%;
    width: 100%;
    background: #7FD0F4;
}
.progress-front {
    position: absolute;
    background: $blue-bg;
    height: 100%;
    top: 0;
    left: 0;
    bottom:0;
}
.process-failed {
    background-color: #F8CACA;
    border: 1px solid #E12020;
    padding: 24px 26px;
    font-size: 13px;
    display: flex;
}
.failed-title {
    font-weight: 600;
    margin-bottom: 15px;
}
.failed-left {
    width: 60%;
}
.failed-right {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.failed-button {
    padding: 4px 15px;
    border-radius: 5px;
    background: $error-500;
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}
</style>
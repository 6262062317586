<template>
    <div>
        <new-banner-provider-request-slideout v-model="newProviderDialogOpen" />
        <div class="mb-4">
            <h2 class="mb-2">
                Privacy Banner Selection & Agreement
            </h2>
            <p>
                Because EventFlowPLUS is designed to collect events into a central repository and syndicate them to the relevant platforms it will not,
                by default, honor user selections on privacy banners. To continue, you will need to select a supported privacy banner or agree that you are bypassing this service.
            </p>
        </div>
        <v-alert
            type="error"
            class="mb-5"
            :value="errorMessage">
            {{ errorMessage }}
        </v-alert>
        <div class="selection-title">
            Is there a privacy banner active on {{ currentDealer.name }}'s website that you want to support?
        </div>
        <div class="flex mb-4">
            <styled-radio
                v-model="selection"
                :disabled="loading || (checkBox && !slim)"
                input-value="yes"
                label="Yes, I want to support the banner"
                name="bannerSelector"
                dot-style
                inline />
            <styled-radio
                v-model="selection"
                :disabled="loading || (checkBox && !slim)"
                class="ml-4 mb-1"
                input-value="no"
                label="No, there is no privacy banner"
                name="bannerSelector"
                dot-style
                inline />
            <styled-radio
                v-model="selection"
                :disabled="loading || (checkBox && !slim)"
                class="ml-4 mb-1"
                input-value="out"
                label="I am opting out of privacy banner support"
                name="bannerSelector"
                dot-style
                inline />
        </div>
        <loader v-if="loading" />
        <template v-else>
            <div
                v-if="selection === 'yes'"
                class="mb-5">
                <div class="layout">
                    <v-autocomplete
                        v-model="banner"
                        class="styled-field flex xs12 md6 mb-2"
                        label="Select Banner Provider"
                        :disabled="(checkBox && !slim)"
                        :loading="loading"
                        :items="banners"
                        item-text="display_name"
                        return-object />
                </div>
                <a
                    href="#"
                    @click.prevent="newProviderDialogOpen = true">
                    Don't see your provider? Click here to open a request.
                </a>
            </div>
            <template v-if="selection === 'yes' && banner">
                <div>
                    <div class="banner-title">
                        Privacy Banner Support Agreement
                    </div>
                    <p class="mb-4">
                        Since you have chosen to support the active privacy banner
                        we need your organization's consent and acknowledgment that BuyerBridge
                        will be using our best effort to detect user's choices and will default
                        to not track users if their consent choice cannot be confidently detected
                    </p>
                </div>
                <div class="agreement-content">
                    <div class="pa-4">
                        <div v-html="parsedLegalBody" />
                    </div>
                </div>
            </template>
            <template v-if="selection === 'no'">
                <div>
                    <div class="banner-title">
                        No Privacy Banner Agreement
                    </div>
                    <p class="mb-4">
                        Since you have indicated there's no privacy banner on the site, BuyerBridge needs your confirmation that
                        you understand your organization is responsible for changing this setting if a privacy banner is ever introduced on {{ currentDealer.name }}'s website.
                    </p>
                </div>
                <div class="agreement-content">
                    <div class="pa-4">
                        <div v-html="parsedLegalBody" />
                    </div>
                </div>
            </template>
            <template v-if="selection === 'out'">
                <div>
                    <div class="banner-title">
                        Privacy Banner Opt-Out Agreement
                    </div>
                    <p class="mb-4">
                        Since you are opting out of privacy banner support by indicating that there is not a privacy banner BuyerBridge
                        requires your organization's waiver of any BuyerBridge liability related to {{ currentDealer.name }}'s privacy compliance.
                    </p>
                </div>
                <div class="agreement-content">
                    <div class="pa-4">
                        <div v-html="parsedLegalBody" />
                    </div>
                </div>
            </template>
            <template v-if="selection === 'no' || selection === 'out' || (selection === 'yes' && banner)">
                <div class="flex mt-4">
                    <div>
                        <styled-checkbox
                            :value="checkBoxValue"
                            :disabled="loading || (checkBox && !slim)"
                            :checked.sync="checkBoxValue"
                            square />
                    </div>
                    <div
                        class="mb-1 ml-3">
                        Click here to confirm that you are authorized and have full authority on behalf of your organization to accept these terms.
                    </div>
                </div>
                <div
                    class="flex mt-4">
                    <div>
                        <styled-checkbox
                            :value="checkBoxValue2"
                            :disabled="loading || (checkBox && !slim)"
                            :checked.sync="checkBoxValue2"
                            square />
                    </div>
                    <div class="mb-1 ml-3">
                        Click here to confirm that you accept the agreement above and understand that if BuyerBridge cannot confidently detect a user's
                        consent it will default to limited data use tracking which may affect advertising attribution and performance.
                    </div>
                </div>
                <div
                    v-if="currentDealer.agency.id === AGENCY_DU"
                    class="flex mt-4">
                    <div>
                        <styled-checkbox
                            :value="checkBoxValue3"
                            :disabled="loading || (checkBox && !slim)"
                            :checked.sync="checkBoxValue3"
                            square />
                    </div>
                    <div class="mb-1 ml-3">
                        Click here to confirm that a data sharing agreement for the dealership noted above has been signed and received.
                        The information selected above has been confirmed to the dealer's requests related to data sharing.
                    </div>
                </div>
            </template>
        </template>
        <div
            v-if="slim"
            class="save-settings">
            <styled-button
                :disabled="loading || !checkBoxValue || !checkBoxValue2 || (!checkBoxValue3 && currentDealer.agency.id === AGENCY_DU)"
                @click="save">
                SAVE SETTINGS
            </styled-button>
        </div>
        <div v-if="!slim">
            <div class="mt-5 layout align-center">
                <styled-button
                    class="ml-auto"
                    :disabled="loading || !checkBoxValue || !checkBoxValue2 || (!checkBoxValue3 && currentDealer.agency.id === AGENCY_DU)"
                    @click="submit">
                    <span v-if="!checkBox">ACCEPT AGREEMENT &</span> CONTINUE
                </styled-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import {
    AGENCY_DU,
    EVENT_FLOW_PLUS_PRIVACY_BANNER_OPT_IN,
    EVENT_FLOW_PLUS_NO_PRIVACY_BANNER,
    EVENT_FLOW_PLUS_PRIVACY_BANNER_OPT_OUT,
} from '@/helpers/globals';
import Loader from '@/components/globals/Loader';
import StyledRadio from '@/components/globals/StyledRadio';
import StyledButton from '@/components/globals/StyledButton';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import NewBannerProviderRequestSlideout from '@/components/globals/NewBannerProviderRequestSlideout';

export default {
    components: {
        Loader,
        StyledRadio,
        StyledButton,
        StyledCheckbox,
        NewBannerProviderRequestSlideout
    },
    props: {
        slim: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            AGENCY_DU,
            banners: [],
            banner: null,
            loading: false,
            allBanners: [],
            checkBox: false,
            selection: 'yes',
            allAgreements: [],
            errorMessage: null,
            checkBoxValue: false,
            checkBoxValue2: false,
            checkBoxValue3: false,
            parsedLegalBody: null,
            availableBanner: null,
            legalAgreementData: null,
            newProviderDialogOpen: false,
            legalAgreementName: EVENT_FLOW_PLUS_PRIVACY_BANNER_OPT_IN
        };
    },
    computed: {
        ...mapState({
            currentDealer: state => state.dealer.currentDealer
        }),
    },
    watch: {
        banner() {
            this.parseLegalBody();
        },
        selection() {
            if (this.selection === 'yes') {
                this.legalAgreementName = EVENT_FLOW_PLUS_PRIVACY_BANNER_OPT_IN;
            }
            if (this.selection === 'no') {
                this.legalAgreementName = EVENT_FLOW_PLUS_NO_PRIVACY_BANNER;
            }
            if (this.selection === 'out') {
                this.legalAgreementName = EVENT_FLOW_PLUS_PRIVACY_BANNER_OPT_OUT;
            }
            this.getLegalAgreementData();
            this.checkBoxValue = false;
            this.checkBoxValue2 = false;
            this.checkBoxValue3 = false;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.loading = true;
            await this.getDealerPrivacyBanners();
            await this.getPrivacyBanners();
            this.checkBannerAvailability();
            if (this.availableBanner) {
                await this.getLegalAgreements();
                const currentAgreement = this.allAgreements.find(agreement => agreement.id == this.availableBanner.legal_agreement_id);
                this.legalAgreementName = currentAgreement.name;
                this.banner = this.banners.find(banner => banner.id == this.availableBanner.privacy_banner_id);
                if (this.legalAgreementName === EVENT_FLOW_PLUS_NO_PRIVACY_BANNER) {
                    this.selection = 'no';
                }
                if (this.legalAgreementName === EVENT_FLOW_PLUS_PRIVACY_BANNER_OPT_OUT) {
                    this.selection = 'out';
                }
            }
            await this.getDealerLegalAgreementStatus();
            await this.getLegalAgreementData();
            this.loading = false;
        },
        async getDealerPrivacyBanners() {
            try {
                const response = await this.$apiRepository.getDealerPrivacyBanners();
                const allBanners = response.data.data;
                this.allBanners = allBanners.filter(banner => banner.dealer_id == this.currentDealer.id);
            } catch(error) {
                console.error('Error getting Dealer Banners', error);
                this.errorMessage = 'Error getting Dealer Banners';
            }
        },
        async getPrivacyBanners() {
            try {
                const response = await this.$apiRepository.getPrivacyBanners();
                this.banners = response.data.data;
            } catch(error) {
                console.error('Error getting Privacy Banners', error);
                this.errorMessage = 'Error getting Privacy Banners';
            }
        },
        async deletePrivacyBanner(id) {
            this.loading = true;
            try {
                await this.$apiRepository.deletePrivacyBanner(id);
            } catch(error) {
                console.error('Error deleting Privacy Banner', error);
                this.errorMessage = 'Error deleting Privacy Banner';
            } finally {
                this.loading = false;
            }
        },
        checkBannerAvailability() {
            const banner = this.allBanners.find(banner => banner.legal_agreement_id);
            if (banner) { this.availableBanner = banner }
        },
        async getLegalAgreements() {
            try {
                const response = await this.$apiRepository.getLegalAgreements();
                this.allAgreements = response.data.data;
            } catch(error) {
                console.error('Error getting Legal Agreements', error);
                this.errorMessage = 'Error getting Legal Agreements';
            }
        },
        async getDealerLegalAgreementStatus() {
            try {
                const response = await this.$apiRepository.getDealerAgreement(this.currentDealer.id, this.legalAgreementName);
                this.checkBox = !!response.data?.id;
                this.checkBoxValue = this.checkBox;
                this.checkBoxValue2 = this.checkBox;
                this.checkBoxValue3 = this.checkBox;
            } catch (error) {
                console.error('Error getting the legal agreement status', error);
                this.errorMessage = 'Error getting the legal agreement status';
            }
        },
        async getLegalAgreementData() {
            try {
                this.loading = true;
                const agreement = await this.$apiRepository.getLegalAgreement(this.legalAgreementName);
                this.legalAgreementData = agreement;
                this.parseLegalBody();
            } catch (error) {
                console.error('Error getting the legal agreement data', error);
                this.errorMessage = 'Error getting the legal agreement data';
            } finally {
                this.loading = false;
            }
        },
        async acceptAgreement() {
            try {
                this.loading = true;
                const data = {
                    dealerId: this.currentDealer.id,
                    legalAgreementName: this.legalAgreementData.name,
                    document: this.parsedLegalBody,
                };
                const response = await this.$apiRepository.acceptDealerAgreement(data);
                if (response.data?.success) {
                    this.checkBox = true;
                }
            } catch (error) {
                console.error('Error updating the legal agreement status', error);
                this.errorMessage = 'Error updating the legal agreement status';
            } finally {
                this.loading = false;
            }
        },
        async savePrivacyBanner() {
            try {
                this.loading = true;
                const data = {
                    dealer_id: this.currentDealer.id,
                    privacy_banner_id: this.selection === 'yes' ? this.banner.id : null,
                    legal_agreement_id: this.legalAgreementData.id,
                };
                await this.$apiRepository.savePrivacyBanner(data);
            } catch (error) {
                console.error('Error updating privacy banner', error);
                this.errorMessage = 'Error updating privacy banner';
            } finally {
                this.loading = false;
            }
        },
        parseLegalBody() {
            const variables = {
                '{{agency_name}}': this.currentDealer.agency.name,
                '{{dealer_name}}': this.currentDealer.name,
                '{{selected_provider}}': this.banner?.display_name ?? null
            };
            if (this.legalAgreementData) {
                this.parsedLegalBody = this.legalAgreementData.body.replace(/{{agency_name}}|{{dealer_name}}|{{selected_provider}}/g, (matched) => {
                    return variables[matched];
                });
            }
        },
        async save() {
            if (this.availableBanner) {
                await this.deletePrivacyBanner(this.availableBanner.id);
            }
            await this.acceptAgreement();
            await this.savePrivacyBanner();
            await this.init();
            this.$flash('Settings saved successfully!', 'green');
        },
        async submit() {
            if (!this.checkBox) {
                await this.acceptAgreement();
                await this.savePrivacyBanner();
            }
            if (!this.errorMessage) {
                this.$store.dispatch('goToNextStep');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.agreement-content {
    color: $gray-dark;
    border: 1px solid rgba($gray, 0.4);
    font-size: 14px;
    background-color: rgba($gray, 0.05);
    font-family:'Courier New', Courier, monospace;
    h1, h2, h3, h4 {
        font-weight: bold;
        margin-bottom: 1rem;
        color: $gray-dark;
    }
}
.flex {
    display: flex;
    align-items: center;
}
.selection-title {
    font-size: 12px;
    margin-bottom: 15px;
}
.banner-title {
    font-size: 18px;
    margin-bottom: 15px;
}
.save-settings {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 30px;
}
</style>
<template>
    <styled-slideout
        :width="800"
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2 class="mb-3">
                EventFlowPLUS Tag
            </h2>
            <event-flow-plus-tag-status
                slim
                @show-instructions="$emit('show-instructions')" />
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import EventFlowPlusTagStatus from '@/components/globals/EventFlowPlusTagStatus';

export default {
    components: {
        StyledSlideout,
        EventFlowPlusTagStatus,
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
};
</script>

<template>
    <styled-interface
        class="dealer-settings"
        hide-toolbar>
        <template
            #interface-heading>
            Account Settings
        </template>
        <template
            #interface-heading-actions>
            <action-button
                :loading="loading"
                class="mr-4"
                icon="add-permission"
                @click="userPanelActive = true">
                Add User
            </action-button>

            <action-button
                :loading="loading"
                icon="lead-destination"
                @click="leadPanelActive = true">
                Add Lead Destination
            </action-button>
        </template>

        <loader v-if="loading" />
        <div v-else>
            <!-- Cards -->
            <onboarding-card
                v-if="!userIsClient"
                class="section onboardings" />
            <channels-overview class="section" />
            <dealer-overview
                v-model="dealerPanelActive"
                class="section" />
            <account-branding class="section" />
            <event-flow
                v-if="hasEventFlow || hasEventFlowPlus"
                class="section" />
            <data-sources
                v-if="dealerInAutoVertical"
                class="section" />
            <div
                v-if="!userIsClient"
                class="section-group">
                <users
                    v-model="userPanelActive"
                    class="section" />

                <lead-destinations
                    v-model="leadPanelActive"
                    class="section"
                    @updated="updateCurrentDealer" />
            </div>


            <!-- Panels -->
            <add-user
                v-model="userPanelActive"
                @updated="updateCurrentDealer" />
            <add-lead-destination
                v-model="leadPanelActive"
                @updated="updateCurrentDealer" />
            <update-dealer
                v-model="dealerPanelActive"
                @updated="updateCurrentDealer" />
        </div>
    </styled-interface>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import StyledInterface from '@/components/globals/StyledInterface';
import ActionButton from '@/components/globals/ActionButton';
import OnboardingCard from '@/components/dealers/DealerID/OnboardingCard';
import Loader from '@/components/globals/Loader';
import ChannelsOverview from './ChannelsOverview';
import DealerOverview from './DealerOverview';
import UpdateDealer from './panels/UpdateDealer';
import DataSources from '@/components/globals/data-sources/DataSources';
import Users from './Users';
import AddUser from './panels/AddUser';
import LeadDestinations from './LeadDestinations';
import AddLeadDestination from './panels/AddLeadDestination';
import EventFlow from './EventFlow';
import AccountBranding from './AccountBranding';
import { TOGGLE_CHANNEL_SETTINGS_SLIDEOUT, TOGGLE_PAGE_CHANGE_SLIDEOUT } from '@/store/mutation-types';
import { FEATURE_EVENT_FLOW, FEATURE_EVENT_FLOW_PLUS } from '@/helpers/globals';

export default {
    name: 'DealerSettings',
    components: {
        AccountBranding,
        EventFlow,
        StyledInterface,
        ActionButton,
        OnboardingCard,
        ChannelsOverview,
        DealerOverview,
        UpdateDealer,
        DataSources,
        Users,
        AddUser,
        LeadDestinations,
        AddLeadDestination,
        Loader
    },
    data() {
        return {
            userPanelActive: false,
            leadPanelActive: false,
            dealerPanelActive: false,
            loading: false,
        };
    },
    computed: {
        ...mapGetters([
            'userIsClient',
            'dealerInAutoVertical',
            'dealerHasFeature',
        ]),
        ...mapState({
            currentDealer: state => state.dealer.currentDealer
        }),
        hasEventFlow() {
            return this.dealerHasFeature(FEATURE_EVENT_FLOW);
        },
        hasEventFlowPlus() {
            return this.dealerHasFeature(FEATURE_EVENT_FLOW_PLUS);
        },
        routeHash() {
            return this.$route.hash;
        }
    },
    watch: {
        routeHash() {
            this.checkChangePage();
        }
    },
    async created() {
        this.$title = 'Account Settings';
        this.loading = true;
        await this.waitForDealerLoaded();
        this.loading = false;
        this.checkChangePage();
    },
    methods: {
        ...mapActions([
            'updateCurrentDealer',
            'waitForDealerLoaded'
        ]),
        checkChangePage() {
            if (this.routeHash === '#change-page') {
                this.$store.commit(TOGGLE_CHANNEL_SETTINGS_SLIDEOUT, true);
                this.$store.commit(TOGGLE_PAGE_CHANGE_SLIDEOUT, true);
            }
        }
    }
};
</script>
<style lang="scss">
.dealer-settings {
    .onboardings {
        .styled-card {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
}
</style>

<style lang="scss" scoped>
.section {
    margin-bottom: 45px;
}
.section-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .section {
        width: 48%;
    }
}
</style>
